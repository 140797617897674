import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import { domNormalize } from 'common/utils/domNormalizeHack'
import { shouldForwardProp } from 'common/utils/should-forward-prop'
import { initialiseAxiosInterceptors } from 'client/utils/registerInterceptors'
import App from './App'
import './i18n'
import { configureStore } from './store/index'

// Start the mocking conditionally.
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start({
//     serviceWorker: {
//       url: '/assets/mockServiceWorker.js',
//     },
//   })
// }

domNormalize()
initialiseAxiosInterceptors()

const store = configureStore()
const root = createRoot(document.getElementById('app'))

root.render(
  <Provider store={store}>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <App />
    </StyleSheetManager>
  </Provider>,
)
