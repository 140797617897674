import { migrateFormInput } from 'tools/commands/migrate/FormInput/migrate-form-input'
import { migrateCommand } from 'tools/commands/migrate/utils/migrateCommand'
import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'

export default function (page: ServerPage): [ServerPage, boolean] {
  return migrateCommand({
    page,
    structureType: structureTypes.FORM_INPUT,
    migrateFunction: migrateFormInput,
  })
}
