import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createMenu } from 'client/components/entities/Menu/Menu'
import {
  migrateFontSettings,
  migrateFontSize,
  migrateLineHeight,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'
import './migrateMenuUtils'
import {
  migrateActiveColor,
  migrateAlignSelf,
  migrateColor,
  migrateItemUnderlineColor,
  migrateMenuBurgerProperty,
  migrateMenuItems,
  migrateSpacing,
} from './migrateMenuUtils'

export function migrateMenu(entity: OldEntityInterface): MenuInterface {
  const newMenu = createMenu(entity.parentId, entity.masterBlockId)
  newMenu.id = entity.id
  //appearance
  if (entity.options.appearance) {
    newMenu.appearance.desktop = entity.options.appearance.desktop
    newMenu.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  newMenu.htmlAttrId = entity.options.attrId
  //text
  //margins
  migrateMargin(newMenu, entity)
  migrateMobileMargin(newMenu, entity)
  //paddings
  if (entity.styles.padding) {
    newMenu.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    newMenu.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }
  migrateFontSettings(newMenu, entity)
  migrateFontSize(newMenu, entity)
  migrateAlignSelf(newMenu, entity)
  migrateLineHeight(newMenu, entity)
  migrateSpacing(newMenu, entity)
  migrateColor(newMenu, entity)
  migrateActiveColor(newMenu, entity)
  migrateItemUnderlineColor(newMenu, entity)
  migrateMenuItems(newMenu, entity)
  migrateMenuBurgerProperty(newMenu, entity)
  migrateReadOnly(newMenu, entity)

  if (entity.options.type) {
    newMenu.mobileMenuType = entity.options.type
  }

  if (entity.options.sideMenuBackgroundColor) {
    newMenu.sideMenuBackgroundColor = entity.options.sideMenuBackgroundColor
  }

  return newMenu
}
