import React, { useEffect, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { AccessDenied } from 'common/errors/AccessDenied'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { useActions } from 'common/hooks/useActions'
import {
  fetchPageDataSuccess,
  fetchPageDataFailure,
} from 'client/actions/pageActions'
import { fetchPageData, fetchPageAutomationRulesData } from 'client/api/pageApi'
import CodeDialog from 'client/components/core/CodeEditor/CodeEditorDialog'
import Content, { Loading } from 'client/components/core/Content'
import CommonErrorsDialog from 'client/components/core/ErrorsDialog/CommonErrorsDialog'
import ValidationErrorsDialog from 'client/components/core/ErrorsDialog/ValidationErrorsDialog'
import Header from 'client/components/core/Header'
import Layout from 'client/components/core/Layout'
import Sidebar from 'client/components/core/Sidebar'
import Toast from 'client/components/core/Toast'
import useBeforeunload from 'client/hooks/useBeforeunload'
import usePageRouteDetector from 'client/hooks/usePageRouteDetector'
import { fetchPageAutomationRules } from 'client/store/automationRules/automationRulesActions'
import migratePage from 'client/utils/migratePage'
import * as managementActions from './actions/actionsManagement'
import LibrarySkeleton from './components/core/Sidebar/components/Toolbox/library-skeleton'
import { DeviceModeProvider } from './context/DeviceModeContext'
import { HighlightedEntityProvider } from './context/HighlightedContext'
import SidebarVisibilityContextProvider from './context/SidebarVisibilityContext'
import { locales } from './i18n'

function AppNew() {
  if (process.env.NODE_ENV !== 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useBeforeunload(e => e.preventDefault())
  }
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const dispatchFetchPageData = useActions(fetchPageDataSuccess)
  const dispatch = useDispatch()
  const dispatchFetchPageDataFail = useActions(fetchPageDataFailure)
  const setHasLostEntities = useActions(managementActions.setHasLostEntities)
  const setHasMasterBlockRootWithoutMasterBlockId = useActions(
    managementActions.setHasMasterBlockRootWithoutMasterBlockId,
  )
  const setHasMasterBlockIdsWithoutMasterRoot = useActions(
    managementActions.setHasMasterBlockIdsWithoutMasterRoot,
  )
  const { pageId, isTemplate } = usePageRouteDetector()

  useEffect(() => {
    const browserLanguage = window.navigator.languages.find(lng =>
      locales.includes(lng),
    )
    const fixedT = i18n.getFixedT(browserLanguage)

    async function fetchPage(pageId) {
      setIsLoading(true)
      try {
        const response = await fetchPageData(pageId, isTemplate)
        response.data.page = migratePage(response.data.page)
        dispatchFetchPageData(response.data)

        await i18n.changeLanguage(response.data.user.dashboardLocale)
        setIsLoading(false)
      } catch (error) {
        if (error instanceof AccessDenied) {
          // do nothing
        } else if (error instanceof NotFound) {
          dispatchFetchPageDataFail(fixedT('core.errors.page_not_found'))
        } else if (error instanceof NetworkError) {
          dispatchFetchPageDataFail(fixedT('core.errors.no_connection'))
        } else {
          dispatchFetchPageDataFail(
            fixedT('core.errors.went_wrong_contact_support'),
          )
          window.Rollbar.error(error)
          setIsError(true)
        }
      }
    }

    async function fetchAutomationRules(pageId) {
      try {
        const response = await fetchPageAutomationRulesData(pageId, isTemplate)
        dispatch(fetchPageAutomationRules(response.data))
      } catch (error) {
        // do nothing
      }
    }

    if (!pageId) {
      dispatchFetchPageDataFail(fixedT('core.errors.page_not_found'))
    } else {
      fetchPage(pageId)
      if (!isTemplate) fetchAutomationRules(pageId)
    }
  }, [
    dispatch,
    dispatchFetchPageData,
    dispatchFetchPageDataFail,
    i18n,
    isTemplate,
    pageId,
    setHasLostEntities,
    setHasMasterBlockIdsWithoutMasterRoot,
    setHasMasterBlockRootWithoutMasterBlockId,
  ])

  return (
    <SkeletonTheme color="#ccc" highlightColor="#f5f5f5">
      <SidebarVisibilityContextProvider>
        <DeviceModeProvider>
          <HighlightedEntityProvider>
            <Layout>
              <Header />
              {isLoading ? <LibrarySkeleton /> : <Sidebar />}
              {isLoading ? <Loading /> : !isError ? <Content /> : ''}
              <ValidationErrorsDialog />
              <CommonErrorsDialog />
              <CodeDialog />
              <Toast />
            </Layout>
          </HighlightedEntityProvider>
        </DeviceModeProvider>
      </SidebarVisibilityContextProvider>
    </SkeletonTheme>
  )
}

export default memo(AppNew)
