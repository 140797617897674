import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Fonts, getFontPropertiesFromFontFiles } from 'common/utils/fontsUtils'
import * as fileApi from 'client/api/fileApi'
import { getFilteredPageFontFiles } from 'client/reducers/filesReducer'
import { useFiles } from 'client/store'
import { DataFile } from 'client/store/files/filesReducer'

type CustomFontsContextType = {
  userFonts: Fonts | []
  userFontFiles: Record<number, DataFile>
  setUserFonts: (userFonts: Fonts) => void
  setUserFontFiles: (userFontFiles: Record<number, DataFile>) => void
  loadFonts: () => Promise<void>
  isFetching: boolean
}

const CustomFontsContext = createContext<CustomFontsContextType>({
  userFonts: [],
  userFontFiles: {},
  setUserFonts: () => {},
  setUserFontFiles: () => {},
  loadFonts: () => Promise.resolve(),
  isFetching: false,
})

export const useCustomFonts = () => useContext(CustomFontsContext)

function CustomFontsProvider(props: React.PropsWithChildren<React.ReactNode>) {
  const [userFonts, setUserFonts] = useState<Fonts | []>([])
  const [userFontFiles, setUserFontFiles] = useState<Record<number, DataFile>>(
    {},
  )
  const [isFetching, setIsFetching] = useState(false)
  const requestPromiseRef = useRef<Promise<void> | null>(null)
  const hasRequestedRef = useRef(false)

  const fontsFromStore = useFiles(getFilteredPageFontFiles(userFontFiles))

  const loadFonts = useCallback(async () => {
    if (hasRequestedRef.current) {
      return
    }
    if (requestPromiseRef.current) {
      return requestPromiseRef.current
    }
    requestPromiseRef.current = (async () => {
      try {
        setIsFetching(true)
        const response = await fileApi.fetchUserFontFiles()
        const filteredFontFilesMeta = await getFontPropertiesFromFontFiles(
          Object.values(response.data.files),
        )
        setUserFontFiles(response.data.files)
        setUserFonts(filteredFontFilesMeta)
      } catch {
        // do nothing
      } finally {
        setIsFetching(false)
        hasRequestedRef.current = true
        requestPromiseRef.current = null
      }
    })()
    return requestPromiseRef.current
  }, [])

  // TEMP: add all file fonts received for page
  // SC https://app.shortcut.com/systeme/story/27946/include-content-files-from-store-to-the-custom-font-files-list
  useEffect(() => {
    ;(async () => {
      if (fontsFromStore && Object.values(fontsFromStore).length > 0) {
        const filteredFontFilesMeta = await getFontPropertiesFromFontFiles(
          Object.values(Object.values(fontsFromStore)),
        )
        setUserFontFiles(fontFiles => ({ ...fontFiles, ...fontsFromStore }))
        setUserFonts(fonts => [...fonts, ...filteredFontFilesMeta])
      }
    })()
  }, [fontsFromStore])

  return (
    <CustomFontsContext.Provider
      value={{
        userFonts,
        setUserFonts,
        userFontFiles,
        setUserFontFiles,
        isFetching,
        loadFonts,
      }}
    >
      {props.children}
    </CustomFontsContext.Provider>
  )
}

export default CustomFontsProvider
