import { PageTypeEnum } from 'common/enums/PageTypeEnum'

const automationRulePageTypes = [
  PageTypeEnum.Squeeze,
  PageTypeEnum.OptInThankYou,
  PageTypeEnum.Popup,
  PageTypeEnum.Inline,
  PageTypeEnum.SalesPage,
  PageTypeEnum.OfferThankYouPage,
  PageTypeEnum.InfoPage,
  PageTypeEnum.BlogHome,
  PageTypeEnum.BlogStatic,
  PageTypeEnum.BlogPostListing,
]

export default automationRulePageTypes
