import { useManagement } from '../store'
import { getImpersonatorId } from '../store/management/managementSelectors'

const FRONT_DEVS_IDS = [
  8614689, 1408, 7435594, 6970073, 8417501, 10276448, 10954696, 3635572,
]

export function useIsImpersonatorAndSuperAdmin() {
  const impersonatorId = useManagement(getImpersonatorId)

  return impersonatorId ? FRONT_DEVS_IDS.includes(impersonatorId) : false
}
