import {
  migrateFullBorderType,
  migrateBackgroundColor,
  migrateFontSize,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateLineHeight,
  migrateReadOnly,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { FieldInterface } from 'common/types/entities/field-interface'
import { createField } from 'client/components/entities/field-new/field-new'

export const migrateFormInput = (
  entity: OldEntityInterface,
): FieldInterface => {
  const field = createField(entity.parentId, entity.masterBlockId)

  field.id = entity.id
  field.htmlAttrId = entity.options.attrId

  if (entity.isMasterBlockRoot) {
    field.isMasterBlockRoot = true
  }

  if (entity.options.slug) {
    field.slug = entity.options.slug
  }

  migrateMargin(field, entity)
  migrateMobileMargin(field, entity)

  if (entity.styles.textAlign) {
    field.textAlign = entity.styles.textAlign
  }

  if (entity.mobileStyles.textAlign) {
    field.mobileTextAlign = entity.styles.textAlign
  }

  if (entity.styles.placeholderColor) {
    field.placeholderColor = entity.styles.placeholderColor
  }

  if (entity.mobileStyles.placeholderColor) {
    field.mobilePlaceholderColor = entity.mobileStyles.placeholderColor
  }

  if (entity.options.optional) {
    field.optional = entity.options.optional
  }

  if (entity.styles.padding) {
    field.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    field.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateFontSize(field, entity)

  //textColor
  if (entity.styles.color) {
    field.color = entity.styles.color
  }

  //mobileTextColor
  if (entity.mobileStyles && entity.mobileStyles.color) {
    field.mobileColor = entity.mobileStyles.color
  }

  migrateBackgroundColor(field, entity)

  if (entity.options.placeholder) {
    field.placeholder = entity.options.placeholder
  }

  migrateFullBorderType(field, entity)

  if (entity.styles.boxShadow) {
    field.boxShadow = entity.styles.boxShadow
  }
  if (entity.mobileStyles.boxShadow) {
    field.mobileBoxShadow = entity.mobileStyles.boxShadow
  }

  if (entity.options.iconClassName) {
    field.iconClassName = entity.options.iconClassName
  }

  migrateLineHeight(field, entity)

  if (entity.options.appearance) {
    field.appearance.desktop = entity.options.appearance.desktop
    field.appearance.mobile = entity.options.appearance.mobile
  }

  migrateReadOnly(field, entity)

  return field
}
