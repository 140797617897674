import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { BlogPostContentPlaceholderInterface } from 'common/types/entities/BlogPostContentPlaceholderInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBlogPostContentPlaceholder } from 'client/pages/blog/entities/BlogPostContentPlaceholderNew'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

export default function migrateBlogPostContentPlaceholder(
  entity: OldEntityInterface,
): BlogPostContentPlaceholderInterface {
  const blogPostContentPlaceholder = createBlogPostContentPlaceholder(
    entity.parentId,
    entity.masterBlockId,
  )
  //id and masterBlock
  blogPostContentPlaceholder.id = entity.id
  if (entity.isMasterBlockRoot) {
    blogPostContentPlaceholder.isMasterBlockRoot = true
  }

  //margins
  migrateMargin(blogPostContentPlaceholder, entity)
  migrateMobileMargin(blogPostContentPlaceholder, entity)
  //attrId
  blogPostContentPlaceholder.htmlAttrId = entity.options.attrId
  //paddings
  if (entity.styles.padding) {
    blogPostContentPlaceholder.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    blogPostContentPlaceholder.mobilePadding = migratePadding(
      entity.mobileStyles.padding,
    )
  }
  //childIds
  if (entity.childIds) {
    blogPostContentPlaceholder.childIds = entity.childIds
  }

  migrateReadOnly(blogPostContentPlaceholder, entity)

  return blogPostContentPlaceholder
}
