import React from 'react'
import { pageTypes } from 'common/constants/pageTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import BlogPostSettings from './BlogPostSettings'
import { BlogLayoutSettingsResolver } from './blog-layout-settings'
import { BlogPageBodyResolver } from './blog-page-settings'
import { BlogPostLayoutSettings } from './blog-post-layout-settings'
import { CommonPageSettingsResolver } from './common-page-settings'
import { CommonWithoutSeoSettingsResolver } from './common-without-settings'
import { InlineFormSettingsResolver } from './inline-form-settings'
import { LectureSettingsResolver } from './lecture-settings'
import { RemotePopupSettingsResolver } from './remote-popup-settings'

export function createPageSettings(pageType: PageTypeEnum) {
  switch (pageType) {
    case PageTypeEnum.BlogLayout:
      return <BlogLayoutSettingsResolver />
    case PageTypeEnum.BlogPostLayout:
      return <BlogPostLayoutSettings />
    case PageTypeEnum.BlogPostBody:
      return <BlogPostSettings />
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogPostListing:
    case PageTypeEnum.BlogStatic:
      return <BlogPageBodyResolver />
    case PageTypeEnum.Popup:
      return <RemotePopupSettingsResolver />
    case PageTypeEnum.Inline:
      return <InlineFormSettingsResolver />
    case PageTypeEnum.WebinarSession:
    case PageTypeEnum.CustomLogin:
    case pageTypes.courseIntroduction:
    case PageTypeEnum.NotFound:
      return <CommonWithoutSeoSettingsResolver />
    case pageTypes.lecture:
      return <LectureSettingsResolver />
    default:
      return <CommonPageSettingsResolver />
  }
}
