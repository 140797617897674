import {
  migrateButtonFontSize,
  migrateButtonIcons,
  migrateButtonText,
  migrateButtonTextColor,
  migrateButtonWidth,
} from 'tools/commands/migrate/UpsellButton/migrateButtonUtils'
import {
  migrateAlignSelf,
  migrateBoxShadow,
  migrateFullBorderType,
  migrateLineHeight,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateReadOnly,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createButtonNew } from 'client/components/core/Sidebar/components/Settings/entities/button-settings/utils/create-button'
import { ButtonLinkTypeEnum } from 'common/enums/ButtonLinkEnum'

export const migrateButton = (entity: OldEntityInterface) => {
  const button = createButtonNew(
    entity.parentId,
    entity.options.text,
    entity.options.subText,
    entity.masterBlockId,
  )

  if (entity.childIds && entity.childIds.length !== 0) {
    button.childIds = entity.childIds
  }

  button.id = entity.id
  button.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    button.appearance.desktop = entity.options.appearance.desktop
    button.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(button, entity)
  migrateMobileMargin(button, entity)
  migrateLineHeight(button, entity)
  migrateFullBorderType(button, entity)
  migrateAlignSelf(button, entity)
  migrateBoxShadow(button, entity)
  migrateButtonIcons(button, entity)
  migrateButtonText(button, entity)
  migrateButtonTextColor(button, entity)
  migrateButtonWidth(button, entity)
  migrateButtonFontSize(button, entity)

  if (entity.styles.padding) {
    button.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    button.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  //color
  if (entity.styles.textColor) {
    button.textColor = entity.styles.color
  }

  if (entity.mobileStyles && entity.mobileStyles.textColor) {
    button.mobileTextColor = entity.mobileStyles.textColor
  }

  if (entity.options.action) {
    button.action = entity.options.action
  }

  if (entity.options.tagId) {
    button.tagId = entity.options.tagId
  }

  if (entity.options.urlRedirect) {
    button.urlRedirect = entity.options.urlRedirect
  }

  if (entity.options.blogLink) {
    button.blogLink = entity.options.blogLink
  }

  if (entity.options.delay) {
    button.delay = entity.options.delay
  }

  if (entity.styles.background || entity.styles.backgroundColor) {
    button.background =
      entity.styles.background || entity.styles.backgroundColor
  }

  if (entity.mobileStyles.background || entity.mobileStyles.backgroundColor) {
    button.mobileBackground =
      entity.mobileStyles.background || entity.mobileStyles.backgroundColor
  }

  if (entity.options.rel) {
    button.rel = entity.options.rel
  }

  if (entity.options.popup) {
    button.popup = entity.options.popup
  }

  if (entity.options.linkTarget) {
    if (entity.options.linkTarget in ButtonLinkTypeEnum) {
      button.linkTarget =
        ButtonLinkTypeEnum[
          entity.options.linkTarget as keyof typeof ButtonLinkTypeEnum
        ]
    } else {
      button.linkTarget = entity.options.linkTarget
    }
  }

  if (entity.options.srcFileId) {
    button.fileId = entity.options.srcFileId
  }

  if (entity.options.redirectionType) {
    button.redirectionType = entity.options.redirectionType
  }

  if (entity.options.linkUrl) {
    button.linkUrl = entity.options.linkUrl
  }

  if (entity.options.hover) {
    button.hover = entity.options.hover
  }

  if (entity.mobileOptions.hover) {
    button.mobileHover = entity.mobileOptions.hover
  }

  migrateReadOnly(button, entity)

  return button
}
