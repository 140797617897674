import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import structureTypes from 'common/constants/structureTypes'
import { getBlockFile } from 'client/reducers/blocksReducer'
import EntityTypeEnum from '../../../../../../common/enums/entityTypeEnum'
import BlockShareDialog from './BlockShareDialog'
import BlockActionIconUi from './ui/BlockActionIconUi'
import BlockImageWrapperUi from './ui/BlockImageWrapperUi'
import BlockIsMasterUi from './ui/BlockIsMasterUi'
import BlockTitleUi from './ui/BlockTitleUi'
import BlockTitleWrapperUi from './ui/BlockTitleWrapperUi'
import BlockUi from './ui/BlockUi'
import BlockWithSectionUi from './ui/BlockWithSectionUi'

const demoImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAACWBAMAAABkyf1EAAAAG1BMVEXh5eZte4t7iJaKlaGnsLjS19rEys+Yoq21vcMcLj6CAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADBUlEQVR4nO2YTW/aQBCGJwGTHj0LITnaadJeMf3KMSZSe8WpqvaI00Ov0CrtFQ7N7+7MGuMFKuEDbBzpfSTwxrMjP2z2Y4AIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGgcR8xs3o5r9Az44DI1UF/mVzV6NsTXXFzEzJPdPRvieypvP/hqd88G+VLa292zSb4nNVya5BvwfGfPJvlSMpW3W76U9yxsxy/HneS1bnLByPxe9lTftVhnaK410Em68yxc5XvxXQyI7mRjk3WXncp+cZXaNuVyb+74urGZxCSN5M+e+i7zvfjmIQUxX3BXnLrdx5nhj7oGA+Yh9x1fJ9bm7i+W6wvmhNMq34uvjM8JP9BX2Ygznkq7TyeGqCWDmxvH14kdd8d0J37HZkL3XOV78ZVLdibXeEqZuHTEqyN++bm255WvEzvq69jKVDrVe+Eq35evPpcW8txzdZvIa0xpJPeSQeXrxD4PyHrPNByHq3xfvjN1y2Sc+2R9SF7JjTpEla8TU9pyL5lLQ+Zvme/FV+ZvYiufc/tIu9eKU6wueVj5OjGib58SaccTsuNa5nvxzSOKS9+wcrLLZzlmxf7r+I60PxV95DPFPn3TwfJ5vdq+LTYfHrd8axQi+/CViZpExR3XaWs+OLFFb2zbZZ8y34evrvkk3PbdWm8bMW0XfcJVvg/fluyrqbbur9acUlV197ONuaL7b9EnXOX78E1lmdj9Xv6prtPWeeHOFfE91jOl75wXB58U1vc7R3r23sh5erOxpjbOY3c+vKNA97OWnMe5+Jb5B/Ytvr+NbW3zhs2601a946438yc542sZWzNkOd/K/AP7Vt+P82U96e6xm/Xk2tgzT2OZBAtmo+dw7qWe1N8frguhkW2s+QY/zQP935duzXu6vyz66J63zH8WLKKnNqjNFy0l0uiJLeqT2nry0HXv/sht3T5/ao3aHJm/nZTr/GLYDNo+yrJ9ooX685m+MnmHq99UAAAAAAAAAAAAAAAAAAAAAAAAAABA4/kHG6uDwenboGUAAAAASUVORK5CYII'

function Block({
  block,
  remove,
  isRemoveAllowed,
  isMobile,
  isSharingAllowed = false,
  isMasterBlockInUse,
  addToTheBottom,
}) {
  const { t } = useTranslation()
  const [isShareDialogOpen, setShareDialogOpen] = useState(false)
  let desktopFile = useSelector(state =>
    getBlockFile(
      state.blocks,
      block.desktop_preview && block.desktop_preview.id,
    ),
  )
  let mobileFile = useSelector(state =>
    getBlockFile(state.blocks, block.mobile_preview && block.mobile_preview.id),
  )
  const hasSection = Boolean(
    block.content.find(
      ent =>
        ent.type === structureTypes.SECTION ||
        ent.type === EntityTypeEnum.Section,
    ),
  )
  const [, drag] = useDrag({
    item: {
      id: block.id,
      entities: block.content,
      type: 'BLOCK',
      isMaster: Boolean(block.isMaster),
    },
    canDrag: !isMasterBlockInUse,
  })

  const safeRemove = e => {
    e.stopPropagation()
    confirm(
      t(
        block.isMaster
          ? 'components.core.blocks.confirm_remove_master'
          : 'components.core.blocks.confirm_remove',
      ),
    ) && remove()
  }

  const title =
    isMasterBlockInUse &&
    t('components.core.blocks.master_block.disabled_message')

  return (
    <React.Fragment>
      <BlockUi
        ref={drag}
        disabled={isMasterBlockInUse}
        onClick={() => !isMasterBlockInUse && addToTheBottom(block.content)}
        title={title}
      >
        {hasSection && (
          <BlockWithSectionUi>
            {t('entity_settings.section.header')}
          </BlockWithSectionUi>
        )}
        {block.isMaster && (
          <BlockIsMasterUi>
            <i
              className="fa-light fa-bolt-lightning"
              style={{ fontSize: 15, padding: 3 }}
              title={t('components.core.blocks_library.master_block.title')}
            />
          </BlockIsMasterUi>
        )}
        <BlockImageWrapperUi isMobile={isMobile}>
          <img
            src={
              isMobile && mobileFile
                ? mobileFile.path
                : desktopFile
                  ? desktopFile.path
                  : demoImage
            }
          />
        </BlockImageWrapperUi>
        <BlockTitleWrapperUi>
          <BlockTitleUi>{block.title}</BlockTitleUi>
          {isSharingAllowed && (
            <BlockActionIconUi
              className="fas fa-share-alt"
              onClick={e => {
                e.preventDefault
                e.stopPropagation()
                setShareDialogOpen(true)
              }}
            />
          )}
          {isRemoveAllowed && (
            <BlockActionIconUi
              className="far fa-trash-alt"
              onClick={safeRemove}
            />
          )}
        </BlockTitleWrapperUi>
      </BlockUi>
      {isShareDialogOpen && (
        <BlockShareDialog
          block={block}
          close={() => setShareDialogOpen(false)}
        />
      )}
    </React.Fragment>
  )
}

Block.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
  remove: PropTypes.func.isRequired,
  addToTheBottom: PropTypes.func.isRequired,
  isRemoveAllowed: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isSharingAllowed: PropTypes.bool,
}

export default Block
