import React from 'react'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import RangeInputUi from './ui/RangeInputUi'
import RangeTextInputUi from './ui/RangeTextInputUi'
import RangeUi from './ui/RangeUi'

type RangeProps = {
  label?: string
  labelIcon?: React.ReactElement | null
  min?: number
  max?: number
  value?: number
  change: (value: number) => void
  greyThumb?: boolean
  allowNegative?: boolean
  itemStyles?: React.CSSProperties
}

function Range({
  label,
  labelIcon = null,
  min = 0,
  max = 100,
  value = 0,
  change,
  greyThumb = false,
  allowNegative = true,
  itemStyles,
}: RangeProps) {
  function setNumberValue(event: React.ChangeEvent<HTMLInputElement>) {
    let val = Number(event.target.value)
    if (!allowNegative) {
      if (val < min) {
        val = min
      } else if (val > max) {
        val = max
      }
    }
    change(val)
  }

  return (
    <OptionWrapper
      labelText={label}
      labelIcon={labelIcon}
      itemStyles={itemStyles}
    >
      <RangeUi>
        <RangeInputUi
          $greyThumb={greyThumb}
          min={min}
          max={max}
          value={value}
          onChange={setNumberValue}
        />
        <RangeTextInputUi
          value={value}
          onChange={setNumberValue}
          {...(allowNegative ? {} : { min: 1, max })}
        />
      </RangeUi>
    </OptionWrapper>
  )
}

export default Range
