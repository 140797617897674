import React from 'react'
import { RGBColor, ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import { parseToObject, getColorAsString } from 'common/utils/colorUtils'
import NewColorPickerPopup from 'client/components/core/NewColorPickerPopup'
import Popover from 'client/components/core/Popover'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import MobileIconUi from '../../styles/ui/MobileIconUi'
import ColorPickerUi from './ui/ColorPickerUi'
import ColorPreviewInnerUi from './ui/ColorPreviewInnerUi'
import ColorPreviewUi from './ui/ColorPreviewUi'
import ColorRemoverUi from './ui/ColorRemoverUi'
import ColorRemoverWrapperUi from './ui/ColorRemoverWrapperUi'
import ColorPickerPopupUi from './ui/PickerPopupUi'

const defaultColor = 'rgba(0, 0, 0, 0)'

interface ColorPickerProps {
  color?: string
  mobileColor?: string
  label?: string
  update: (color: string) => void
  mobileUpdate?: (color: string) => void
  singleColorMode?: boolean
}

function ColorPicker({
  color = defaultColor,
  mobileColor,
  label = 'settings_styles.color.label',
  update,
  mobileUpdate,
  singleColorMode,
}: ColorPickerProps) {
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  const currentColor = singleColorMode
    ? color
    : isMobile
      ? mobileColor || color
      : color
  const updateCurrentColor = singleColorMode
    ? update
    : isMobile
      ? mobileUpdate
      : update

  const currentParsedColor = parseToObject(currentColor)

  const isRgbChanged = (newColor: RGBColor) => {
    const { r, g, b } = currentParsedColor
    return newColor.r !== r || newColor.g !== g || newColor.b !== b
  }

  const handleChangeComplete = (color: ColorResult) => {
    const rgb = { ...color.rgb }
    const prevA = currentParsedColor.a

    if (isRgbChanged(rgb) && prevA === 0) {
      rgb.a = 1
    }
    updateCurrentColor && updateCurrentColor(getColorAsString(rgb))
  }

  const resetTransparency = () => {
    const color = {
      ...currentParsedColor,
      a: 0,
    }
    updateCurrentColor && updateCurrentColor(getColorAsString(color))
  }

  return (
    <OptionWrapper
      labelText={label}
      labelIcon={isMobile && !singleColorMode && <MobileIconUi />}
    >
      <ColorPickerUi>
        <div>
          <Popover
            offsetValue={45}
            flipOptions={{
              fallbackAxisSideDirection: 'end',
              fallbackPlacements: ['right'],
            }}
            strategyValue={'fixed'}
            placement={'right'}
            triggerElement={
              <ColorPreviewUi>
                <ColorPreviewInnerUi
                  color={getColorAsString(currentParsedColor) || 'transparent'}
                />
              </ColorPreviewUi>
            }
            popoverContent={
              <ColorPickerPopupUi>
                {/* @ts-ignore */}
                <NewColorPickerPopup
                  color={currentParsedColor}
                  onChangeComplete={handleChangeComplete}
                />
              </ColorPickerPopupUi>
            }
          />
        </div>
        <ColorRemoverWrapperUi>
          <ColorRemoverUi
            title={t('settings_styles.remove_color.label')}
            className="fa fa-ban"
            onClick={resetTransparency}
          />
        </ColorRemoverWrapperUi>
      </ColorPickerUi>
    </OptionWrapper>
  )
}

export default ColorPicker
