import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { SalesRedirectionButtonInterface } from 'common/types/entities/button-interface'
import { createSalesRedirectionButton } from 'client/pages/sales/entities/SalesRedirectionButton'
import {
  migrateButtonFontSize,
  migrateButtonIcons,
  migrateButtonText,
  migrateButtonTextColor,
  migrateButtonWidth,
} from '../UpsellButton/migrateButtonUtils'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateBackgroundColor,
  migrateAlignSelf,
  migrateBoxShadow,
  migrateLineHeight,
  migrateFullBorderType,
} from '../utils/migrateUtils'

export function migrateSalesRedirectionButton(
  entity: OldEntityInterface,
): SalesRedirectionButtonInterface {
  const salesRedirectionButton = createSalesRedirectionButton(
    entity.parentId,
    entity.masterBlockId,
  )

  //id and masterBlock
  salesRedirectionButton.id = entity.id
  if (entity.isMasterBlockRoot) {
    salesRedirectionButton.isMasterBlockRoot = true
  }
  //appearance
  if (entity.options.appearance) {
    salesRedirectionButton.appearance.desktop =
      entity.options.appearance.desktop
    salesRedirectionButton.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  salesRedirectionButton.htmlAttrId = entity.options.attrId
  //text
  salesRedirectionButton.text = entity.options.text
  //subText
  if (entity.options.subText) {
    salesRedirectionButton.subText = entity.options.subText
  }

  //margins
  migrateMargin(salesRedirectionButton, entity)
  migrateMobileMargin(salesRedirectionButton, entity)
  //paddings
  if (entity.styles.padding) {
    salesRedirectionButton.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    salesRedirectionButton.mobilePadding = migratePadding(
      entity.mobileStyles.padding,
    )
  }

  //fontSettings text
  migrateButtonText(salesRedirectionButton, entity)

  migrateBackgroundColor(salesRedirectionButton, entity)

  migrateButtonTextColor(salesRedirectionButton, entity)

  migrateButtonWidth(salesRedirectionButton, entity)

  migrateButtonFontSize(salesRedirectionButton, entity)

  migrateLineHeight(salesRedirectionButton, entity)

  migrateAlignSelf(salesRedirectionButton, entity)

  migrateFullBorderType(salesRedirectionButton, entity)

  migrateBoxShadow(salesRedirectionButton, entity)

  migrateButtonIcons(salesRedirectionButton, entity)

  return salesRedirectionButton
}
