import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaypalBackgroundColorType } from 'common/components/entities/paypal-rest-button'
import ReactSelect from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'

const colors: Record<PaypalBackgroundColorType, string> = {
  blue: 'entity_settings.paypal.blue',
  black: 'entity_settings.paypal.black',
  gold: 'entity_settings.paypal.gold',
  silver: 'entity_settings.paypal.silver',
  white: 'entity_settings.paypal.white',
}

interface PaypalBackgroundColorProps {
  color?: PaypalBackgroundColorType
  update: (val: PaypalBackgroundColorType) => void
}

export const PaypalBackgroundColor = ({
  color = 'blue',
  update,
}: PaypalBackgroundColorProps) => {
  const { t } = useTranslation()

  return (
    <ReactSelect
      labelText="bg color"
      options={Object.entries(colors).map(([color, label]) => ({
        value: color,
        label: t(label),
      }))}
      selectedOption={{
        value: color,
        label: t(colors[color]),
      }}
      update={option => update(option?.value as PaypalBackgroundColorType)}
      isMulti={false}
    />
  )
}
