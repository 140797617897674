import styled, { css } from 'styled-components'

const Item = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  padding: 15px 20px 0;
  &:last-of-type {
    margin-bottom: ${props =>
      props.itemStyle.marginBottom ? props.itemStyle.marginBottom : '20px'};
  }
  div {
    width: ${props => props.width && `${props.width}px`};
  }
  ${({ itemStyle = {} }) => itemStyle}
  ${p =>
    p.directionRow &&
    css`
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `};
`

export default Item
