import PropTypes from 'prop-types'
import React, { useState } from 'react'
import FileManager from 'client/components/FileManager/FileManager'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useFiles, { selectors as filesSelectors } from 'client/hooks/useFiles'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import MobileIconUi from '../../styles/ui/MobileIconUi'
import InputGroupUi from './ui/InputGroupUi'
import InputTextUi from './ui/InputTextUi'
import RemoveFileIconUi from './ui/RemoveFileIconUi'
import UploadButtonIconUi from './ui/UploadButtonIconUi'
import UploadButtonUi from './ui/UploadButtonUi'

function getFileName(file) {
  if (!file) {
    return ''
  }

  return file.path
    .replace(/^.*[/]/, '')
    .split('_')
    .slice(1)
    .join('_')
}

function BackgroundImage({
  label = 'settings_styles.background_image.style',
  helpMessage,
  fileId,
  mobileFileId,
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()
  const [fileManagerOpened, setFileManagerOpened] = useState(false)
  const desktopFile = useFiles(files =>
    filesSelectors.getFileById(files, fileId),
  )
  const mobileFile = useFiles(files =>
    filesSelectors.getFileById(files, mobileFileId),
  )

  function updateFile(file) {
    isMobile ? mobileUpdate(file.id) : update(file.id)
  }

  function removeFile() {
    isMobile ? mobileUpdate(null) : update(null)
  }

  function openFileManager() {
    setFileManagerOpened(true)
  }

  function closeFileManager() {
    setFileManagerOpened(false)
  }

  const fileName = getFileName(
    isMobile
      ? typeof mobileFileId === 'undefined'
        ? desktopFile
        : mobileFile
      : desktopFile,
  )

  return (
    <OptionWrapper
      labelText={label}
      helpMessage={helpMessage}
      labelIcon={isMobile && <MobileIconUi />}
    >
      <InputGroupUi>
        <InputTextUi defaultValue={fileName} />
        {fileName && removeFile && (
          <RemoveFileIconUi className="fa fa-times" onClick={removeFile} />
        )}
        <UploadButtonUi onClick={openFileManager}>
          <UploadButtonIconUi
            className="fas fa-cloud-upload-alt"
            aria-hidden="true"
          />
        </UploadButtonUi>
      </InputGroupUi>
      {fileManagerOpened && (
        <FileManager
          closeFileManager={closeFileManager}
          mimeTypes={mimeTypes.image}
          fileType="image"
          onInsert={updateFile}
        />
      )}
    </OptionWrapper>
  )
}

BackgroundImage.propTypes = {
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  fileId: PropTypes.number,
  mobileFileId: PropTypes.number,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

export default BackgroundImage
