import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import fontFamilies from 'common/constants/fonts'
import fonts from 'common/constants/fonts'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { Fonts } from 'common/utils/fontsUtils'
import * as fileActions from 'client/actions/fileActions'
import { useCustomFonts } from 'client/context/CustomFontsContext'
import CustomFontPicker from '../../options/CustomFontPicker'
import FontPicker from '../../options/FontPicker'
import ReactSelect, {
  ChangeOptionValue,
  ChangeOptionWithStringValue,
} from '../ReactSelect/ReactSelect'

export type FontUpdate = {
  fontFamily: string
  fontStyle?: string
  fontWeight?: string
  fontFileId?: number
}

export type FontProps = {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  labelText?: string
  update: (styles: FontUpdate) => void
}

export type BaseFontProps = {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  labelText: string | undefined
  update: (styles: FontUpdate) => void
  globalFontFamily: string | undefined
  inheritedFontTypeLabel: string
  isRootSetting?: boolean
}

enum FontTypeEnum {
  google = 'google',
  safe = 'safe',
  inherited = 'inherited',
  custom = 'custom',
}

const fontTypesLabels = {
  [FontTypeEnum.inherited]: 'settings_styles.font_family.inherited',
  [FontTypeEnum.google]: 'settings_styles.font_family.google',
  [FontTypeEnum.safe]: 'settings_styles.font_family.safe',
  [FontTypeEnum.custom]: 'settings_styles.font_family.custom',
}

// todo remove after fonts preloading task
export function getInheritedFontTypeLabel(pageType: PageTypeEnum) {
  switch (pageType) {
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogPostLayout:
    case PageTypeEnum.BlogPostBody:
    case PageTypeEnum.BlogPostListing:
    case PageTypeEnum.BlogStatic:
      return 'settings_styles.font_family.blog_layout_inherited'
    case PageTypeEnum.Lecture:
      return 'settings_styles.font_type.select_option.course_inherited'
    default:
      return 'settings_styles.font_family.inherited'
  }
}

export const getFontFamilyOption = (fontFamily: string) => ({
  value: fontFamily,
  label: fontFamily,
})

function detectFontType(userFonts: Fonts, fontFamily?: string): FontTypeEnum {
  if (!fontFamily || fontFamily === 'inherit') {
    return FontTypeEnum.inherited
  }

  if (fontFamilies.safe.find(safeFF => safeFF === fontFamily)) {
    return FontTypeEnum.safe
  }

  if (userFonts.find(userFont => userFont.fullName === fontFamily)) {
    return FontTypeEnum.custom
  }

  if (fonts.google.includes(fontFamily)) {
    return FontTypeEnum.google
  }

  return FontTypeEnum.custom
}

function BaseFont({
  fontFamily,
  isRootSetting,
  fontStyle,
  fontWeight,
  labelText = 'settings_styles.font.type',
  update,
  globalFontFamily,
  inheritedFontTypeLabel = 'settings_styles.font_family.inherited',
}: BaseFontProps) {
  const { t } = useTranslation()
  const { userFonts, userFontFiles } = useCustomFonts()
  const dispatch = useDispatch()
  const [fontType, setFontType] = useState<FontTypeEnum>(
    detectFontType(userFonts, fontFamily),
  )

  function handleFontTypeChange(fontType: ChangeOptionValue<FontTypeEnum>) {
    if (fontType) {
      setFontType(fontType.value)
      if (fontType.value === FontTypeEnum.inherited) {
        update({ fontFamily: 'inherit' })
      }
    }
  }

  function handleChangeSafeFontFamily(
    safeFontFamily: ChangeOptionWithStringValue,
  ) {
    if (safeFontFamily) {
      update({ fontFamily: safeFontFamily.value })
    }
  }

  function handleChangeCustomFontFamily(
    customFontFamily: ChangeOptionValue<number>,
  ) {
    if (customFontFamily) {
      const fontProperties = userFonts.find(
        el => el.id === customFontFamily.value,
      )
      if (fontProperties) {
        dispatch(
          fileActions.addFileToPageFiles(userFontFiles[fontProperties.id]),
        )
        update({
          fontFileId: fontProperties.id,
          // we need to wrap custom font families with quotes because some fonts may have broken names
          fontFamily: wrapFontFamily(fontProperties.fullName),
          fontWeight: undefined,
          fontStyle: undefined,
        })
      }
    }
  }

  const fontTypeOptions = [
    {
      value: FontTypeEnum.safe,
      label: t(fontTypesLabels[FontTypeEnum.safe]),
    },
    {
      value: FontTypeEnum.google,
      label: t(fontTypesLabels[FontTypeEnum.google]),
    },
    {
      value: FontTypeEnum.custom,
      label: t(fontTypesLabels[FontTypeEnum.custom]),
    },
  ]

  fontTypeOptions.unshift({
    value: FontTypeEnum.inherited,
    label: `${t(inheritedFontTypeLabel)} ${
      !isRootSetting && globalFontFamily && globalFontFamily !== 'inherit'
        ? globalFontFamily
        : ''
    }`,
  })

  return (
    <>
      <ReactSelect<FontTypeEnum>
        labelText={labelText}
        update={handleFontTypeChange}
        selectedOption={
          fontTypeOptions.find(
            fontTypeOption => fontTypeOption.value === fontType,
          ) || null
        }
        options={fontTypeOptions}
        isMulti={false}
      />
      {fontType === FontTypeEnum.safe && (
        <ReactSelect<string>
          update={handleChangeSafeFontFamily}
          selectedOption={
            fontFamily && fontFamilies.safe.includes(fontFamily)
              ? getFontFamilyOption(fontFamily)
              : null
          }
          options={fontFamilies.safe.map(getFontFamilyOption)}
          isMulti={false}
        />
      )}
      {fontType === FontTypeEnum.google && (
        <FontPicker
          activeFontFamily={fontFamily}
          activeFontStyle={fontStyle}
          activeFontWeight={fontWeight}
          onChange={update}
        />
      )}
      {fontType === FontTypeEnum.custom && (
        <CustomFontPicker
          update={handleChangeCustomFontFamily}
          fontFamily={fontFamily}
        />
      )}
    </>
  )
}

export default BaseFont
