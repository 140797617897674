import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBlogPostListing } from 'client/pages/blog-page/entities/BlogPostListing'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateReadOnly,
} from '../utils/migrateUtils'
import {
  migrateCategoriesSettings,
  migrateDateSettings,
  migrateDescriptionSettings,
  migrateFullImageBorderType,
  migrateImageBoxShadow,
  migrateMobilePaginationSettings,
  migratePaginationSettings,
  migrateTitleSettings,
} from './utils'

export default function migrateBlogPostListing(
  entity: OldEntityInterface,
): BlogPostListingInterface {
  const blogPostListing = createBlogPostListing(
    entity.parentId,
    entity.masterBlockId,
  )
  //id and masterBlock
  blogPostListing.id = entity.id
  if (entity.isMasterBlockRoot) {
    blogPostListing.isMasterBlockRoot = true
  }

  //margins
  migrateMargin(blogPostListing, entity)
  migrateMobileMargin(blogPostListing, entity)
  //attrId
  blogPostListing.htmlAttrId = entity.options.attrId

  blogPostListing.showDate = entity.options.showDate
  blogPostListing.maxPosts = entity.options.maxPosts
  blogPostListing.maxPostsPerRow = entity.options.maxPostsPerRow
  blogPostListing.displayHorizontally = entity.options.displayHorizontally
  if (entity.options.category) {
    blogPostListing.category = entity.options.category
  }
  if (entity.styles.padding) {
    blogPostListing.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    blogPostListing.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateImageBoxShadow(blogPostListing, entity)
  migrateFullImageBorderType(blogPostListing, entity)
  migrateTitleSettings(blogPostListing, entity)
  migrateDescriptionSettings(blogPostListing, entity)
  migrateCategoriesSettings(blogPostListing, entity)
  migrateDateSettings(blogPostListing, entity)
  migratePaginationSettings(blogPostListing, entity)
  migrateMobilePaginationSettings(blogPostListing, entity)
  migrateReadOnly(blogPostListing, entity)

  return blogPostListing
}
