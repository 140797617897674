import structureTypes from '../../../../common/constants/structureTypes'
import CarouselInterface, {
  CarouselItemInterface,
} from '../../../../common/types/entities/CarouselInterface'
import ServerPage from '../../../../server/types/ServerPageInterface'
import { migrateCarousel, migrateCarouselItem } from './migrateCarousel'

export default function (page: ServerPage): [ServerPage, boolean] {
  let isChanged = false
  try {
    const entities = Object.values(page.content.entities).reduce(
      (prev, entity) => {
        if (
          entity.type === structureTypes.CAROUSEL ||
          entity.type === structureTypes.CAROUSEL_ITEM
        ) {
          isChanged = true
        }

        return {
          ...prev,
          [entity.id]:
            entity.type === structureTypes.CAROUSEL
              ? migrateCarousel(entity as CarouselInterface)
              : entity.type === structureTypes.CAROUSEL_ITEM
              ? // @ts-ignore
                migrateCarouselItem(entity as CarouselItemInterface)
              : entity,
        }
      },
      {},
    )
    return [
      {
        ...page,
        content: {
          ...page.content,
          entities,
        },
      },
      isChanged,
    ]
  } catch (e) {
    console.log(`--error--`, page, page.id)

    return [page, false]
  }
}
