import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { useActions } from 'common/hooks/useActions'
import { findEntityByType } from 'common/utils/entityUtils'
import * as entityActions from 'client/actions/entityActions'
import { TooltipMessageUi } from 'client/components/core/tooltip/ui/tooltip-message-ui'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'
import useCurrentRootEntity from 'client/hooks/useCurrentRootEntity'
import { usePage, pageSelectors } from 'client/store/index'
import { needScroll } from 'client/store/management/managementActions'
import {
  addStructure,
  toggleInstantUpload,
  toggleSettings,
} from 'client/store/page/pageActions'
import createLibraryElementStructure from 'client/utils/createLibraryElements'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import LibraryElementIconUi from './ui/ToolboxItemIconUi'
import LibraryElementIconWrapperUi from './ui/ToolboxItemIconWrapperUi'
import LibraryElementTitleUi from './ui/ToolboxItemTitleUi'
import LibraryElementUi from './ui/ToolboxItemUi'
import { useAppDispatch } from 'client/hooks/use-dispatch'

type LibraryElementProps = {
  type: string
  title: string
  className?: string
  iconRenderer?: () => JSX.Element
} & (
  | {
      isElementRemoved?: false
      tooltipText?: never
    }
  | {
      isElementRemoved: true
      tooltipText: string
    }
)

function LibraryElement({
  type,
  className,
  title,
  iconRenderer,
  isElementRemoved,
  tooltipText,
}: LibraryElementProps) {
  const dispatch = useAppDispatch()
  const createAsLast = useActions(entityActions.createAsLast)
  const [{ isDragging }, drag] = useDrag({
    item: { type },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const [showTooltip, setShowTooltip] = useState(false)

  const rootEntity = useCurrentRootEntity()
  const pageLocale = usePage(pageSelectors.getPageLocale) as string

  function appendLibraryElement() {
    if (
      type in LibraryElementEnum &&
      type !== LibraryElementEnum.LibrarySection
    ) {
      const structure = createLibraryElementStructure(
        type as LibraryElementEnum,
        rootEntity,
        pageLocale,
        rootEntity.childIds.length,
      )
      dispatch(addStructure(structure))
      dispatch(needScroll(true))
      if (type === LibraryElementEnum.LibraryCalendar) {
        const calendar = findEntityByType(
          structure,
          EntityTypeEnum.BookingCalendar,
        )
        if (calendar) {
          dispatch(toggleSettings(calendar.id))
        }
      }
      if (type === LibraryElementEnum.LibraryImage) {
        dispatch(toggleInstantUpload())
        const image = findEntityByType(structure, EntityTypeEnum.Image)
        if (image) {
          dispatch(toggleSettings(image.id))
        }
      }
    } else {
      createAsLast(type)
    }
  }

  const props = isElementRemoved
    ? {
        onMouseOver: () => setShowTooltip(true),
        onMouseOut: () => setShowTooltip(false),
        styles: { position: 'relative', cursor: 'not-allowed' },
      }
    : {
        ref: drag,
        onClick: appendLibraryElement,
        $isDragging: isDragging,
      }

  return (
    <LibraryElementUi {...props}>
      {isElementRemoved && (
        <TooltipMessageUi
          isVisible={showTooltip}
          styles={{ position: 'absolute' }}
        >
          {tooltipText}
        </TooltipMessageUi>
      )}
      <LibraryElementIconWrapperUi
        // @ts-expect-error: hard to bypass using js
        styles={isElementRemoved && { opacity: '0.45' }}
      >
        {className && <LibraryElementIconUi className={className} />}
        {iconRenderer && iconRenderer()}
      </LibraryElementIconWrapperUi>
      <LibraryElementTitleUi>{title}</LibraryElementTitleUi>
    </LibraryElementUi>
  )
}

export default LibraryElement
