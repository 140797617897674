import React from 'react'
import { useDispatch } from 'react-redux'
import { useActions } from 'common/hooks/useActions'
import { update } from 'client/actions/entityActions'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import { Range } from '../../components'
import useUpdateEntityMobileOption from '../../hooks/useUpdateEntityMobileOption'
import useUpdateEntityMobileStyle from '../../hooks/useUpdateEntityMobileStyle'
import useUpdateEntityOption from '../../hooks/useUpdateEntityOption'
import useUpdateEntityStyle from '../../hooks/useUpdateEntityStyle'
import GroupTitle from '../../styleComponents/GroupTitle'
import { BackgroundPositionStyleNew } from '../../styles'

function Background() {
  const rootEntity = usePage(pageSelectors.getReadableRootEntity)
  const dispatch = useDispatch()
  const updateStyle = useUpdateEntityStyle(dispatch, rootEntity)
  const updateMobileStyle = useUpdateEntityMobileStyle(dispatch, rootEntity)
  const updateOption = useUpdateEntityOption(dispatch, rootEntity)
  const updateMobileOption = useUpdateEntityMobileOption(dispatch, rootEntity)
  const updateAction = useActions(update)

  const { isMobile } = useDeviceModeContext()

  const handleUpdateStyles = styles => {
    const entityMobileStyles = rootEntity.mobileStyles
      ? rootEntity.mobileStyles
      : {}
    const entityStyles = rootEntity.styles ? rootEntity.styles : {}
    const updatedEntity = { ...rootEntity }
    if (updatedEntity.options.backgroundPosition) {
      delete updatedEntity.options.backgroundPosition
    }
    if (
      updatedEntity.mobileOptions &&
      updatedEntity.mobileOptions.backgroundPosition
    ) {
      delete updatedEntity.mobileOptions.backgroundPosition
    }
    if (isMobile) {
      updateAction({
        ...updatedEntity,
        mobileStyles: {
          ...entityMobileStyles,
          ...styles,
        },
      })
    } else {
      updateAction({
        ...updatedEntity,
        styles: {
          ...entityStyles,
          ...styles,
        },
      })
    }
  }
  const backgroundStyles = getBackgroundStyles(rootEntity, isMobile)

  return (
    <React.Fragment>
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={rootEntity.styles.backgroundColor}
        mobileColor={rootEntity.mobileStyles.backgroundColor}
        label="page_settings.body.background_color.label"
      />
      <BackgroundPositionStyleNew
        fileId={
          isMobile
            ? rootEntity.mobileOptions.backgroundFileId ||
              rootEntity.options.backgroundFileId
            : rootEntity.options.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateMobileOption('backgroundFileId')
            : updateOption('backgroundFileId')
        }
        update={handleUpdateStyles}
        backgroundPosition={backgroundStyles}
      />
      <Range
        labelText="page_settings.body.background_blur.label"
        value={rootEntity.options.blur}
        update={updateOption('blur')}
        max={10}
      />
    </React.Fragment>
  )
}

export default Background

export const getBackgroundStyles = (entity, isMobile) => {
  let backgroundStyles
  if (isMobile) {
    backgroundStyles =
      entity.mobileOptions.backgroundPosition ||
      entity.options.backgroundPosition
        ? entity.mobileOptions.backgroundPosition ||
          entity.options.backgroundPosition
        : {
            backgroundSize:
              entity.mobileStyles?.backgroundSize ||
              entity.styles.backgroundSize,
            backgroundPosition:
              entity.mobileStyles?.backgroundPosition ||
              entity.styles.backgroundPosition,
            backgroundRepeat:
              entity.mobileStyles?.backgroundRepeat ||
              entity.styles.backgroundRepeat,
            backgroundAttachment:
              entity.mobileStyles?.backgroundAttachment ||
              entity.styles.backgroundAttachment,
          }
  } else {
    backgroundStyles = entity.options.backgroundPosition
      ? entity.options.backgroundPosition
      : {
          backgroundSize: entity.styles.backgroundSize,
          backgroundPosition: entity.styles.backgroundPosition,
          backgroundRepeat: entity.styles.backgroundRepeat,
          backgroundAttachment: entity.styles.backgroundAttachment,
        }
  }
  return backgroundStyles
}
