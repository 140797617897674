import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { BlogPageBodyInterface } from 'common/types/entities/blog-page-body-interface'
import { migrateBackground } from '../utils/migrateUtils'

export function migrateBlogPageBody(
  entity: OldEntityInterface,
): BlogPageBodyInterface {
  const blogPageBody: BlogPageBodyInterface = {} as BlogPageBodyInterface

  blogPageBody.id = entity.id
  blogPageBody.type = EntityTypeEnum.BlogPageBody
  blogPageBody.childIds = entity.childIds || []

  if (entity.options?.attrId) {
    blogPageBody.htmlAttrId = entity.options.attrId
  }
  if (entity.options?.blur !== undefined) {
    blogPageBody.blur = entity.options.blur
  }

  if (entity.styles?.backgroundColor) {
    blogPageBody.backgroundColor = entity.styles.backgroundColor
  }

  if (entity.mobileStyles?.backgroundColor) {
    blogPageBody.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }
  blogPageBody.background = {}
  migrateBackground(blogPageBody, entity)

  return blogPageBody
}
