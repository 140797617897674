import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

function MarginsStyle({
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
  mobileMarginTop = 0,
  mobileMarginRight = 0,
  mobileMarginBottom = 0,
  mobileMarginLeft = 0,
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()

  const updateProp = style => value =>
    isMobile ? mobileUpdate(style, 'px')(value) : update(style, 'px')(value)

  return (
    <React.Fragment>
      <GroupTitle>settings_styles.margin.label</GroupTitle>
      <Range
        labelText="settings_styles.margin.top"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileMarginTop : marginTop, 0)}
        update={updateProp('marginTop')}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.bottom"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileMarginBottom : marginBottom, 0)}
        update={updateProp('marginBottom')}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.left"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileMarginLeft : marginLeft, 0)}
        update={updateProp('marginLeft')}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.right"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileMarginRight : marginRight, 0)}
        update={updateProp('marginRight')}
        min={0}
        max={200}
      />
    </React.Fragment>
  )
}

MarginsStyle.propTypes = {
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

export default memo(MarginsStyle)
