import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  groups,
  groupsTitles,
} from 'client/components/core/Sidebar/components/Toolbox/toolboxGroups'
import toolboxItems from 'client/components/core/Sidebar/components/Toolbox/toolboxItems'
import ToolboxGroupTitleUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxGroupTitleUi'
import ToolboxGroupUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxGroupUi'
import ToolboxItemListUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxItemListUi'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'
import useManagement from 'client/hooks/useManagement'
import pageToolboxMap from 'client/pages/pageToolboxMap'
import { isUserAdmin } from 'client/store/management/managementSelectors'
import LibraryElement from '../Toolbox/LibraryElement'

function Elements({ pageType }) {
  const isAdmin = useManagement(isUserAdmin)
  const { t } = useTranslation()

  const libraryElements = { ...pageToolboxMap[pageType] }

  return (
    <React.Fragment>
      {Object.keys(libraryElements).map(groupItemsKey => (
        <ToolboxGroupUi key={groupItemsKey}>
          <ToolboxGroupTitleUi>
            <Trans parent={'span'}>{groupsTitles[groupItemsKey]}</Trans>
          </ToolboxGroupTitleUi>
          <ToolboxItemListUi>
            {libraryElements[groupItemsKey].map(libraryElementType => {
              return (
                <LibraryElement
                  key={libraryElementType}
                  type={libraryElementType}
                  className={toolboxItems[libraryElementType].className}
                  iconRenderer={toolboxItems[libraryElementType].iconRenderer}
                  title={t(toolboxItems[libraryElementType].title)}
                  isElementRemoved={t(
                    toolboxItems[libraryElementType].isElementRemoved,
                  )}
                  tooltipText={t(toolboxItems[libraryElementType].tooltipText)}
                />
              )
            })}
          </ToolboxItemListUi>
        </ToolboxGroupUi>
      ))}
      {isAdmin && (
        <ToolboxGroupUi key={groups.sio}>
          <ToolboxGroupTitleUi>
            <Trans parent={'span'}>{groupsTitles[groups.sio]}</Trans>
          </ToolboxGroupTitleUi>
          <ToolboxItemListUi>
            <LibraryElement
              key={LibraryElementEnum.LibraryLanguageSwitcher}
              type={LibraryElementEnum.LibraryLanguageSwitcher}
              className={
                toolboxItems[LibraryElementEnum.LibraryLanguageSwitcher]
                  .className
              }
              title={t(
                toolboxItems[LibraryElementEnum.LibraryLanguageSwitcher].title,
              )}
            />
            <LibraryElement
              key={LibraryElementEnum.LibraryPricePlanCalculator}
              type={LibraryElementEnum.LibraryPricePlanCalculator}
              className={
                toolboxItems[LibraryElementEnum.LibraryPricePlanCalculator]
                  .className
              }
              title={t(
                toolboxItems[LibraryElementEnum.LibraryPricePlanCalculator]
                  .title,
              )}
            />
            <LibraryElement
              key={LibraryElementEnum.LibraryPriceElement}
              type={LibraryElementEnum.LibraryPriceElement}
              className={
                toolboxItems[LibraryElementEnum.LibraryPriceElement].className
              }
              title={t(
                toolboxItems[LibraryElementEnum.LibraryPriceElement].title,
              )}
            />
          </ToolboxItemListUi>
        </ToolboxGroupUi>
      )}
    </React.Fragment>
  )
}

export default Elements
