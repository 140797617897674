import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { borderTypeKeys } from 'common/constants/settings'
import { BorderStyleType, BorderType } from 'common/types/styleTypes'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Range from '../components/Range/Range'
import Select, {
  ChangeOptionWithStringValue,
} from '../components/ReactSelect/ReactSelect'
import GroupTitle from '../styleComponents/GroupTitle'
import BorderRadius from './BorderRadius'
import MobileIconUi from './ui/MobileIconUi'

const getBorderStyleChoices = (t: TFunction) => [
  {
    label: t('settings_styles.border.style.choices.none'),
    value: 'none',
  },
  {
    label: t('settings_styles.border.style.choices.solid'),
    value: 'solid',
  },
  {
    label: t('settings_styles.border.style.choices.dotted'),
    value: 'dotted',
  },
  {
    label: t('settings_styles.border.style.choices.dashed'),
    value: 'dashed',
  },
]

const getBorderTypeChoices = (t: TFunction) => [
  {
    label: t('Full Border'),
    value: borderTypeKeys.fullBorder,
  },
  {
    label: t('Bottom Only'),
    value: borderTypeKeys.bottomOnly,
  },
  {
    label: t('Top Only'),
    value: borderTypeKeys.topOnly,
  },
  {
    label: t('Top & Bottom'),
    value: borderTypeKeys.topAndBottom,
  },
]

type BorderKeyType = 'width' | 'style' | 'radius' | 'color' | 'type'

type BorderProps = {
  border?: Partial<BorderType>
  update: (border: Partial<BorderType>) => void
  mobileUpdate?: (border: Partial<BorderType>) => void
  showGroupTitle?: boolean
  groupTitle?: string
  max?: number
  withoutType?: boolean
  mobileBorder?: Partial<BorderType>
}

function Border({
  border = {},
  update,
  showGroupTitle = true,
  groupTitle = 'settings_styles.border_style.group_title',
  max = 10,
  withoutType,
  mobileBorder,
  mobileUpdate,
}: BorderProps) {
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  const currentBorder = isMobile ? mobileBorder || border : border
  const currentUpdateBorder = isMobile && mobileUpdate ? mobileUpdate : update

  function updateBorder(prop: BorderKeyType) {
    return (value: string | number | null) => {
      currentUpdateBorder({
        ...currentBorder,
        [prop]: value,
      })
    }
  }

  function updateBorderStyle(valueOption: ChangeOptionWithStringValue) {
    if (valueOption) {
      const styleValue = valueOption.value as BorderStyleType
      if (
        (!currentBorder.style || currentBorder.style === 'none') &&
        valueOption.value !== 'none'
      ) {
        currentUpdateBorder({
          ...currentBorder,
          style: styleValue,
          width: 1,
          color: 'rgba(0, 0, 0, 0.5)',
        })
      } else if (
        currentBorder.style !== 'none' &&
        valueOption.value === 'none'
      ) {
        const updatedBorder = {
          ...currentBorder,
          style: styleValue,
        }
        delete updatedBorder.color
        delete updatedBorder.width
        currentUpdateBorder(updatedBorder)
      } else {
        currentUpdateBorder({
          ...currentBorder,
          style: styleValue,
        })
      }
    }
  }

  function updateBorderType(valueOption: ChangeOptionWithStringValue) {
    if (valueOption) {
      currentUpdateBorder({
        ...currentBorder,
        type: valueOption.value,
      })
    }
  }
  const borderStyleChoices = getBorderStyleChoices(t)
  const borderTypeChoices = getBorderTypeChoices(t)

  return (
    <>
      {showGroupTitle && <GroupTitle>{groupTitle}</GroupTitle>}
      <BorderRadius
        label="settings_styles.border_radius.label"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        border={currentBorder}
        update={currentUpdateBorder}
      />
      <Select<string>
        labelText="settings_styles.border_style.label"
        options={borderStyleChoices}
        selectedOption={
          borderStyleChoices.find(
            borderStyle => borderStyle.value === currentBorder.style,
          ) || borderStyleChoices[0]
        }
        labelIcon={isMobile ? <MobileIconUi /> : undefined}
        update={updateBorderStyle}
        isMulti={false}
      />
      {currentBorder.style && currentBorder.style !== 'none' && (
        <>
          <ColorPicker
            color={currentBorder.color}
            update={updateBorder('color')}
            label="settings_styles.border_color.label"
            singleColorMode
          />
          <Range
            label="settings_styles.border_width.label"
            labelIcon={isMobile ? <MobileIconUi /> : null}
            value={currentBorder.width}
            change={updateBorder('width')}
            max={max}
          />
          {!withoutType && (
            <Select<string>
              labelText="settings_styles.border.type"
              options={borderTypeChoices}
              selectedOption={
                borderTypeChoices.find(
                  borderType => borderType.value === currentBorder.type,
                ) || borderTypeChoices[0]
              }
              labelIcon={isMobile ? <MobileIconUi /> : undefined}
              update={updateBorderType}
              isMulti={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default Border
