import React from 'react'
import { BaseEntityBodyInterface } from 'common/types/entities/EntityInterface'
import { getMobileBackgroundFileId } from 'common/utils/getMobileBackgroundFileId'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import { getReadableRootEntity } from 'client/store/page/pageSelectors'
import Range from '../../components/Range/Range'
import { useUpdateProp } from '../../hooks/useUpdateProps'
import GroupTitle from '../../styleComponents/GroupTitle'
import { BackgroundPositionStyleNew } from '../../styles'

export const Background = <T extends BaseEntityBodyInterface>() => {
  const { isMobile } = useDeviceModeContext()
  const entity = usePage(getReadableRootEntity) as T

  const updateProp = useUpdateProp<T>(entity)

  const getBackgroundPosition = () =>
    isMobile
      ? (entity.mobileBackground ?? entity.background)
      : entity.background

  const getFileId = () =>
    isMobile
      ? getMobileBackgroundFileId(
          entity.backgroundFileId,
          entity.mobileBackgroundFileId,
        )
      : entity.backgroundFileId

  return (
    <>
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        label="settings_styles.background_color.label"
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
      />

      <BackgroundPositionStyleNew
        backgroundPosition={getBackgroundPosition()}
        fileId={getFileId()}
        updateBackgroundFileId={updateProp(
          isMobile ? 'mobileBackgroundFileId' : 'backgroundFileId',
        )}
        update={updateProp(isMobile ? 'mobileBackground' : 'background')}
      />
      <Range
        label="page_settings.body.background_blur.label"
        value={entity.blur}
        change={updateProp('blur')}
        max={10}
      />
    </>
  )
}
