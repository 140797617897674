import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createCountdown } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/utils/create-countdown'
import {
  migrateFontSettings,
  migrateMargin,
  migrateMobileMargin,
} from '../utils/migrateUtils'
import { oldTypeToNew } from './oldTypeToNew'

export function migrateCountdown(entity: OldEntityInterface) {
  const countdown = createCountdown(entity.parentId, entity.masterBlockId)

  countdown.id = entity.id
  countdown.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    countdown.appearance.desktop = entity.options.appearance.desktop
    countdown.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(countdown, entity)
  migrateMobileMargin(countdown, entity)
  migrateFontSettings(countdown, entity)
  migrateReadOnly(countdown, entity)

  if (entity.options) {
    if (entity.options.timeStyles) {
      countdown.timeStyles.color = entity.options.timeStyles.color
      countdown.timeStyles.fontSize = parseInt(
        entity.options.timeStyles.fontSize,
      )
    }

    if (entity.options.labelStyles) {
      countdown.labelStyles.color = entity.options.labelStyles.color
      countdown.labelStyles.fontSize = parseInt(
        entity.options.labelStyles.fontSize,
      )
    }

    countdown.countdownType = oldTypeToNew(entity)

    countdown.action = entity.options.action

    if (entity.options.redirectUrl) {
      countdown.redirectUrl = entity.options.redirectUrl
    }

    countdown[CountdownTypesEnum.fixed].date =
      typeof entity.options.dateOptions.date === 'number'
        ? new Date(entity.options.dateOptions.date).toISOString()
        : entity.options.dateOptions.date

    countdown[CountdownTypesEnum.delay] = entity.options.minuteOptions

    countdown[CountdownTypesEnum.daily] = { ...entity.options.dailyOptions }
  }

  if (entity.mobileOptions) {
    if (entity.mobileOptions.timeStyles) {
      countdown.timeStyles.mobileColor = entity.mobileOptions.timeStyles.color
      countdown.timeStyles.mobileFontSize = parseInt(
        entity.mobileOptions.timeStyles.fontSize,
      )
    }

    if (entity.mobileOptions.labelStyles) {
      countdown.labelStyles.mobileColor = entity.mobileOptions.labelStyles.color
      countdown.labelStyles.mobileFontSize = parseInt(
        entity.mobileOptions.labelStyles.fontSize,
      )
    }
  }

  return countdown
}
