import React from 'react'
import { useTranslation } from 'react-i18next'
import { BaseEntityBodyInterface } from 'common/types/entities/EntityInterface'
import { Tooltip } from 'client/components/core/tooltip'
import { getRootEntity } from 'client/reducers/pageReducer'
import { usePage, useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { useUpdateProp } from '../../hooks/useUpdateProps'
import GroupTitle from '../../styleComponents/GroupTitle'
import OptionWrapper from '../../styleComponents/OptionWrapper'

export const Affiliate = <T extends BaseEntityBodyInterface>() => {
  const { t } = useTranslation()
  const entity = usePage(getRootEntity) as T
  const isUserFreemium = useManagement(managementSelectors.isUserFreemium)
  const updateProp = useUpdateProp<T>(entity)

  const toggleAffiliateBadge = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUserFreemium) {
      return
    }
    updateProp('isAffiliateBadgeVisible')(e.target.checked)
  }

  return (
    <>
      <GroupTitle>entity_settings.body.affiliate.title</GroupTitle>
      <OptionWrapper
        labelText="entity_settings.body.affiliate.badge.label"
        helpMessage="entity_settings.body.affiliate.badge.description"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            onChange={toggleAffiliateBadge}
            checked={isUserFreemium || entity?.isAffiliateBadgeVisible}
            type="checkbox"
            disabled={isUserFreemium}
            style={{ marginRight: 5 }}
          />
          {isUserFreemium && (
            <Tooltip
              message={t(
                'entity_settings.body.affiliate.badge.freemium_help_message',
              )}
            />
          )}
        </div>
      </OptionWrapper>
      <div style={{ marginBottom: 20 }} />
    </>
  )
}
