import { LocaleEnum } from 'common/enums/LocaleEnum'
import { useActions } from 'common/hooks/useActions'
import { BlockEntityInterface } from 'common/types/entities/EntityInterface'
import { addUserBlock, addUserBlockFile } from 'client/actions/blockActions'
import * as blockApi from 'client/api/blockApi'

interface CreateUserBlockParams {
  blockEntities: BlockEntityInterface[]
  entityId: string
  files: File[]
  locale: LocaleEnum
  title: string
}

export const useCreateUserBlock = () => {
  const addBlock = useActions(addUserBlock)
  const addFile = useActions(addUserBlockFile)

  const createUserBlock = async ({
    blockEntities,
    entityId,
    files,
    locale,
    title,
  }: CreateUserBlockParams) => {
    const screenshotRes = await blockApi.screenshotCreate(
      blockEntities,
      entityId,
      files,
      locale,
    )

    const { data } = await blockApi.createUserBlock(
      blockEntities,
      screenshotRes.data,
      title,
    )

    addBlock(data.block)
    addFile(data.desktopPreview)
    return data
  }

  return {
    createUserBlock,
  }
}
