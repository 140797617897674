import React from 'react'
import { LanguageSelector } from '../options'
import Affiliate from './components/AffiliateOld'
import Background from './components/BackgroundOld'
import Seo from './components/Seo'
import Tracking from './components/Tracking'
import Typography from './components/Typography'

function CommonPageSettings() {
  return (
    <>
      <Typography />
      <LanguageSelector />
      <Background />
      <Seo />
      <Tracking />
      <Affiliate />
    </>
  )
}

export default CommonPageSettings
