import React from 'react'
import PropTypes from 'prop-types'
import { Translation } from 'react-i18next'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import SelectUi from './ui/SelectUi'

/**
 * @typedef {Object} SelectProps
 * @property {function} update - Function to call when selection changes.
 * @property {string|null} [value] - The currently selected value.
 * @property {string} [labelText] - Text label for the select element.
 * @property {(React.ReactElement|boolean)} [labelIcon] - Icon to display with the label.
 * @property {Object.<string, any>} choiceList - Object containing the available options.
 * @property {string} [firstOption] - Text for the first option in the select.
 * @property {string} [dataTestElement] - Element identifier for testing.
 * @property {boolean} [needTranslate] - Whether options need translation.
 * @property {string} [helpMessage] - Help text to display with the select.
 */

/**
 * Select component
 * @param {SelectProps} props
 * @returns {React.ReactElement}
 */
const Select = ({
  value = '',
  firstOption = '',
  labelText = null,
  dataTestElement = '',
  labelIcon = null,
  needTranslate = true,
  helpMessage = '',
  update,
  choiceList,
}) => (
  <Translation ns={['client', 'common']}>
    {t => (
      <OptionWrapper
        labelText={labelText}
        labelIcon={labelIcon}
        helpMessage={helpMessage}
      >
        <SelectUi
          dataTestElement={dataTestElement}
          onChange={e => update(e.target.value)}
          value={value}
        >
          {firstOption && <option value="">{t(firstOption)}</option>}
          {Object.keys(choiceList).map(choiceKey => (
            <option key={choiceKey} value={choiceKey}>
              {needTranslate
                ? t([choiceList[choiceKey], `common:${choiceList[choiceKey]}`])
                : choiceList[choiceKey]}
            </option>
          ))}
        </SelectUi>
      </OptionWrapper>
    )}
  </Translation>
)

Select.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  choiceList: PropTypes.objectOf(PropTypes.any).isRequired,
  firstOption: PropTypes.string,
  dataTestElement: PropTypes.string.isRequired,
  needTranslate: PropTypes.bool,
  helpMessage: PropTypes.string,
}

export { Select as default }
