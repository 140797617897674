import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'

const browserDefaultLineHeight = 1.3

/**
 * @param {(string|number)} [lineHeight] - The line height value for desktop.
 * @param {(string|number)} [mobileLineHeight] - The line height value for mobile.
 * @param {(string|number)} [fontSize] - The font size value for desktop.
 * @param {(string|number)} [mobileFontSize] - The font size value for mobile.
 * @param {function} update - Function to update desktop line height.
 * @param {function} mobileUpdate - Function to update mobile line height.
 */
function LineHeight({
  fontSize = '18px',
  mobileFontSize = '18px',
  lineHeight = null,
  mobileLineHeight = null,
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()
  const parsedLineHeight = isMobile ? mobileLineHeight : lineHeight
  const getFontSize = () => Number.parseInt(isMobile ? mobileFontSize : fontSize, 0)
  const computedLineHeight = Math.round(
    browserDefaultLineHeight * getFontSize(),
  )
  const calculatedLineHeight = parsedLineHeight
    ? Number.parseInt(parsedLineHeight, 0)
    : computedLineHeight

  const updateProp = value => {
    if (value < 8) {
      return
    }

    return isMobile ? mobileUpdate(value) : update(value)
  }

  return (
    <Range
      labelText="settings_styles.line_height.label"
      labelIcon={isMobile && <MobileIconUi />}
      value={calculatedLineHeight}
      update={updateProp}
      min={8}
    />
  )
}

LineHeight.propTypes = {
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

export default memo(LineHeight)
