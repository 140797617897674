import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPageBodyInterface } from 'common/types/entities/blog-page-body-interface'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { usePage } from 'client/store'
import { getFirstEntityByType } from 'client/store/page/pageSelectors'
import BlogPageSettingsOld from './BlogPageSettings'
import Seo from './components/Seo'
import Tracking from './components/Tracking'
import Typography from './components/Typography'
import { Background } from './components/background'

export const BlogPageBodyResolver = () => {
  const isMigratedBlogPageBodyEntity = usePage(page =>
    getFirstEntityByType(page, EntityTypeEnum.BlogPageBody),
  )

  if (isMigratedBlogPageBodyEntity) {
    return <BlogPageSettings />
  }
  return <BlogPageSettingsOld />
}

export const BlogPageSettings = () => {
  // @description: for BlogPageBody

  const parentGlobalSettings = usePresentPage(selectors.getParentGlobalSettings)
  return (
    <>
      {/* no parent settings - blog page without layout */}
      {Object.keys(parentGlobalSettings).length === 0 && <Typography />}
      <Background<BlogPageBodyInterface> />
      <Seo />
      <Tracking />
    </>
  )
}
