import React from 'react'
import PropTypes from 'prop-types'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input'

const Height = ({ height = '100%', update }) => (
  <Input
    labelText="Height"
    update={update}
    value={+height}
    placeholder={'PX'}
    type="number"
  />
)

Height.propTypes = {
  update: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Height
