import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Select from '../components/Select'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

const boxShadowKeys = {
  noShadow: 'noShadow',
  softShadow: 'softShadow',
  midShadow: 'midShadow',
  hardShadow: 'hardShadow',
  farShadow: 'farShadow',
  blurryShadow: 'blurryShadow',
  darkWithHighlight: 'darkWithHighlight',
  softShadowInset: 'softShadowInset',
  midShadowInset: 'midShadowInset',
  hardShadowInset: 'hardShadowInset',
}

const {
  noShadow,
  softShadow,
  midShadow,
  hardShadow,
  farShadow,
  blurryShadow,
  darkWithHighlight,
  softShadowInset,
  midShadowInset,
  hardShadowInset,
} = boxShadowKeys

const boxShadowNames = {
  [noShadow]: 'settings_styles.shadow.now_shadow',
  [softShadow]: 'settings_styles.shadow.soft_shadow',
  [midShadow]: 'settings_styles.shadow.mid_shadow',
  [hardShadow]: 'settings_styles.shadow.hard_shadow',
  [farShadow]: 'settings_styles.shadow.far_shadow',
  [blurryShadow]: 'settings_styles.shadow.blurry_shadow',
  [darkWithHighlight]: 'settings_styles.shadow.dark_with_highlight',
  [softShadowInset]: 'settings_styles.shadow.soft_inner_shadow',
  [midShadowInset]: 'settings_styles.shadow.mid_inner_shadow',
  [hardShadowInset]: 'settings_styles.shadow.hard_inner_shadow',
}

const boxShadowValues = {
  [noShadow]: 'none',
  [softShadow]: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
  [midShadow]: '0 1px 5px rgba(0, 0, 0, 0.4)',
  [hardShadow]: '0 1px 5px rgba(0, 0, 0, 0.7)',
  [farShadow]: '0 8px 1px rgba(0, 0, 0, 0.1)',
  [blurryShadow]:
    '0 0 25px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0.2), 0 0 3px rgba(0, 0, 0, 0.4)',
  [darkWithHighlight]:
    '0 0 25px rgba(0, 0, 0, 0.4), 0 0 15px rgba(255, 255, 255, 0.2), 0 0 3px rgba(255, 255, 255, 0.4)',
  [softShadowInset]: 'inset 0 1px 5px rgba(0, 0, 0, 0.2)',
  [midShadowInset]: 'inset 0 1px 5px rgba(0, 0, 0, 0.4)',
  [hardShadowInset]: 'inset 0 1px 5px rgba(0, 0, 0, 0.7)',
}

function ShadowStyle({
  shadow = boxShadowKeys.noShadow,
  mobileShadow,
  isTitleNeeded = true,
  labelText = '',
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()

  const getShadowValue = () => {
    const calculatedShadow = isMobile ? mobileShadow || shadow : shadow

    return Object.keys(boxShadowValues).find(
      boxShadowKey => boxShadowValues[boxShadowKey] === calculatedShadow,
    )
  }

  const updateProp = boxShadowKey => {
    const value = boxShadowValues[boxShadowKey]
    return isMobile ? mobileUpdate(value) : update(value)
  }

  return (
    <React.Fragment>
      {isTitleNeeded && (
        <GroupTitle afterChildren={isMobile && <MobileIconUi />}>
          settings_styles.shadow.label
        </GroupTitle>
      )}
      <Select
        value={getShadowValue()}
        update={updateProp}
        choiceList={boxShadowNames}
        labelText={labelText}
      />
    </React.Fragment>
  )
}

ShadowStyle.propTypes = {
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  shadow: PropTypes.string,
  mobileShadow: PropTypes.string,
  isTitleNeeded: PropTypes.bool,
  labelText: PropTypes.string,
}

export default memo(ShadowStyle)
