import styled from 'styled-components'

const ToolboxItemUi = styled.div`
  transition: opacity 0.25s ease-in;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  background-color: ${({ $isDragging }) =>
    $isDragging ? '#ebedf0' : '#ffffff'};
  width: 92px;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 3px;
  border: solid 1px #ebedf0;
  text-align: center;
  cursor: move;
  padding: 6px;
  > span {
    opacity: ${({ $isDragging }) => ($isDragging ? 0 : 1)};
  }
  ${({ styles }) => styles}
`

export default ToolboxItemUi
