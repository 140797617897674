import PropTypes from 'prop-types'
import React, { memo } from 'react'
import styled from 'styled-components'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import OptionWrapper from '../styleComponents/OptionWrapper'
import MobileIconUi from '../styles/ui/MobileIconUi'

const ALIGN_LEFT = 'left'
const ALIGN_CENTER = 'center'
const ALIGN_RIGHT = 'right'

const AlignButton = styled.button`
  width: 33.3%;
  float: left;
  margin-bottom: 0;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  color: ${({ active }) => (active ? '#707e8d' : '#777')};
  background-color: ${({ active }) => (active ? '#ebeef1' : '#f9f9f9')};
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    background-color: #ebeef1;
    color: #707e8d;
  }
`

/**
 * Component for styling text alignment.
 *
 * @typedef {Object} TextAlignStyleProps
 * @param {string} [label] - The label for the text align style control
 * @param {string} textAlign - The current text alignment value
 * @param {string} mobileTextAlign - The current text alignment value for mobile view
 * @param {Function} update - Function to update the text alignment
 * @param {Function} mobileUpdate - Function to update the text alignment for mobile view
 */
function TextAlignStyle(props) {
  const {
    update,
    mobileUpdate,
    mobileTextAlign,
    textAlign,
    label = 'settings_styles.text_align.label',
    removeEntityMobileStyle,
    removeEntityStyle,
    isResetIconVisible = false,
  } = props
  const { isMobile } = useDeviceModeContext()

  function getTextAlign() {
    return isMobile ? mobileTextAlign : textAlign
  }

  const updateAlign = align => (isMobile ? mobileUpdate(align) : update(align))

  const handleReset = () =>
    isMobile ? removeEntityMobileStyle() : removeEntityStyle()

  return (
    <OptionWrapper
      labelText={label}
      labelIcon={isMobile && <MobileIconUi />}
      resetText="settings_styles.text_align.default"
      isResetIconVisible={isResetIconVisible}
      onReset={handleReset}
    >
      <AlignButton
        onClick={() => updateAlign(ALIGN_LEFT)}
        active={ALIGN_LEFT === getTextAlign()}
      >
        <span className="fas fa-align-left" />
      </AlignButton>
      <AlignButton
        onClick={() => updateAlign(ALIGN_CENTER)}
        active={ALIGN_CENTER === getTextAlign()}
      >
        <span className="fas fa-align-center" />
      </AlignButton>
      <AlignButton
        onClick={() => updateAlign(ALIGN_RIGHT)}
        active={ALIGN_RIGHT === getTextAlign()}
      >
        <span className="fas fa-align-right" />
      </AlignButton>
    </OptionWrapper>
  )
}

TextAlignStyle.propTypes = {
  label: PropTypes.string,
  textAlign: PropTypes.string,
  mobileTextAlign: PropTypes.string,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func,
  removeEntityStyle: PropTypes.func,
  isResetIconVisible: PropTypes.bool,
}

export default memo(TextAlignStyle)
