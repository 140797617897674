import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { blogPageTypes, pageTypes } from 'common/constants/pageTypes'
import { buttonActionsTypes } from 'common/constants/settings'
import {
  RedirectionTypesEnum,
  sendFormRedirectionChoices,
} from 'common/enums/ButtonActionEnum'
import {
  buttonActionsNames,
  ButtonActionTypeEnum,
  getButtonActions,
} from 'common/enums/ButtonActionTypeEnum'
import { buttonLinkTypeNames } from 'common/enums/ButtonLinkEnum'
import { useActions } from 'common/hooks/useActions'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { addUrlSchemaToTheLink } from 'common/utils/urlUtils'
import * as pageActions from 'client/actions/pageActions'
import {
  Select,
  Checkbox,
} from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import ColorPickerWithGradient from 'client/components/core/Sidebar/components/Settings/components/ColorPickerWithGradient'
import FontSize from 'client/components/core/Sidebar/components/Settings/components/Font/FontSize'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { UrlRedirect } from 'client/components/core/Sidebar/components/Settings/options'
import DelayOption from 'client/components/core/Sidebar/components/Settings/options/DelayOption'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  FlexAlignStyle,
  ShadowStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import TextLineHeight from 'client/components/core/Sidebar/components/Settings/styles/TextLineHeight'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { AutomationRuleTriggersEnum } from 'client/enums/AutomationRulesEnum'
import { usePage } from 'client/store'
import { useAutomationRules } from 'client/store'
import {
  saveAutomationRule,
  saveDeletedAutomationRule,
} from 'client/store/automationRules/automationRulesActions'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import { DataFile } from 'client/store/files/filesReducer'
import pageSelectors from 'client/store/page/pageSelectors'
import { automationRuleTriggerEntityProperties } from 'client/types/AutomationRulesActionsInterface'
import { FileUpload } from '../../components'
import AutomationActionsList from '../../components/AutomationRulesSettings/AutomationActionsList'
import AutomationRulesActionsModal from '../../components/AutomationRulesSettings/AutomationRulesActionsModal'
import automationRulePageTypes from '../../components/AutomationRulesSettings/utils/automationRulesPageTypes'
import BlogLinkAction from '../../components/BlogLink'
import ButtonIconPicker from '../../components/ButtonIconPicker'
import HoverButtonSetting from '../../components/HoverButton'
import PrimaryButton from '../../components/PrimaryButton'
import ButtonWidth from '../../styles/ButtonWidth'

export interface ButtonSettingsProps {
  entity: ButtonInterface
}

const ButtonSettingsNew = ({ entity }: ButtonSettingsProps) => {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<ButtonInterface>()
  const dispatch = useDispatch()

  const popups = usePage(pageSelectors.getPopupEntities)
  const pageType = usePage(pageSelectors.getPageType)
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const doubleOptIn = usePage(pageSelectors.getDoubleOptIn)
  const toggleDoubleOptInAction = useActions(pageActions.toggleDoubleOptIn)
  const [isAutomationsModalOpen, setIsAutomationsModalOpen] = useState(false)
  const originalButtonActions = useAutomationRules(state =>
    automationRulesSelectors.getOriginalAutomationRulesByButtonIdId(
      state,
      entity.id,
    ),
  )

  const editedButtonActions = useAutomationRules(state =>
    automationRulesSelectors.getEditedAutomationRulesByEntityId(
      state,
      entity.id,
      automationRuleTriggerEntityProperties.buttonId,
    ),
  )

  const updateAction = (action: ButtonActionTypeEnum) => {
    const popup = action !== ButtonActionTypeEnum.ShowPopup ? '' : entity.popup

    const needResetRedirectionType =
      action === ButtonActionTypeEnum.SendForm &&
      entity.action !== ButtonActionTypeEnum.SendForm

    const newRedirectionType = blogPageTypes.includes(pageType)
      ? RedirectionTypesEnum.customUrl
      : RedirectionTypesEnum.nextStep

    const updatedEntity = {
      ...entity,
      action,
      redirectionType: needResetRedirectionType
        ? newRedirectionType
        : entity.redirectionType,
      popup,
    }

    const needResetFile =
      action === ButtonActionTypeEnum.DownloadFile &&
      entity.action !== ButtonActionTypeEnum.DownloadFile
    if (needResetFile) {
      delete updatedEntity.fileId
    }

    const needResetTagId =
      action !== ButtonActionTypeEnum.SendForm && !!entity.tagId

    if (needResetTagId) {
      delete updatedEntity.tagId
    }

    const needResetBlogLink =
      entity.action === ButtonActionTypeEnum.BlogLink &&
      action !== ButtonActionTypeEnum.BlogLink

    if (needResetBlogLink) {
      updatedEntity.blogLink = ''
    }

    const needResetAutomationRules = action !== ButtonActionTypeEnum.SendForm
    // check if we have existing rules
    resetAutomationRules(
      needResetAutomationRules && editedButtonActions.length > 0,
    )
    updateEntity(updatedEntity)
  }

  const toggleDoubleOptIn = () => {
    toggleDoubleOptInAction()
  }

  const removeFileId = () => {
    const updatedEntity = { ...entity }
    delete updatedEntity.fileId
    updateEntity(updatedEntity)
  }

  const updatePopup = (popupId: string) => {
    updateProp('popup')(popupId)
  }

  const toggleNoFollow = (value: boolean) => {
    let noFollow = ''
    if (value) {
      noFollow = 'nofollow'
    }
    updateProp('rel')(noFollow)
  }

  const updateBlogLink = (value: string) => {
    const updatedEntity = {
      ...entity,
      blogLink: value,
    }
    updateEntity(updatedEntity)
  }

  const updateRedirectionType = (redirectionType: RedirectionTypesEnum) => {
    const updatedEntity = {
      ...entity,
      urlRedirect: '',
      redirectionType,
    }
    updateEntity(updatedEntity)
  }
  const popupChoiceList = popups.reduce(
    (popupChoices, popup) => ({
      ...popupChoices,
      // @ts-expect-error missing designated popup interface
      [popup.id]: popup.title ?? popup.options.title,
    }),
    {},
  )

  const resetAutomationRules = (condition: boolean) => {
    if (condition) {
      const automationRuleDeletedData = {
        automationTriggers: [
          {
            type: automationTriggerType,
            buttonId: entity.id,
          },
        ],
        automationActions: originalButtonActions,
      }
      const automationRuleData = {
        automationTriggers: [
          {
            type: automationTriggerType,
            buttonId: entity.id,
          },
        ],
        automationActions: [],
      }
      dispatch(saveDeletedAutomationRule(automationRuleDeletedData))
      dispatch(saveAutomationRule(automationRuleData))
    }
  }

  const isActionSendForm = entity.action === ButtonActionTypeEnum.SendForm
  const isRedirectionTypeCustomUrl =
    entity.redirectionType === RedirectionTypesEnum.customUrl
  const isActionBlogLink = entity.action === ButtonActionTypeEnum.BlogLink

  const pageButtonActions = getButtonActions(pageType)
  const availableButtonActions = pageButtonActions.reduce(
    (actions, action) => ({
      ...actions,
      [action]: buttonActionsNames[action],
    }),
    {} as Record<ButtonActionTypeEnum, string>,
  )

  const redirectionTypeNames = { ...sendFormRedirectionChoices }
  if (blogPageTypes.includes(pageType)) {
    delete redirectionTypeNames[RedirectionTypesEnum.nextStep]
  }

  const automationTriggerType = blogPageTypes.includes(pageType)
    ? AutomationRuleTriggersEnum.blog_page_form_subscribed
    : AutomationRuleTriggersEnum.form_subscribed

  const isAutomationRulesVisible =
    !isTemplate &&
    automationRulePageTypes.includes(pageType) &&
    isActionSendForm

  useEffect(() => {
    if (
      entity.action === buttonActionsTypes.nextStepRedirection &&
      blogPageTypes.includes(pageType)
    ) {
      updateEntity({
        ...entity,
        action: ButtonActionTypeEnum.SendForm,
        redirectionType: RedirectionTypesEnum.customUrl,
      })
    }
  }, [])

  const removeBackgroundColorProperty = () => {
    const updatedEntity = { ...entity }

    if (isMobile) {
      delete updatedEntity.mobileBackground
    } else {
      // @ts-expect-error optional
      delete updatedEntity.background
    }

    updateEntity(updatedEntity)
  }

  return (
    <React.Fragment>
      {!!pageButtonActions.length && (
        <Select
          update={updateAction}
          choiceList={availableButtonActions}
          labelText="Button action"
          value={entity.action}
        />
      )}
      {entity.action === buttonActionsTypes.downloadFile && (
        <>
          <FileUpload
            fileId={entity.fileId}
            afterUpload={(file: DataFile) => updateProp('fileId')(file.id)}
            removeFile={removeFileId}
            mimeTypes={mimeTypes.all}
          />
        </>
      )}
      {entity.action === buttonActionsTypes.showPopup && (
        <Select
          labelText="Popup"
          update={updatePopup}
          choiceList={popupChoiceList}
          firstOption="entity_settings.button.choose_actions"
          value={entity.popup}
          needTranslate={false}
        />
      )}
      {entity.action === buttonActionsTypes.openUrl && [
        <Input
          key="url"
          label="url"
          update={updateProp('linkUrl')}
          placeholder="entity_settings.button.url_input.placeholder"
          value={entity.linkUrl}
        />,
        <Select
          key="link-type"
          labelText="open in new window"
          update={updateProp('linkTarget')}
          choiceList={buttonLinkTypeNames}
          value={entity.linkTarget}
        />,
        <Checkbox
          key="noFollow"
          labelText="entity_settings.button.no_follow.label"
          helpMessage="entity_settings.button.no_follow.description"
          value={Boolean(entity.rel)}
          update={toggleNoFollow}
        />,
      ]}
      {isActionSendForm && (
        <Select
          labelText="entity_settings.button.send_form.redirection.label"
          value={entity.redirectionType}
          update={updateRedirectionType}
          dataTestElement="button-redirection-checkbox"
          choiceList={redirectionTypeNames}
        />
      )}
      {blogPageTypes.includes(pageType) && isActionBlogLink && (
        <React.Fragment>
          <BlogLinkAction
            update={updateBlogLink}
            blogLink={entity.blogLink as string}
            needOptionWrapper
          />
          <Select
            labelText="open in new window"
            update={updateProp('linkTarget')}
            choiceList={buttonLinkTypeNames}
            value={entity.linkTarget}
          />
          <Checkbox
            labelText="entity_settings.button.no_follow.label"
            helpMessage="entity_settings.button.no_follow.description"
            value={Boolean(entity.rel)}
            update={toggleNoFollow}
          />
        </React.Fragment>
      )}
      {isActionSendForm && isRedirectionTypeCustomUrl && (
        <UrlRedirect
          key="urlRedirect"
          urlRedirect={addUrlSchemaToTheLink(entity.urlRedirect)}
          update={updateProp('urlRedirect')}
          data-test-element="button-redirection-input"
        />
      )}
      {isActionSendForm && pageType !== pageTypes.webinarRegistration && (
        <Checkbox
          labelText="entity_settings.button.send_model.double_opt_in.label"
          update={toggleDoubleOptIn}
          value={doubleOptIn}
        />
      )}
      {isAutomationRulesVisible && (
        <>
          {editedButtonActions.length > 0 && (
            <AutomationActionsList actions={editedButtonActions} />
          )}
          <PrimaryButton
            onClick={() => {
              setIsAutomationsModalOpen(true)
            }}
            key={'automationSettingsButton'}
          >
            {t('entity_settings.automation_rule.edit.add_automation_rule')}
          </PrimaryButton>
          <AutomationRulesActionsModal
            key="automationSettingsModal"
            opened={isAutomationsModalOpen}
            triggerType={automationTriggerType}
            onClose={() => setIsAutomationsModalOpen(false)}
            //@ts-expect-error @TODO check
            actions={editedButtonActions}
            entityId={entity.id}
            triggerKey={'buttonId'}
          />
        </>
      )}
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <ButtonWidth
        update={updateProp('width')}
        mobileUpdate={updateProp('mobileWidth')}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <GroupTitle>typography</GroupTitle>
      <Input
        label="button text"
        value={entity.text}
        update={updateProp('text')}
      />
      <FontSize
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
        onChange={updateProp('textFontSize')}
        onChangeMobile={updateProp('mobileTextFontSize')}
      />
      <TextLineHeight
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        removeEntityStyle={updateProp('lineHeight')}
        removeEntityMobileStyle={updateProp('mobileLineHeight')}
      />
      <TextFontUpdater<ButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'textFontFamily'}
        fontWeightKey={'textFontWeight'}
        fontStyleKey={'textFontStyle'}
        mobileFontFamilyKey={'mobileTextFontFamily'}
        mobileFontWeightKey={'mobileTextFontWeight'}
        mobileFontStyleKey={'mobileTextFontStyle'}
        fontFileIdKey={'textFontFileId'}
        mobileFontFileIdKey={'mobileTextFontFileId'}
      />
      <Input
        label="entity_settings.button.sub_text.label"
        update={updateProp('subText')}
        value={entity.subText}
      />
      {entity.subText && (
        <FontSize
          label={'entity_settings.button.sub_text.font_size.label'}
          fontSize={entity.subTextFontSize}
          mobileFontSize={entity.mobileSubTextFontSize}
          onChange={updateProp('subTextFontSize')}
          onChangeMobile={updateProp('mobileSubTextFontSize')}
        />
      )}
      {entity.subText && (
        <TextFontUpdater
          entity={entity}
          update={updateEntity}
          fontFamilyKey={'subTextFontFamily'}
          fontWeightKey={'subTextFontWeight'}
          fontStyleKey={'subTextFontStyle'}
          mobileFontFamilyKey={'mobileSubTextFontFamily'}
          mobileFontWeightKey={'mobileSubTextFontWeight'}
          mobileFontStyleKey={'mobileSubTextFontStyle'}
        />
      )}
      <ButtonIconPicker
        valueBefore={entity.iconClassNameBefore}
        valueAfter={entity.iconClassNameAfter}
        updateBefore={updateProp('iconClassNameBefore')}
        updateAfter={updateProp('iconClassNameAfter')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPickerWithGradient
        label="background color"
        update={updateProp('background')}
        mobileUpdate={updateProp('mobileBackground')}
        color={entity.background}
        mobileColor={entity.mobileBackground}
        removeBackgroundColorProperty={removeBackgroundColorProperty}
      />
      <ColorPicker
        label="text color"
        update={updateProp('textColor')}
        mobileUpdate={updateProp('mobileTextColor')}
        color={entity.textColor}
        mobileColor={entity.mobileTextColor}
      />
      {entity.subText && (
        <ColorPicker
          label="entity_settings.button.sub_text.color.label"
          update={updateProp('subTextColor')}
          mobileUpdate={updateProp('mobileSubTextColor')}
          color={entity.subTextColor}
          mobileColor={entity.mobileSubTextColor}
        />
      )}
      <GroupTitle>padding</GroupTitle>
      <PaddingStyle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        showGroupTitle
        border={isMobile ? entity.mobileBorder : entity.border}
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <HoverButtonSetting
        textColor={entity.textColor}
        backgroundColor={entity.background ?? entity.backgroundColor}
        subTextColor={entity.subTextColor}
        update={updateProp('hover')}
        mobileUpdate={updateProp('mobileHover')}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default ButtonSettingsNew
