import PropTypes from 'prop-types'
import React from 'react'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import BaseFont from 'client/components/core/Sidebar/components/Settings/components/Font/BaseFont'
import TextAlignStyle from 'client/components/core/Sidebar/components/Settings/styles/TextAlignStyle'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import HeadingFont from '../../components/Font/HeadingFont'
import {
  useUpdateGlobalSetting,
  useUpdateGlobalSettings,
  useUpdateGlobalHeadingSettings,
} from '../../hooks/useUpdateGlobalSetting'
import GroupTitle from '../../styleComponents/GroupTitle'
import FontSize from '../../styles/FontSize'
import LineHeight from '../../styles/LineHeight'

function Typography({ skipFont = false, isRootSetting = true }) {
  const globalSettings = usePage(pageSelectors.getGlobalSettings)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const headingColor = usePage(pageSelectors.getGlobalHeadingColor)
  const textAlign = usePage(pageSelectors.getGlobalTextAlign)
  const mobileTextAlign = usePage(pageSelectors.getGlobalMobileTextAlign)
  const headingAlign = usePage(pageSelectors.getGlobalHeadingAlign)
  const mobileHeadingAlign = usePage(pageSelectors.getGlobalMobileHeadingAlign)
  const updateGlobalSetting = useUpdateGlobalSetting()
  const updateGlobalFontStyle = useUpdateGlobalSettings()
  const updateGlobalHeadingFontStyle = useUpdateGlobalHeadingSettings()
  const globalFontFamily = usePage(pageSelectors.getGlobalFontFamily)

  return (
    <div>
      <GroupTitle>page_settings.typography.title</GroupTitle>
      {!skipFont && (
        <BaseFont
          inheritedFontTypeLabel={'settings_styles.font_family.default'}
          fontFamily={globalSettings.fontFamily}
          fontStyle={globalSettings.fontStyle}
          fontWeight={globalSettings.fontWeight}
          update={updateGlobalFontStyle}
          globalFontFamily={globalFontFamily}
          isRootSetting={isRootSetting}
        />
      )}
      <FontSize
        fontSize={globalSettings.textFontSize}
        mobileFontSize={globalSettings.mobileTextFontSize}
        update={updateGlobalSetting('textFontSize', 'px')}
        mobileUpdate={updateGlobalSetting('mobileTextFontSize', 'px')}
      />
      <LineHeight
        lineHeight={globalSettings.textLineHeight}
        mobileLineHeight={globalSettings.mobileTextLineHeight}
        fontSize={globalSettings.textFontSize}
        mobileFontSize={globalSettings.mobileTextFontSize}
        update={updateGlobalSetting('textLineHeight', 'px')}
        mobileUpdate={updateGlobalSetting('mobileTextLineHeight', 'px')}
      />
      <ColorPicker
        update={updateGlobalSetting('linkColor')}
        color={linkColor}
        label="global_settings.link_color.label"
        singleColorMode
      />
      <ColorPicker
        update={updateGlobalSetting('textColor')}
        color={textColor}
        label="global_settings.text_color.label"
        singleColorMode
      />
      <TextAlignStyle
        textAlign={textAlign}
        mobileTextAlign={mobileTextAlign}
        update={updateGlobalSetting('textAlign')}
        mobileUpdate={updateGlobalSetting('mobileTextAlign')}
      />
      <GroupTitle>page_settings.typography.heading_title</GroupTitle>
      {!skipFont && (
        <HeadingFont
          fontFamily={globalSettings.headingFontFamily}
          fontStyle={globalSettings.headingFontStyle}
          fontWeight={globalSettings.headingFontWeight}
          update={updateGlobalHeadingFontStyle}
        />
      )}
      <ColorPicker
        update={updateGlobalSetting('headingColor')}
        color={headingColor}
        label="global_settings.text_color.label"
        singleColorMode
      />
      <TextAlignStyle
        textAlign={headingAlign}
        mobileTextAlign={mobileHeadingAlign}
        update={updateGlobalSetting('headingAlign')}
        mobileUpdate={updateGlobalSetting('mobileHeadingAlign')}
      />
    </div>
  )
}

Typography.propTypes = {
  skipFont: PropTypes.bool,
  isRootSetting: PropTypes.bool,
}

export default Typography
