import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ResetIcon from 'client/components/core/Sidebar/components/Settings/styles/ui/ResetIcon'
import { Tooltip } from 'client/components/core/tooltip'
import Item from './Item'
import Label from './Label'
import Value from './Value'

function OptionWrapper(props) {
  const {
    labelText = null,
    labelIcon = null,
    isResetIconVisible = false,
    resetText = '',
    directionRow = false,
    children,
    helpMessage = '',
    needTranslate = true,
    itemStyles = {},
    width,
    onReset,
  } = props
  const { t } = useTranslation()

  return (
    <Item itemStyle={itemStyles} width={width} directionRow={directionRow}>
      {labelText && (
        <Label directionRow={directionRow}>
          {labelText && helpMessage ? (
            <Tooltip message={needTranslate ? t(helpMessage) : helpMessage}>
              {needTranslate ? t(labelText) : labelText}
            </Tooltip>
          ) : needTranslate ? (
            t(labelText)
          ) : (
            labelText
          )}
          {labelIcon}
          {isResetIconVisible && (
            <ResetIcon onClick={onReset} title={t(resetText)} />
          )}
        </Label>
      )}

      <Value directionRow={directionRow}>{children}</Value>
    </Item>
  )
}

OptionWrapper.propTypes = {
  labelText: PropTypes.string,
  resetText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  directionRow: PropTypes.bool,
  isResetIconVisible: PropTypes.bool,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  helpMessage: PropTypes.string,
  needTranslate: PropTypes.bool,
  itemStyles: PropTypes.object,
  width: PropTypes.number,
  onReset: PropTypes.func,
}

export { OptionWrapper as default, Value, Label, Item }
