import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import EmptyList from 'common/components/core/EmptyList'
import { localeNames } from 'common/constants/localeTypes'
import CloseIcon from 'common/icons/CloseIcon'
import CoreButton from 'client/components/core/BlueButton/ui/BlueButtonUi'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogSubmit,
} from 'client/components/core/Dialog'
import { useUserResources } from 'client/context/UserResourcesContext'
import {
  AutomationRuleActionsDescriptionEnum,
  AutomationRuleActionsEnum,
  AutomationRuleActionsTitleEnum,
  AutomationRuleTriggersEnum,
} from 'client/enums/AutomationRulesEnum'
import { useAutomationRules } from 'client/store'
import { saveAutomationRule } from 'client/store/automationRules/automationRulesActions'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import {
  AccessTypeEnum,
  ActionEditType,
} from 'client/types/AutomationRulesActionsEditInterface'
import { AutomationRuleSaveTriggerInterface } from 'client/types/AutomationRulesActionsInterface'
import { getSaveRuleData } from 'client/utils/getRuleApiData'
import {
  changeResourcesState,
  getBaseLocaleOptions,
  getResourceSelectedOption,
  validateAutomationResources,
} from 'client/utils/userResourcesUtils'
import Input from '../Input/Input'
import ReactSelect from '../ReactSelect/ReactSelect'
import AddActionModal from './AddActionModal'
import CourseAccessSelector from './CourseAccessTypeSelector'
import ActionModalHeaderUi from './ui/ActionModalHeaderUi'
import AutomationActionContainerUi from './ui/AutomationActionContainerUi'
import AutomationActionIconContainerUi from './ui/AutomationActionIconContainerUi'
import AutomationEditActionDescriptionContainerUi from './ui/AutomationEditActionDescriptionContainerUi'
import AutomationRuleCloseButtonContainerUi from './ui/AutomationRuleCloseButtonContainerUi'
import EditActionsWrapperUi from './ui/AutomationRuleFlexContainerUi'
import AutomationRuleFlexContainerUi from './ui/AutomationRuleFlexContainerUi'
import AutomationRuleSpanUi from './ui/AutomationRuleSpanUi'
import { getActionIcon } from './utils/get-rule-icon'

interface AutomationRulesActionsModalProps {
  opened: boolean
  onClose: () => void
  actions: ActionEditType[]
  entityId: string
  triggerType: AutomationRuleTriggersEnum
  triggerKey: keyof AutomationRuleSaveTriggerInterface
}

function AutomationRulesActionsModal({
  opened,
  onClose,
  actions,
  entityId,
  triggerType,
  triggerKey,
}: AutomationRulesActionsModalProps) {
  const maxActionId = useRef(0)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [existingActions, setExistingActions] = useState<
    AutomationRulesActionsModalProps['actions']
  >([])
  const [errors, setErrors] = useState<Record<number, string>>({})
  const [tempActions, setTempActions] = useState<
    AutomationRulesActionsModalProps['actions']
  >([])
  const dispatch = useDispatch()

  const deletedActions = useAutomationRules(state =>
    automationRulesSelectors.getDeletedAutomationRulesByEntityId(
      state,
      entityId,
      triggerKey,
    ),
  )
  const { setShouldFetch, userResources, isResourceLoading } =
    useUserResources()

  const { t } = useTranslation()

  const currentActions = [...existingActions, ...tempActions]

  useEffect(() => {
    if (actions) {
      setExistingActions(actions)
    }
  }, [actions])

  const onDelete = (id: number) => {
    setTempActions(prev => {
      return prev.filter(el => el.id !== id)
    })
    setExistingActions(prev => {
      return prev.filter(el => el.id !== id)
    })
  }

  const handleSave = () => {
    setErrors([])
    const { errors, haveEmptyFields } = validateAutomationResources(
      tempActions,
      t,
    )

    if (haveEmptyFields) {
      setErrors(errors)
      return
    }

    const automationRuleData = {
      automationTriggers: [
        {
          type: triggerType,
          [triggerKey]: entityId,
        },
      ],
      automationActions: getSaveRuleData(
        existingActions,
        tempActions,
        deletedActions,
      ),
    }

    dispatch(saveAutomationRule(automationRuleData))
    setTempActions([])
    onClose()
  }

  return (
    <Dialog width={900} show={opened} small={false}>
      <DialogHeader grayHeader={false} close={onClose}>
        <ActionModalHeaderUi>
          {t('entity_settings.automation_rule.button_automation_rule_settings')}
        </ActionModalHeaderUi>
      </DialogHeader>
      <DialogBody>
        <AutomationRuleFlexContainerUi
          gap={20}
          justifyContent={'space-between'}
        >
          {currentActions.length > 0 ? (
            currentActions.map(action => {
              switch (action.type) {
                case AutomationRuleActionsEnum.add_tag:
                case AutomationRuleActionsEnum.remove_tag:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        options={userResources.userTags}
                        labelText="entity_settings.tag.title"
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userTags,
                          action.tag.id,
                        )}
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.add_tag]: true,
                          }))
                        }
                        isMulti={false}
                        placeholder={t(
                          'entity_settings.automation_rule.choose_tag',
                        )}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.add_tag}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.subscribe_to_campaign:
                case AutomationRuleActionsEnum.unsubscribe_from_campaign:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect<number>
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.subscribe_to_campaign]:
                              true,
                          }))
                        }
                        options={userResources.userCampaigns}
                        placeholder={t(
                          'entity_settings.automation_rule.choose_campaign',
                        )}
                        labelText="entity_settings.campaign.title"
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userCampaigns,
                          action.campaign.id,
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.subscribe_to_campaign}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.send_email:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.send_email]: true,
                          }))
                        }
                        options={userResources.userEmails}
                        labelText="entity_settings.email.title"
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userEmails,
                          action.systemeEmail.id,
                        )}
                        placeholder={t(
                          'entity_settings.automation_rule.choose_email',
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.send_email}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.send_email_to_specific_address:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.send_email_to_specific_address]:
                              true,
                          }))
                        }
                        options={userResources.userSpecificEmails}
                        labelText="entity_settings.email.title"
                        placeholder={t(
                          'entity_settings.automation_rule.choose_email',
                        )}
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userSpecificEmails,
                          action.emailMessageWithRecipient.id,
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={
                          isResourceLoading.send_email_to_specific_address
                        }
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.enroll_in_course:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <>
                        <ReactSelect
                          update={option =>
                            changeResourcesState({
                              action,
                              option,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                            })
                          }
                          onOpen={() =>
                            setShouldFetch(prev => ({
                              ...prev,
                              [AutomationRuleActionsEnum.enroll_in_course]:
                                true,
                            }))
                          }
                          options={userResources.userCourses}
                          labelText="entity_settings.courses.title"
                          selectedOption={getResourceSelectedOption(
                            action,
                            userResources.userCourses,
                            action.course.id,
                          )}
                          placeholder={t(
                            'entity_settings.automation_rule.choose_course',
                          )}
                          isMulti={false}
                          itemStyles={{ padding: '0px' }}
                          isLoading={isResourceLoading.enroll_in_course}
                          menuPortalTarget={document.body}
                          error={errors[action.id]}
                        />
                        <CourseAccessSelector
                          changeAccessType={data =>
                            changeResourcesState({
                              action,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                              accessType: data,
                            })
                          }
                          type={action.type}
                          value={
                            action.courseAccessType || AccessTypeEnum.fullAccess
                          }
                        />
                      </>
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.revoke_access_to_course:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <>
                        <ReactSelect
                          update={option =>
                            changeResourcesState({
                              action,
                              option,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                            })
                          }
                          onOpen={() =>
                            setShouldFetch(prev => ({
                              ...prev,
                              [AutomationRuleActionsEnum.revoke_access_to_course]:
                                true,
                            }))
                          }
                          options={userResources.userCourses}
                          labelText="entity_settings.courses.title"
                          selectedOption={getResourceSelectedOption(
                            action,
                            userResources.userCourses,
                            action.course.id,
                          )}
                          placeholder={t(
                            'entity_settings.automation_rule.choose_course',
                          )}
                          isMulti={false}
                          itemStyles={{ padding: '0px' }}
                          isLoading={isResourceLoading.revoke_access_to_course}
                          menuPortalTarget={document.body}
                          error={errors[action.id]}
                        />
                      </>
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <>
                        <ReactSelect
                          update={option =>
                            changeResourcesState({
                              action,
                              option,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                            })
                          }
                          onOpen={() =>
                            setShouldFetch(prev => ({
                              ...prev,
                              [AutomationRuleActionsEnum.revoke_access_to_course_bundle]:
                                true,
                            }))
                          }
                          options={userResources.userCourseBundles}
                          labelText="entity_settings.course_bundle.title_one"
                          selectedOption={getResourceSelectedOption(
                            action,
                            userResources.userCourseBundles,
                            action.courseBundle.id,
                          )}
                          placeholder={t(
                            'entity_settings.automation_rule.choose_course_bundle',
                          )}
                          isMulti={false}
                          itemStyles={{ padding: '0px' }}
                          isLoading={
                            isResourceLoading.revoke_access_to_course_bundle
                          }
                          menuPortalTarget={document.body}
                          error={errors[action.id]}
                        />
                      </>
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.enroll_in_course_bundle:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <>
                        <ReactSelect
                          update={option =>
                            changeResourcesState({
                              action,
                              option,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                            })
                          }
                          onOpen={() =>
                            setShouldFetch(prev => ({
                              ...prev,
                              [AutomationRuleActionsEnum.enroll_in_course_bundle]:
                                true,
                            }))
                          }
                          options={userResources.userCourseBundles}
                          labelText="entity_settings.course_bundle.title_one"
                          selectedOption={getResourceSelectedOption(
                            action,
                            userResources.userCourseBundles,
                            action.courseBundle.id,
                          )}
                          placeholder={t(
                            'entity_settings.automation_rule.choose_course_bundle',
                          )}
                          isMulti={false}
                          itemStyles={{ padding: '0px' }}
                          isLoading={isResourceLoading.enroll_in_course_bundle}
                          menuPortalTarget={document.body}
                          error={errors[action.id]}
                        />
                        <CourseAccessSelector
                          changeAccessType={data =>
                            changeResourcesState({
                              action,
                              existingActions,
                              setExistingActions,
                              setTempActions,
                              accessType: data,
                            })
                          }
                          type={action.type}
                          value={
                            action.enrollmentAccessType ||
                            AccessTypeEnum.fullAccess
                          }
                        />
                      </>
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.grant_access_to_community:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.grant_access_to_community]:
                              true,
                          }))
                        }
                        options={userResources.userCommunities}
                        labelText="entity_settings.community.title"
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userCommunities,
                          action.community.id,
                        )}
                        placeholder={t(
                          'entity_settings.automation_rule.choose_community',
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.grant_access_to_community}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.revoke_access_to_community:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.revoke_access_to_community]:
                              true,
                          }))
                        }
                        options={userResources.userCommunities}
                        labelText="entity_settings.community.title"
                        selectedOption={getResourceSelectedOption(
                          action,
                          userResources.userCommunities,
                          action.community.id,
                        )}
                        placeholder={t(
                          'entity_settings.automation_rule.choose_community',
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.revoke_access_to_community}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.send_webhook:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <Input
                        update={value =>
                          changeResourcesState({
                            action,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                            value,
                          })
                        }
                        placeholder={t(
                          'entity_settings.automation_rule.type_webhook',
                        )}
                        itemStyles={{ padding: '0px' }}
                        label="entity_settings.automation_rule.webhook_url"
                        value={action.webhookUrl}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                case AutomationRuleActionsEnum.create_user:
                  return (
                    <EditActionsWrapper
                      action={action}
                      onDelete={onDelete}
                      key={`${action.type}-${action.id}`}
                    >
                      <ReactSelect
                        update={option =>
                          changeResourcesState({
                            action,
                            option,
                            existingActions,
                            setExistingActions,
                            setTempActions,
                          })
                        }
                        onOpen={() =>
                          setShouldFetch(prev => ({
                            ...prev,
                            [AutomationRuleActionsEnum.create_user]: true,
                          }))
                        }
                        options={getBaseLocaleOptions(t)}
                        labelText="entity_settings.automation_rule.action.new_user_language"
                        selectedOption={
                          action.locale
                            ? {
                                value: action.locale,
                                label: t(localeNames[action.locale]),
                              }
                            : null
                        }
                        placeholder={t(
                          'entity_settings.automation_rule.choose_language_of_user',
                        )}
                        isMulti={false}
                        itemStyles={{ padding: '0px' }}
                        isLoading={isResourceLoading.create_user}
                        menuPortalTarget={document.body}
                        error={errors[action.id]}
                      />
                    </EditActionsWrapper>
                  )
                default:
                  return <></>
              }
            })
          ) : (
            <EmptyList
              title={t('entity_settings.automation_rule.empty_list_title')}
              subTitle={t(
                'entity_settings.automation_rule.empty_list_sub_title',
              )}
            />
          )}
          <AddActionModal
            opened={isAddModalOpen}
            onClose={() => setIsAddModalOpen(false)}
            setRules={setTempActions}
            maxActionId={maxActionId}
          />
          <CoreButton
            onClick={() => setIsAddModalOpen(true)}
            disabled={!actions}
            style={{ width: 'max-content', alignSelf: 'center' }}
          >
            {t('entity_settings.automation_rule.edit.add_action')}
          </CoreButton>
        </AutomationRuleFlexContainerUi>
      </DialogBody>
      <DialogFooter>
        <DialogSubmit onClick={handleSave}>
          {t('entity_settings.automation_rule.edit.save')}
        </DialogSubmit>
      </DialogFooter>
    </Dialog>
  )
}

interface EditActionsWrapperProps {
  action: ActionEditType
  onDelete: (id: number) => void
  children: JSX.Element
}

const EditActionsWrapper = ({
  action,
  onDelete,
  children,
}: EditActionsWrapperProps) => {
  const { t } = useTranslation()

  return (
    <EditActionsWrapperUi key={`${action.type}-${action.id}`} gap={5}>
      <AutomationRuleFlexContainerUi
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <AutomationActionContainerUi>
          <AutomationActionIconContainerUi>
            {getActionIcon(action.type)}
          </AutomationActionIconContainerUi>
          <AutomationEditActionDescriptionContainerUi>
            <AutomationRuleSpanUi fontWeight={700}>
              {t(AutomationRuleActionsTitleEnum[action.type])}
            </AutomationRuleSpanUi>
            <AutomationRuleSpanUi>
              {t(AutomationRuleActionsDescriptionEnum[action.type])}
            </AutomationRuleSpanUi>
          </AutomationEditActionDescriptionContainerUi>
        </AutomationActionContainerUi>
        <AutomationRuleCloseButtonContainerUi
          onClick={() => onDelete(action.id)}
        >
          <CloseIcon />
        </AutomationRuleCloseButtonContainerUi>
      </AutomationRuleFlexContainerUi>
      {children}
    </EditActionsWrapperUi>
  )
}

export default AutomationRulesActionsModal
