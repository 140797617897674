import React from 'react'
import PropTypes from 'prop-types'
import Select from 'client/components/core/Sidebar/components/Settings/components/Select'
import { backgroundPositionNames } from 'common/constants/settings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import MobileIconUi from './ui/MobileIconUi'

const { cover, ...mobileBackgroundPositionNames } = backgroundPositionNames

function BackgroundPositionStyle({
  label = 'settings_styles.background_position.style',
  backgroundPosition,
  mobileBackgroundPosition,
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()

  function choosePosition(position) {
    isMobile ? mobileUpdate(position) : update(position)
  }

  return (
    <Select
      labelText={label}
      update={choosePosition}
      firstOption="settings_styles.background_position.choose"
      choiceList={
        isMobile ? mobileBackgroundPositionNames : backgroundPositionNames
      }
      value={
        isMobile
          ? mobileBackgroundPosition || backgroundPosition
          : backgroundPosition
      }
      labelIcon={isMobile && <MobileIconUi />}
    />
  )
}

BackgroundPositionStyle.propTypes = {
  label: PropTypes.string,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  backgroundPosition: PropTypes.string,
  mobileBackgroundPosition: PropTypes.string,
}

export default BackgroundPositionStyle
