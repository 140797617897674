import React from 'react'
import { useDispatch } from 'react-redux'
import CustomLoginInterface from 'common/types/entities/CustomLoginInterface'
import Select from 'client/components/core/Sidebar/components/Settings/components/Select'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { CustomLoginModeEnum } from 'client/pages/custom-login/entities/CustomLogin'
import { useCustomLogin } from 'client/store'
import { changeCustomLoginMode } from 'client/store/custom-login/customLoginActions'
import { selectors } from 'client/store/custom-login/customLoginReducer'
import { useUpdateProp } from '../../hooks/useUpdateProps'
import HtmlAttrId from '../../options/HtmlAttrId'
import MarginStyle from '../../styles/MarginStyle'

const accessModesChoices = {
  [CustomLoginModeEnum.Login]:
    'entity_settings.member_access.modes.choices.login',
  [CustomLoginModeEnum.Registration]:
    'entities.member_access.registration.default_headline',
  [CustomLoginModeEnum.Request]:
    'entity_settings.member_access.modes.choices.resetting',
  [CustomLoginModeEnum.Reset]:
    'entity_settings.member_access.modes.choices.resetting_reset',
}

type NewCustomLoginProps = {
  entity: CustomLoginInterface
}

function CustomLoginSettings({ entity }: NewCustomLoginProps) {
  const dispatch = useDispatch()
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const mode = useCustomLogin(selectors.getCustomLoginMode)

  return (
    <>
      <Select
        update={(mode: CustomLoginModeEnum) =>
          dispatch(changeCustomLoginMode(mode as CustomLoginModeEnum))
        }
        choiceList={accessModesChoices}
        labelText="entity_settings.member_access.modes.label"
        value={mode}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default CustomLoginSettings
