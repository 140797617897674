import migrateBlogContentPlaceholderCommand from 'tools/commands/migrate/BlogContentPlaceholder/migrateBlogContentPlaceholderCommand'
import migrateBlogLatestPostsCommand from 'tools/commands/migrate/BlogLatestPosts/migrateBlogLatestPostsCommand'
import migrateBlogPageBodyCommand from 'tools/commands/migrate/BlogPageBody/migrateBlogPageBodyCommand'
import migrateBlogPostCategoriesCommand from 'tools/commands/migrate/BlogPostCategories/migrateBlogPostCategoriesCommand'
import migrateBlogPostContentPlaceholderCommand from 'tools/commands/migrate/BlogPostContentPlaceholder/migrateBlogPostContentPlaceholderCommand'
import migrateBlogPostLIstingCommand from 'tools/commands/migrate/BlogPostListing/migrateBlogPostLIstingCommand'
import migrateInlineCommand from 'tools/commands/migrate/Inline/migrateInlineCommand'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { DEFAULT_AUTOMIGRATE_COMMAND_LIST } from 'client/const/migrate-consts'
import ServerPage from 'server/types/ServerPageInterface'

const migratePage = (page: ServerPage): ServerPage => {
  const commandList = DEFAULT_AUTOMIGRATE_COMMAND_LIST

  switch (page.type) {
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogPostBody:
    case PageTypeEnum.BlogPostLayout:
    case PageTypeEnum.BlogPostListing:
    case PageTypeEnum.BlogStatic:
      commandList.push(
        migrateBlogLatestPostsCommand,
        migrateBlogPostLIstingCommand,
        migrateBlogContentPlaceholderCommand,
        migrateBlogPostCategoriesCommand,
        migrateBlogPostContentPlaceholderCommand,
        migrateBlogPageBodyCommand,
      )
      break
    case PageTypeEnum.Inline:
      commandList.push(migrateInlineCommand)
      break
  }

  return commandList.reduce((page, command) => command(page)[0], page)
}

export default migratePage
