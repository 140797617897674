import AlignStyle from 'client/components/core/Sidebar/components/Settings/styles/AlignStyle'
import BackgroundPositionStyleNew from 'client/components/core/Sidebar/components/Settings/styles/BackgroundImageNew/BackgroundPositionStyleNew'
import BackgroundPositionStyle from 'client/components/core/Sidebar/components/Settings/styles/BackgroundPositionStyle'
import BorderStyle from 'client/components/core/Sidebar/components/Settings/styles/BorderStyle'
import BorderStyleNew from 'client/components/core/Sidebar/components/Settings/styles/BorderStyleNew'
import CornerStyle from 'client/components/core/Sidebar/components/Settings/styles/CornerStyle'
import CornerStyleAll from 'client/components/core/Sidebar/components/Settings/styles/CornerStyleAll'
import FlexAlignStyle from 'client/components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import FontSize from 'client/components/core/Sidebar/components/Settings/styles/FontSize'
import LineHeight from 'client/components/core/Sidebar/components/Settings/styles/LineHeight'
import MarginsStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginsStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/PaddingStyle'
import ShadowStyle from 'client/components/core/Sidebar/components/Settings/styles/ShadowStyle'
import TextAlignStyle from 'client/components/core/Sidebar/components/Settings/styles/TextAlignStyle'

export {
  BackgroundPositionStyle,
  CornerStyle,
  MarginsStyle,
  PaddingStyle,
  ShadowStyle,
  BorderStyle,
  AlignStyle,
  LineHeight,
  FontSize,
  TextAlignStyle,
  FlexAlignStyle,
  BorderStyleNew,
  CornerStyleAll,
  BackgroundPositionStyleNew,
}
