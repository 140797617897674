import React, { useEffect, useRef, memo } from 'react'
import { ThemeProvider } from 'styled-components'
import AffiliateBadge from 'common/components/entities/AffiliateBadge'
import { getMediaQueryByPageType } from 'common/constants/mediaQueries'
import { useActions } from 'common/hooks/useActions'
import GlobalStyles from 'common/utils/GlobalStyles'
import { getFilteredEntitiesIdsByPageType } from 'common/utils/entityUtils'
import { toggleNeedScroll } from 'client/actions/actionsManagement'
import CreateBlockDialog from 'client/components/core/CreateBlockDialog'
import { CreateEntityProvider } from 'client/context/CreateEntityContext'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { ResizingRowProvider } from 'client/context/ResizingRowContext'
import useManagement from 'client/hooks/useManagement'
import usePresentPage from 'client/hooks/usePresentPage'
import { createPage } from 'client/pages'
import {
  getNeedScroll,
  isCreateBlockDialogActive,
  getEditingId,
} from 'client/reducers/managementReducer'
import {
  getPageType,
  getRootEntity,
  getAllPageEntities,
} from 'client/reducers/pageReducer'
import { pageSelectors, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import useCustomFontFaces from '../../../hooks/useCustomFontFaces'
import usePageFonts from '../../../hooks/usePageFonts'
import PageUi from './ui/PageUi'

function Page() {
  const pageRef = useRef()
  const { isMobile } = useDeviceModeContext()
  const isDialogActive = useManagement(isCreateBlockDialogActive)
  const isUserFreemium = useManagement(managementSelectors.isUserFreemium)
  const needScroll = useManagement(getNeedScroll)
  const pageType = usePresentPage(getPageType)
  const rootEntity = usePresentPage(getRootEntity)
  const dispatchToggleNeedScroll = useActions(toggleNeedScroll)
  const editingEntity = useManagement(getEditingId)
  const pageEntities = usePresentPage(getAllPageEntities)
  const excludedEntitiesIds = getFilteredEntitiesIdsByPageType(
    pageEntities,
    pageType,
  )
  const globalScrollPaddingTop = usePage(
    pageSelectors.getGlobalScrollPaddingTop,
  )

  usePageFonts()
  useCustomFontFaces()

  useEffect(() => {
    if (needScroll) {
      pageRef.current.scrollTop = pageRef.current.scrollHeight
      dispatchToggleNeedScroll(false)
    }
  })

  useEffect(() => {
    const editingElement = document.getElementById(editingEntity)
    if (editingElement) {
      try {
        // don't scroll if in a view already but not centered
        editingElement.scrollIntoViewIfNeeded(true)
      } catch {
        // because scrollIntoViewIfNeeded is experimental
        editingElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [editingEntity, isMobile])

  const [Page, createEntityElement] = createPage(pageType)

  const showAffiliateBadge = Boolean(
    rootEntity?.options?.affiliateBadge ||
      rootEntity?.affiliateBadge ||
      rootEntity?.isAffiliateBadgeVisible ||
      isUserFreemium,
  )

  return (
    <PageUi ref={pageRef}>
      <GlobalStyles scrollPaddingTop={globalScrollPaddingTop} />
      <ThemeProvider
        theme={{
          phone: getMediaQueryByPageType(pageType).phone,
          desktop: getMediaQueryByPageType(pageType).desktop,
          isEditorMobileMode: isMobile,
          isEditor: true,
        }}
      >
        <CreateEntityProvider
          excludedEntitiesIds={excludedEntitiesIds}
          createEntityElement={createEntityElement}
        >
          <Page>
            <ResizingRowProvider>
              {createEntityElement(rootEntity)}
            </ResizingRowProvider>
            {isDialogActive && <CreateBlockDialog />}
          </Page>
        </CreateEntityProvider>
      </ThemeProvider>
      {showAffiliateBadge && <AffiliateBadge />}
    </PageUi>
  )
}

export default memo(Page)
