import axios from 'axios'
import { LocaleEnum } from 'common/enums/LocaleEnum'
import { BlockEntityInterface } from 'common/types/entities/EntityInterface'
import request from 'client/utils/request'

export const fetchBlocks = () => request.get('/blocks')

export const createMasterBlock = (
  content: BlockEntityInterface[],
  desktopPreview: string,
  title: string,
) =>
  request.post(`/masters/blocks`, {
    block: {
      content: JSON.stringify(content),
      title,
      desktopPreview,
    },
  })

export const createUserBlock = (
  content: BlockEntityInterface[],
  desktopPreview: string,
  title: string,
) =>
  request.post('/users/blocks', {
    block: {
      content: JSON.stringify(content),
      title,
      desktopPreview,
    },
  })

export const createCommonBlock = (
  locale: LocaleEnum,
  content: BlockEntityInterface[],
  desktopPreview: string,
  mobilePreview: string,
  title: string,
  category: string,
) => {
  return request.post('/commons/blocks', {
    block: {
      locale,
      content: JSON.stringify(content),
      title,
      category,
      desktopPreview,
      mobilePreview,
    },
  })
}

export const deleteUserBlock = (id: string) =>
  request.delete(`/users/${id}/block`)

export const deleteCommonBlock = (id: string) =>
  request.delete(`/commons/${id}/block`)

export const deleteMasterBlock = (id: string) =>
  request.delete(`/masters/${id}/block`)

export const screenshotCreate = (
  entities: BlockEntityInterface[],
  rootEntityId: string,
  files: File[],
  locale: LocaleEnum,
) =>
  axios.post('/block/screenshot/create', {
    entities,
    rootEntityId,
    files,
    locale,
  })
