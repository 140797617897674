import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import structureTypes from '../../../../common/constants/structureTypes'
import ServerPage from '../../../../server/types/ServerPageInterface'
import migrateBlogPostListing from './migrateBlogPostListing'

export default function (page: ServerPage): [ServerPage, boolean] {
  let isChanged = false
  try {
    const entities = Object.values(page.content.entities).reduce(
      (prev, entity) => {
        if (entity.type === structureTypes.BLOG_POST_LISTING) {
          isChanged = true
        }

        return {
          ...prev,
          [entity.id]:
            entity.type === structureTypes.BLOG_POST_LISTING
              ? migrateBlogPostListing(entity as OldEntityInterface)
              : entity,
        }
      },
      {},
    )
    return [
      {
        ...page,
        content: {
          ...page.content,
          entities,
        },
      },
      isChanged,
    ]
  } catch (e) {
    console.log(`--error--`, page, page.id)
    console.log(e)

    return [page, false]
  }
}
