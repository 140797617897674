import { migrateButton } from 'tools/commands/migrate/button/migrate-button'
import { migrateCommand } from 'tools/commands/migrate/utils/migrateCommand'
import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'

export default function (page: ServerPage) {
  return migrateCommand({
    page,
    structureType: structureTypes.BUTTON,
    migrateFunction: migrateButton,
  })
}
