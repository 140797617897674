import React from 'react'
import Skeleton from 'react-loading-skeleton'
import ToolboxGroupTitleUi from './ui/ToolboxGroupTitleUi'
import ToolboxGroupUi from './ui/ToolboxGroupUi'
import ToolboxItemListUi from './ui/ToolboxItemListUi'
import ToolboxItemTitleUi from './ui/ToolboxItemTitleUi'
import ToolboxItemUi from './ui/ToolboxItemUi'
import ToolboxUi from './ui/ToolboxUi'
import SidebarContainerUi from '../../ui/SidebarContainerUi'
import SidebarUi from '../../ui/SidebarUi'

function LibrarySkeleton() {
  return (
    <SidebarUi>
      <SidebarContainerUi>
        <ToolboxUi>
          <ToolboxGroupUi>
            <ToolboxGroupTitleUi>
              <Skeleton width={50} />
            </ToolboxGroupTitleUi>
            <ToolboxItemListUi>
              {Array.from(Array(6).keys())
                .map(index => `${index}`)
                .map(key => (
                  <ToolboxItemUi key={key}>
                    <Skeleton circle width={50} height={50} />
                    <ToolboxItemTitleUi>
                      <Skeleton width={60} height={10} />
                    </ToolboxItemTitleUi>
                  </ToolboxItemUi>
                ))}
            </ToolboxItemListUi>
          </ToolboxGroupUi>
          <ToolboxGroupUi>
            <ToolboxGroupTitleUi>
              <Skeleton width={70} />
            </ToolboxGroupTitleUi>
            <ToolboxItemListUi>
              {Array.from(Array(4).keys())
                .map(index => `${index}`)
                .map(key => (
                  <ToolboxItemUi key={key}>
                    <Skeleton circle width={50} height={50} />
                    <ToolboxItemTitleUi>
                      <Skeleton width={60} height={10} />
                    </ToolboxItemTitleUi>
                  </ToolboxItemUi>
                ))}
            </ToolboxItemListUi>
          </ToolboxGroupUi>
        </ToolboxUi>
      </SidebarContainerUi>
    </SidebarUi>
  )
}

export default LibrarySkeleton
