import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import FormGroup from 'common/components/core/FormGroup'
import InputLabel from 'common/components/core/InputLabel'
import Select from 'common/components/core/Select'
import { localeNames } from 'common/constants/localeTypes'
import { Errors } from '../'
import { categoryNames } from '../../Sidebar/components/Blocks/blockCategories'
import CreateBlogDialogErrorMessageUi from '../ui/CreateBlogDialogErrorMessageUi'

interface CommonBlockSettingsProps {
  locale: string
  setLocale: (value: string) => void
  category: string
  setCategory: (value: string) => void
  errors?: Errors
  desktopFileRef: React.RefObject<HTMLInputElement>
  handleDesktopInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  mobileFileRef: React.RefObject<HTMLInputElement>
  handleMobileInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CommonBlockSettings: FC<CommonBlockSettingsProps> = ({
  locale,
  setLocale,
  category,
  setCategory,
  errors,
  desktopFileRef,
  handleDesktopInputFileChange,
  mobileFileRef,
  handleMobileInputFileChange,
}) => {
  const { t } = useTranslation()

  const categoryNamesTranslated = Object.keys(categoryNames).reduce(
    (acc, categoryKey) => ({
      ...acc,
      [categoryKey]: t(categoryNames[categoryKey]),
    }),
    {},
  )
  return (
    <React.Fragment>
      <FormGroup>
        <InputLabel>
          {t('components.core.dialog.save_block.choose_language')}
        </InputLabel>
        <Select
          value={locale}
          handleChange={setLocale}
          choices={localeNames}
          needTranslate={true}
        />
        {errors?.locale &&
          errors.locale.map(error => (
            <CreateBlogDialogErrorMessageUi key={error}>
              {error}
            </CreateBlogDialogErrorMessageUi>
          ))}
      </FormGroup>
      <FormGroup>
        <InputLabel>
          {t('components.core.dialog.save_block.category')}
        </InputLabel>
        <Select
          firstChoice="components.core.dialog.save_block.choose_category"
          value={category}
          handleChange={setCategory}
          choices={categoryNamesTranslated}
          needTranslate={false}
        />
        {errors?.category &&
          errors.category.map(error => (
            <CreateBlogDialogErrorMessageUi key={error}>
              {error}
            </CreateBlogDialogErrorMessageUi>
          ))}
      </FormGroup>
      <FormGroup>
        <InputLabel>
          {t('components.core.dialog.save_block.desktop_preview')}
        </InputLabel>
        <input
          type="file"
          ref={desktopFileRef}
          onChange={handleDesktopInputFileChange}
        />
        {errors?.desktopPreview &&
          errors.desktopPreview.map(error => (
            <CreateBlogDialogErrorMessageUi key={error}>
              {error}
            </CreateBlogDialogErrorMessageUi>
          ))}
      </FormGroup>
      <FormGroup>
        <InputLabel>
          {t('components.core.dialog.save_block.mobile_preview')}
        </InputLabel>
        <input
          type="file"
          ref={mobileFileRef}
          onChange={handleMobileInputFileChange}
        />
        {errors?.mobilePreview &&
          errors.mobilePreview.map(error => (
            <CreateBlogDialogErrorMessageUi key={error}>
              {error}
            </CreateBlogDialogErrorMessageUi>
          ))}
      </FormGroup>
    </React.Fragment>
  )
}

export default CommonBlockSettings
