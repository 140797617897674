import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { AlignType } from 'common/types/styleTypes'

export function migrateFontSettings(
  newEntity: BlogPostCategoriesInterface,
  originalEntity: OldEntityInterface,
) {
  //text
  if (originalEntity.styles.fontFamily) {
    newEntity.fontFamily = originalEntity.styles.fontFamily
    newEntity.fontWeight = originalEntity.styles.fontWeight
    newEntity.fontStyle = originalEntity.styles.fontStyle
  }

  //mobileText
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.fontFamily) {
    newEntity.mobileFontFamily = originalEntity.mobileStyles.fontFamily
    newEntity.mobileFontWeight = originalEntity.mobileStyles.fontWeight
    newEntity.mobileFontStyle = originalEntity.mobileStyles.fontStyle
  }
}

export function migrateColor(
  newEntity: BlogPostCategoriesInterface,
  originalEntity: OldEntityInterface,
) {
  //textColor
  if (originalEntity.styles.color) {
    newEntity.color = originalEntity.styles.color
  }

  //mobileTextColor
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.color) {
    newEntity.mobileColor = originalEntity.mobileStyles.color
  }
}

export function migrateFontSize(
  newEntity: BlogPostCategoriesInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.styles.fontSize &&
    !isNaN(parseInt(originalEntity.styles.fontSize))
  ) {
    newEntity.fontSize = parseInt(originalEntity.styles.fontSize)
  }
  if (
    originalEntity.mobileStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileStyles.fontSize))
  ) {
    newEntity.mobileFontSize = parseInt(originalEntity.mobileStyles.fontSize)
  }
}

export interface AlignSelfInterface {
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
}

export function migrateAlignSelf(
  newEntity: AlignSelfInterface,
  originalEntity: OldEntityInterface,
) {
  if (originalEntity.styles.textAlign) {
    newEntity.alignSelf = originalEntity.styles.textAlign
    newEntity.mobileAlignSelf = originalEntity.styles.textAlign
  } else {
    newEntity.alignSelf = 'left'
    newEntity.mobileAlignSelf = 'left'
  }
}
