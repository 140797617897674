import styled from 'styled-components'

const HasChangesUi = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: red;
  height: 6px;
  width: 6px;
  border-radius: 3px;
`

export default HasChangesUi
