import { LocaleEnum } from 'common/enums/LocaleEnum'
import { useActions } from 'common/hooks/useActions'
import { BlockEntityInterface } from 'common/types/entities/EntityInterface'
import { addMasterBlock, addMasterBlockFile } from 'client/actions/blockActions'
import { markAsMasterBlock } from 'client/actions/entityActions'
import * as blockApi from 'client/api/blockApi'
import { cloneStructure } from 'client/utils/cloneStructure'

interface CreateMasterBlockParams {
  blockEntities: BlockEntityInterface[]
  entityId: string
  files: File[]
  locale: LocaleEnum
  title: string
  entity: BlockEntityInterface
}

export const useCreateMasterBlock = () => {
  const addBlock = useActions(addMasterBlock)
  const addFile = useActions(addMasterBlockFile)
  const markAsMaster = useActions(markAsMasterBlock)

  const createMasterBlock = async ({
    blockEntities,
    entityId,
    files,
    locale,
    title,
    entity,
  }: CreateMasterBlockParams) => {
    const { structure } = cloneStructure(
      entity,
      blockEntities.filter(be => be.id !== entity.id),
    )

    console.log(`--structure--`, structure)

    const screenshotRes = await blockApi.screenshotCreate(
      blockEntities,
      entityId,
      files,
      locale,
    )

    const { data } = await blockApi.createMasterBlock(
      Object.values(structure),
      screenshotRes.data,
      title,
    )
    markAsMaster(entityId, data.block.id)
    addBlock(data.block)
    addFile(data.desktopPreview)
    return data
  }

  return {
    createMasterBlock,
  }
}
