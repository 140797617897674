import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import OfferPriceNew from '../offer/entities/OfferPrice'
import UpsellButton from './entities/UpsellButton'

export default function createEntityElementForUpsell(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
      return (
        <UpsellButton
          entity={entity as UpsellButtonInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.OfferPrice:
      return (
        <OfferPriceNew
          entity={entity as OfferPriceInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
