import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateCommand } from '../utils/migrateCommand'
import { migrateBlogPageBody } from './migrateBlogPageBody'

export default function (page: ServerPage) {
  return migrateCommand({
    page,
    structureType: structureTypes.BLOG_PAGE_BODY,
    migrateFunction: migrateBlogPageBody,
  })
}
