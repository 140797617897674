import { migrateCheckbox } from 'tools/commands/migrate/Checkbox/migrateCheckbox'
import { migrateCommand } from 'tools/commands/migrate/utils/migrateCommand'
import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'

export default function (page: ServerPage): [ServerPage, boolean] {
  return migrateCommand({
    page,
    structureType: structureTypes.EXPLICIT_CONSENT,
    migrateFunction: migrateCheckbox,
  })
}
