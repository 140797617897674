import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import ServerPage from '../../../../server/types/ServerPageInterface'

export function migrateContactUsRecaptcha(entity: RecaptchaInterface) {
  entity.type = EntityTypeEnum.ContactUsRecaptcha

  return entity
}

export default function (page: ServerPage): [ServerPage, boolean] {
  let isChanged = false
  try {
    const entities = Object.values(page.content.entities).reduce(
      (prev, entity) => {
        if (entity.type === EntityTypeEnum.Recaptcha) {
          isChanged = true
        }

        return {
          ...prev,
          [entity.id]:
            entity.type === EntityTypeEnum.Recaptcha
              ? migrateContactUsRecaptcha(entity as RecaptchaInterface)
              : entity,
        }
      },
      {},
    )

    return [
      {
        ...page,
        content: {
          ...page.content,
          entities,
        },
      },
      isChanged,
    ]
  } catch (e) {
    console.log(`--error--`, page, page.id)

    return [page, false]
  }
}
