import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { SalesRedirectionButtonInterface } from 'common/types/entities/button-interface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import SalesRedirectionButtonSettings from './settings/SalesRedirectionButtonSettings'

function createSettingsForSales(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.SalesRedirectionButton:
      return (
        <SalesRedirectionButtonSettings
          entity={entity as SalesRedirectionButtonInterface}
        />
      )
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForSales
