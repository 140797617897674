import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import { BlogPostContentPlaceholderInterface } from 'common/types/entities/BlogPostContentPlaceholderInterface'
import { BlogPostDateInterface } from 'common/types/entities/BlogPostDateInterface'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import { BlogPostTitleInterface } from 'common/types/entities/BlogPostTitleInterface'
import { BreadcrumbsInterface } from 'common/types/entities/BreadcrumbsInterface'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { LatestBlogPostsCarouselInterface } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { FieldInterface } from 'common/types/entities/field-interface'
import ButtonNew from 'client/components/entities/button-new'
import BlogLatestPosts from '../blog-page/entities/BlogLatestPosts'
import LatestBlogPostsCarousel from '../blog-page/entities/LatestBlogPostsCarousel'
import BlogPostContentPlaceholderNew from '../blog/entities/BlogPostContentPlaceholderNew'
import Text from '../blog/entities/Text'
import BlogBulletList from '../blog/entities/blog-bullet-list'
import Attachments, {
  AttachmentsInterface,
} from '../contact-us/entities/Attachments'
import ContactUs from '../contact-us/entities/ContactUs'
import ContactUsField from '../contact-us/entities/ContactUsField'
import Recaptcha from '../contact-us/entities/Recaptcha'
import TextArea from '../contact-us/entities/TextArea'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import BlogPostCategoriesNew from './entities/BlogPostCategoriesNew'
import BlogPostDateNew from './entities/BlogPostDateNew'
import BlogPostImage from './entities/BlogPostImage'
import BlogPostTitleNew from './entities/BlogPostTitleNew'
import Breadcrumbs from './entities/Breadcrumbs'

export default function createEntityElementForBlogPost(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.BlogPostContentPlaceholder:
      return (
        <BlogPostContentPlaceholderNew
          entity={entity as BlogPostContentPlaceholderInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogPostImage:
      return (
        <BlogPostImage
          entity={entity as BlogPostImageInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogPostDate:
      return (
        <BlogPostDateNew
          entity={entity as BlogPostDateInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Breadcrumbs:
      return (
        <Breadcrumbs
          entity={entity as BreadcrumbsInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogPostCategories:
      return (
        <BlogPostCategoriesNew
          entity={entity as BlogPostCategoriesInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Text:
      return <Text entity={entity as TextInterface} {...additionalProps} />
    case EntityTypeEnum.BulletList:
      return (
        <BlogBulletList
          entity={entity as BulletListInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogPostTitle:
      return (
        <BlogPostTitleNew
          entity={entity as BlogPostTitleInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.LatestBlogPosts:
      return (
        <BlogLatestPosts
          entity={entity as LatestBlogPostsInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.LatestBlogPostsCarousel:
      return (
        <LatestBlogPostsCarousel
          entity={entity as LatestBlogPostsCarouselInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.ContactUs:
      return (
        <ContactUs entity={entity as ContactUsInterface} {...additionalProps} />
      )
    case EntityTypeEnum.TextArea:
      return (
        <TextArea entity={entity as TextAreaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.ContactUsField:
    case EntityTypeEnum.Field:
      return (
        <ContactUsField
          entity={entity as FieldInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return (
        <Recaptcha entity={entity as RecaptchaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.Button:
      return (
        <ButtonNew entity={entity as ButtonInterface} {...additionalProps} />
      )
    case EntityTypeEnum.Attachments:
      return (
        <Attachments
          entity={entity as AttachmentsInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
