import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BodyInterface } from 'common/types/entities/body-interface'
import { usePage } from 'client/store'
import { getFirstEntityByType } from 'client/store/page/pageSelectors'
import { LanguageSelector } from '../options'
import CommonPageSettingsOld from './CommonPageSettings'
import Seo from './components/Seo'
import Tracking from './components/Tracking'
import Typography from './components/Typography'
import { Affiliate } from './components/affiliate'
import { Background } from './components/background'

export const CommonPageSettingsResolver = () => {
  const isMigratedBodyEntity = usePage(page =>
    getFirstEntityByType(page, EntityTypeEnum.Body),
  )

  if (isMigratedBodyEntity) {
    return <CommonPageSettings />
  }
  return <CommonPageSettingsOld />
}

export const CommonPageSettings = () => {
  // @description: for Body
  return (
    <>
      <Typography />
      <LanguageSelector />
      <Background<BodyInterface> />
      <Seo />
      <Tracking />
      <Affiliate<BodyInterface> />
    </>
  )
}
