import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { actions } from 'common/components/entities/Survey'
import { SurveyActions } from 'common/components/entities/SurveyNew'
import { useActions } from 'common/hooks/useActions'
import { Appearance } from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import {
  SurveyInterface,
  SurveyQuestionType,
} from 'common/types/entities/SurveyInterface'
import * as managementActions from 'client/actions/actionsManagement'
import {
  Dialog,
  DialogBody,
  DialogCancel,
  DialogFooter,
  DialogHeader,
  DialogSubmit,
} from 'client/components/core/Dialog'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement, { selectors } from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { usePage } from 'client/store'
import typedPageSelectors from 'client/store/page/pageSelectors'
import { PrimaryButton, Select, Switcher } from '../../components'
import TextFontUpdater from '../../components/Font/TextFontUpdater'
import Input from '../../components/Input/Input'
import ReactSelect, {
  ChangeOptionWithStringValue,
} from '../../components/ReactSelect/ReactSelect'
import { useUpdateEntity, useUpdateProp } from '../../hooks/useUpdateProps'
import { FontSize } from '../../options'
import DeviceAppearance from '../../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../options/HtmlAttrId'
import GroupTitle from '../../styleComponents/GroupTitle'
import Border from '../../styles/Border'
import MarginStyle from '../../styles/MarginStyle'
import ShadowStyle from '../../styles/ShadowStyle'
import Question from './Question'
import QuestionList from './QuestionList'
import QuestionChangeOrderUi from './ui/QuestionChangeOrderUi'
import QuestionEditHeaderUi from './ui/QuestionEditHeaderUi'
import QuestionListItemDeleteUi from './ui/QuestionListItemDeleteUi'
import QuestionListOrderUi from './ui/QuestionListOrderUi'
import QuestionListUi from './ui/QuestionListUi'

function detectAction(entity: SurveyInterface) {
  if (entity.popupId) {
    return SurveyActions.OpenPopup
  }

  if (entity.redirectUrl) {
    return SurveyActions.Redirect
  }

  return SurveyActions.Nothing
}

export const actionsNames = {
  [SurveyActions.Redirect]: 'components.survey.actions.redirect',
  [SurveyActions.OpenPopup]: 'components.survey.actions.open_popup',
  [SurveyActions.Nothing]: 'components.survey.actions.nothing',
}

function getPopupOption(popup: any) {
  return {
    value: popup.id,
    label: popup.options.title,
  }
}

function SurveySettings({ entity }: { entity: SurveyInterface }) {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<SurveyInterface>()
  const { isMobile } = useDeviceModeContext()
  const [action, setAction] = useState(detectAction(entity))

  const { t, i18n } = useTranslation()
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const popups = usePage(typedPageSelectors.getPopupEntities)

  const [dialogOpened, setDialogOpened] = useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<
    number | null
  >(null)
  const showSurveyResult = useActions(managementActions.showSurveyResult)
  const isSurveyResult = useManagement(selectors.isSurveyResult)
  const fixedT = i18n.getFixedT(pageLocale)

  const updateQuestion = (index: number) => (question: SurveyQuestionType) => {
    updateProp('questions')([
      ...entity.questions.slice(0, index),
      question,
      ...entity.questions.slice(index + 1),
    ])
  }

  const addQuestion = () => {
    updateProp('questions')([
      ...entity.questions,
      {
        id: uuid(),
        title: fixedT('entity_settings.survey.default_question_title', {
          num: entity.questions.length + 1,
        }),
        subTitle: ``,
        answers: [createAnswer()],
      },
    ])
  }

  const createAnswer = () => ({
    id: uuid(),
    title: fixedT('entity_settings.survey.default_answer_title'),
  })

  const addAnswer = () => {
    if (selectedQuestionIndex === null) {
      return
    }

    const updatedQuestion = {
      ...entity.questions[selectedQuestionIndex],
      answers: [
        ...entity.questions[selectedQuestionIndex].answers,
        createAnswer(),
      ],
    }
    updateQuestion(selectedQuestionIndex)(updatedQuestion)
  }

  const moveDown = (index: number) => () => {
    updateProp('questions')([
      ...entity.questions.slice(0, index),
      entity.questions[index + 1],
      entity.questions[index],
      ...entity.questions.slice(index + 2),
    ])
  }

  const moveUp = (index: number) => () => {
    updateProp('questions')([
      ...entity.questions.slice(0, index - 1),
      entity.questions[index],
      entity.questions[index - 1],
      ...entity.questions.slice(index + 1),
    ])
  }

  const removeQuestion = (index: number) => () => {
    confirm(t('entity_settings.survey.remove_question_warning')) &&
      updateProp('questions')([
        ...entity.questions.slice(0, index),
        ...entity.questions.slice(index + 1),
      ])
  }

  const hideDialog = () => setDialogOpened(false)
  const backToList = () => setSelectedQuestionIndex(null)
  const selectQuestion = (index: number) => () =>
    setSelectedQuestionIndex(index)

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  function updateAction(action: SurveyActions) {
    const updatedEntity = { ...entity }
    switch (action) {
      case actions.openPopup:
        delete updatedEntity.redirectUrl
        break
      case actions.redirect:
        delete updatedEntity.popupId
        break
      case actions.nothing:
      default:
        delete updatedEntity.redirectUrl
        delete updatedEntity.popupId
    }
    setAction(action)
    updateEntity(updatedEntity)
  }

  function getSelectedPopupOption(popupId: string) {
    const selectedPopup = popups.find(
      (popup: OldEntityInterface) => popup.id === popupId,
    )

    return selectedPopup ? getPopupOption(selectedPopup) : null
  }

  const popupOptions = popups.map(getPopupOption)

  function updatePopup(option: ChangeOptionWithStringValue) {
    if (!option) {
      return
    }
    return updateProp('popupId')(option.value)
  }

  return (
    <>
      <Switcher
        firstLabel="entity_settings.survey.mode.show_survey"
        isFirstActive={!isSurveyResult}
        handleFirstButton={() => showSurveyResult(false)}
        secondLabel="entity_settings.survey.mode.show_result"
        isSecondActive={isSurveyResult}
        handleSecondButton={() => showSurveyResult(true)}
      />
      <PrimaryButton onClick={() => setDialogOpened(true)}>
        entity_settings.survey.edit_label
      </PrimaryButton>
      <Select
        update={updateAction}
        choiceList={actionsNames}
        labelText="entity_settings.survey.choose_action"
        value={action}
      />
      {action === SurveyActions.Redirect && (
        <Input
          label="entity_settings.survey.redirect_url.label"
          update={updateProp('redirectUrl')}
          value={entity.redirectUrl}
        />
      )}
      {action === SurveyActions.OpenPopup && (
        <ReactSelect<string>
          update={updatePopup}
          options={popupOptions}
          labelText="entity_settings.survey.choose_popup"
          selectedOption={
            entity.popupId ? getSelectedPopupOption(entity.popupId) : null
          }
          isMulti={false}
        />
      )}
      {/*@ts-ignore*/}
      <Dialog width={900} show={dialogOpened}>
        <DialogHeader close={hideDialog}>
          <QuestionEditHeaderUi>
            {t('entity_settings.survey.edit_header')}
          </QuestionEditHeaderUi>
        </DialogHeader>
        <DialogBody>
          {selectedQuestionIndex !== null ? (
            <Question
              key={entity.questions[selectedQuestionIndex].id}
              question={entity.questions[selectedQuestionIndex]}
              update={updateQuestion(selectedQuestionIndex)}
            />
          ) : (
            <QuestionListUi>
              {entity.questions.map((question, index, arr) => (
                <QuestionList
                  number={index + 1}
                  key={question.id}
                  title={question.title}
                  select={selectQuestion(index)}
                >
                  <React.Fragment>
                    {arr.length > 1 && (
                      <QuestionListItemDeleteUi
                        className="far fa-trash-alt"
                        onClick={removeQuestion(index)}
                      />
                    )}
                    <QuestionListOrderUi>
                      {index !== 0 && (
                        <QuestionChangeOrderUi
                          className="fas fa-arrow-up"
                          key="up"
                          onClick={moveUp(index)}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      {index + 1 !== arr.length && (
                        <QuestionChangeOrderUi
                          className="fas fa-arrow-down"
                          key="down"
                          onClick={moveDown(index)}
                        />
                      )}
                    </QuestionListOrderUi>
                  </React.Fragment>
                </QuestionList>
              ))}
            </QuestionListUi>
          )}
        </DialogBody>
        <DialogFooter>
          {selectedQuestionIndex === null ? (
            <DialogSubmit onClick={addQuestion}>
              {t('entity_settings.survey.add_question')}
            </DialogSubmit>
          ) : (
            <React.Fragment>
              <DialogCancel onClick={backToList}>
                {t('entity_settings.survey.back_to_list')}
              </DialogCancel>
              <DialogSubmit onClick={addAnswer}>
                {t('entity_settings.survey.add_answer')}
              </DialogSubmit>
            </React.Fragment>
          )}
        </DialogFooter>
      </Dialog>
      {!isSurveyResult && (
        <React.Fragment>
          {/*<GroupTitle>typography</GroupTitle>*/}
          <TextFontUpdater<SurveyInterface>
            entity={entity}
            update={updateEntity}
          />
          <ColorPicker
            update={updateProp('backgroundColor')}
            mobileUpdate={updateProp('mobileBackgroundColor')}
            color={entity.backgroundColor}
            mobileColor={entity.mobileBackgroundColor}
          />
          <GroupTitle>entity_settings.survey.question_group_title</GroupTitle>
          <FontSize
            labelText="entity_settings.survey.question_title_font_size"
            update={updateProp('questionTitleFontSize')}
            mobileUpdate={updateProp('mobileQuestionTitleFontSize')}
            fontSize={entity.questionTitleFontSize}
            mobileFontSize={entity.mobileQuestionTitleFontSize}
          />
          <FontSize
            labelText="entity_settings.survey.question_sub_title_font_size"
            update={updateProp('questionSubTitleFontSize')}
            mobileUpdate={updateProp('mobileQuestionSubTitleFontSize')}
            fontSize={entity.questionSubTitleFontSize}
            mobileFontSize={
              entity.mobileQuestionSubTitleFontSize ||
              entity.questionSubTitleFontSize
            }
          />
          <ColorPicker
            update={updateProp('questionTitleColor')}
            color={entity.questionTitleColor}
            label="entity_settings.survey.question_title_color"
            singleColorMode
          />
          <ColorPicker
            update={updateProp('questionSubTitleColor')}
            color={entity.questionSubTitleColor}
            label="entity_settings.survey.question_sub_title_color"
            singleColorMode
          />
          <GroupTitle>entity_settings.survey.answer_group_title</GroupTitle>
          <FontSize
            labelText="entity_settings.survey.answer_font_size"
            update={updateProp('answerFontSize')}
            mobileUpdate={updateProp('mobileAnswerFontSize')}
            fontSize={entity.answerFontSize}
            mobileFontSize={
              entity.mobileAnswerFontSize || entity.answerFontSize
            }
          />
          <ColorPicker
            update={updateProp('answerColor')}
            color={entity.answerColor}
            label="entity_settings.survey.answer_color"
            singleColorMode
          />
          <ColorPicker
            update={updateProp('answerBackgroundColor')}
            color={entity.answerBackgroundColor}
            label="entity_settings.survey.answer_background_color"
            singleColorMode
          />
          <ColorPicker
            update={updateProp('answerOutlineColor')}
            color={entity.answerOutlineColor}
            label="entity_settings.survey.answer_outline_color"
            singleColorMode
          />
          <Border
            update={updateProp('answerBorder')}
            mobileUpdate={updateProp('mobileAnswerBorder')}
            border={entity.answerBorder}
            mobileBorder={entity.mobileAnswerBorder}
          />
        </React.Fragment>
      )}
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default SurveySettings
