import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonLoader from 'common/components/core/ButtonLoader'
import FormGroup from 'common/components/core/FormGroup'
import Input from 'common/components/core/Input'
import InputLabel from 'common/components/core/InputLabel'
import { BadRequest } from 'common/errors/BadRequest'
import { useActions } from 'common/hooks/useActions'
import { EntitiesStructure } from 'common/types/Page'
import { BlockEntityInterface } from 'common/types/entities/EntityInterface'
import { hideCreateBlockDialog } from 'client/actions/actionsManagement'
import BlueButton from 'client/components/core/BlueButton'
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from 'client/components/core/Dialog'
import useFiles from 'client/hooks/useFiles'
import useManagement from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { createPage } from 'client/pages'
import { getFilesByIds } from 'client/reducers/filesReducer'
import {
  getCreateBlockDialogEntityId,
  isUserAdmin,
} from 'client/reducers/managementReducer'
import { findFileId } from 'client/reducers/pageReducer'
import { FilesState } from 'client/store/files/filesReducer'
import { toBase64 } from 'client/utils/filesHelper'
import CheckboxWithLabel from './components/checkbox-with-label'
import CommonBlockSettings from './components/common-block-settings'
import { useCreateCommonBlock } from './hooks/use-create-common-block'
import { useCreateMasterBlock } from './hooks/use-create-master-block'
import { useCreateUserBlock } from './hooks/use-create-user-block'
import CreateBlogDialogErrorMessageUi from './ui/CreateBlogDialogErrorMessageUi'
import CreateBlogDialogHeaderUi from './ui/CreateBlogDialogHeaderUi'
import CreateBlogDialogPreviewUi from './ui/CreateBlogDialogPreviewUi'
import CreateBlogDialogSuccessMessageUi from './ui/CreateBlogDialogSuccessMessageUi'

function cleanUpPossibleMasterBlockProperties(entity: BlockEntityInterface) {
  const entityToCleanUp = { ...entity }
  if (entityToCleanUp.isMasterBlockRoot) {
    delete entityToCleanUp.isMasterBlockRoot
  }

  if (entityToCleanUp.masterBlockId) {
    delete entityToCleanUp.masterBlockId
  }

  return entityToCleanUp
}

export interface Errors {
  locale?: string[]
  category?: string[]
  desktopPreview?: string[]
  mobilePreview?: string[]
  title?: string[]
}

function CreateBlockDialog() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isCommonError, setIsCommonError] = useState(false)
  const [isCommon, setIsCommon] = useState(false)
  const [isMaster, setIsMaster] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [desktopBlobFile, setDesktopBlobFile] = useState('')
  const [mobileBlobFile, setMobileBlobFile] = useState('')
  const desktopFileRef = useRef<HTMLInputElement | null>(null)
  const mobileFileRef = useRef<HTMLInputElement | null>(null)
  const [success, setSuccess] = useState(false)
  const hide = useActions(hideCreateBlockDialog)
  const pageLocale = usePresentPage(pageSelectors.getPageLocale)
  const pageType = usePresentPage(pageSelectors.getPageType)
  const pageId = usePresentPage(pageSelectors.getPageId)
  const [locale, setLocale] = useState(pageLocale)
  const entityId = useManagement(getCreateBlockDialogEntityId)
  const isAdmin = useManagement(isUserAdmin)
  const { createCommonBlock } = useCreateCommonBlock()
  const { createMasterBlock } = useCreateMasterBlock()
  const { createUserBlock } = useCreateUserBlock()
  const entity = usePresentPage((page: { entities: EntitiesStructure }) =>
    pageSelectors.getEntityById(page, entityId),
  )
  const descendants = usePresentPage((page: { entities: EntitiesStructure }) =>
    pageSelectors.getAllDescendants(page, entityId),
  )
  const blockEntities = [...descendants, entity]
    .filter(Boolean)
    .map(cleanUpPossibleMasterBlockProperties)
  const filesIds = blockEntities.map(findFileId).filter(Boolean)
  const files = useFiles((files: FilesState) => getFilesByIds(files, filesIds))
  const [, createComponent] = createPage(pageType)

  useEffect(() => {
    if (!entity) {
      window.Rollbar?.info(
        `Page id ${pageId} entity not found by id ${entity.id}`,
      )
    }
  }, [entity])

  const create = async () => {
    try {
      setIsLoading(true)
      setIsCommonError(false)
      if (isAdmin && isCommon) {
        await createCommonBlock({
          locale,
          blockEntities,
          desktopBlobFile,
          mobileBlobFile,
          title,
          category,
        })
      } else if (isMaster) {
        await createMasterBlock({
          entity,
          blockEntities,
          entityId,
          files,
          locale,
          title,
        })
      } else {
        await createUserBlock({
          blockEntities,
          entityId,
          files,
          locale,
          title,
        })
      }
      setSuccess(true)
      setTimeout(() => hide(), 1000)
    } catch (e) {
      if (e instanceof BadRequest) {
        setErrors(e.response.data.errors as unknown as Errors)
      } else {
        setIsCommonError(true)
        // no need to send error, backend will send it
      }
    }
    setIsLoading(false)
  }

  async function handleDesktopInputFileChange() {
    const file = desktopFileRef?.current?.files?.[0]
    if (!file) {
      return
    }
    const base64 = await toBase64(file, true)
    setDesktopBlobFile(base64)
  }

  async function handleMobileInputFileChange() {
    const file = mobileFileRef.current?.files?.[0]
    if (!file) {
      return
    }
    const base64 = await toBase64(file, true)
    setMobileBlobFile(base64)
  }

  return (
    <Dialog show close={hide} width={900} small={false}>
      <DialogHeader close={hide}>
        <CreateBlogDialogHeaderUi>
          {t('components.core.dialog.save_block.header')}
        </CreateBlogDialogHeaderUi>
      </DialogHeader>
      <DialogBody>
        <FormGroup>
          <InputLabel>
            {t('components.core.dialog.save_block.title')}
          </InputLabel>
          <Input value={title} onChange={e => setTitle(e.target.value)} />
          {errors?.title &&
            errors.title.map(error => (
              <CreateBlogDialogErrorMessageUi key={error}>
                {error}
              </CreateBlogDialogErrorMessageUi>
            ))}
        </FormGroup>
        {!isCommon && (
          <CheckboxWithLabel
            isChecked={isMaster}
            setIsChecked={setIsMaster}
            labelText={t('components.core.dialog.save_block.is_master.label')}
            tooltipText={t(
              'components.core.dialog.save_block.is_master.help_message',
            )}
          />
        )}
        {isAdmin && (
          <React.Fragment>
            {!isMaster && (
              <CheckboxWithLabel
                isChecked={isCommon}
                setIsChecked={setIsCommon}
                labelText={t('components.core.dialog.save_block.is_common')}
              />
            )}
            {isCommon && (
              <CommonBlockSettings
                locale={locale}
                setLocale={setLocale}
                category={category}
                setCategory={setCategory}
                errors={errors}
                desktopFileRef={desktopFileRef}
                handleDesktopInputFileChange={handleDesktopInputFileChange}
                mobileFileRef={mobileFileRef}
                handleMobileInputFileChange={handleMobileInputFileChange}
              />
            )}
          </React.Fragment>
        )}
        <FormGroup>
          <InputLabel>
            {t('components.core.dialog.save_block.preview')}
          </InputLabel>
        </FormGroup>
        <CreateBlogDialogPreviewUi id="create-block-preview">
          {createComponent(entity, {
            isMoveDownAllowed: false,
            isMoveUpAllowed: false,
          })}
        </CreateBlogDialogPreviewUi>
      </DialogBody>
      <DialogFooter>
        {success && (
          <CreateBlogDialogSuccessMessageUi>
            {t('components.core.dialog.save_block.success_message')}
          </CreateBlogDialogSuccessMessageUi>
        )}
        {isCommonError && (
          <CreateBlogDialogErrorMessageUi>
            {t('components.core.dialog.save_block.error')}
          </CreateBlogDialogErrorMessageUi>
        )}
        <BlueButton onClick={create} disabled={isLoading}>
          {isLoading && <ButtonLoader />}
          {t('components.core.dialog.save_block.submit')}
        </BlueButton>
      </DialogFooter>
    </Dialog>
  )
}

export default CreateBlockDialog
