import PropTypes from 'prop-types'
import React from 'react'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import useBlogData from '../../hooks/useBlogData'
import ActivePopup from '../components/ActivePopup'
import PageUi from '../ui/PageUi'

function BlogPage({ children }: { children: React.ReactNode }) {
  useBlogData()
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)

  return (
    <PageUi
      fontFamily={globalFontProperties.fontFamily}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

export default BlogPage
