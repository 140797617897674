import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateCommand } from '../utils/migrateCommand'
import { migrateRemotePopup } from './migrate-remote-popup'

export default function (page: ServerPage) {
  return migrateCommand({
    page,
    structureType: structureTypes.REMOTE_POPUP,
    migrateFunction: migrateRemotePopup,
  })
}
