import structureTypes from 'common/constants/structureTypes'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import {
  createUpsellAgreeButton,
  createUpsellDisagreeButton,
} from 'client/pages/upsell/entities/UpsellButton'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateBackgroundColor,
  migrateAlignSelf,
  migrateBoxShadow,
  migrateLineHeight,
} from '../utils/migrateUtils'
import {
  migrateButtonFontSize,
  migrateButtonIcons,
  migrateButtonText,
  migrateButtonTextColor,
  migrateButtonWidth,
} from './migrateButtonUtils'

export function migrateUpsellButton(
  entity: OldEntityInterface,
): UpsellButtonInterface {
  const upsellButton =
    entity.type === structureTypes.PAYMENT_BUTTON_AGREE
      ? createUpsellAgreeButton(entity.parentId, entity.masterBlockId)
      : createUpsellDisagreeButton(entity.parentId, entity.masterBlockId)

  //id and masterBlock
  upsellButton.id = entity.id
  if (entity.isMasterBlockRoot) {
    upsellButton.isMasterBlockRoot = true
  }
  //appearance
  if (entity.options.appearance) {
    upsellButton.appearance.desktop = entity.options.appearance.desktop
    upsellButton.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  upsellButton.htmlAttrId = entity.options.attrId
  //text
  upsellButton.text = entity.options.text
  //subText
  upsellButton.subText = entity.options.subText

  //margins
  migrateMargin(upsellButton, entity)
  migrateMobileMargin(upsellButton, entity)
  //paddings
  if (entity.styles.padding) {
    upsellButton.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    upsellButton.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  //fontSettings text
  migrateButtonText(upsellButton, entity)

  migrateBackgroundColor(upsellButton, entity)

  migrateButtonTextColor(upsellButton, entity)

  migrateButtonWidth(upsellButton, entity)

  migrateButtonFontSize(upsellButton, entity)

  migrateLineHeight(upsellButton, entity)

  migrateAlignSelf(upsellButton, entity)

  // migrateButtonBorderFullBorderType(upsellButton, entity)

  migrateBoxShadow(upsellButton, entity)

  migrateButtonIcons(upsellButton, entity)

  return upsellButton
}
