import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { ContactUsButtonInterface } from 'common/types/entities/contact-us-button-interface'
import { FieldInterface } from 'common/types/entities/field-interface'
import FieldNew from 'client/components/entities/field-new/field-new'
import ContactUsField from 'client/pages/contact-us/entities/ContactUsField'
import Recaptcha from 'client/pages/contact-us/entities/Recaptcha'
import ContactUsButton from 'client/pages/contact-us/entities/contact-us-button'
import ButtonNew from '../../components/entities/button-new'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import Attachments, { AttachmentsInterface } from './entities/Attachments'
import ContactUs from './entities/ContactUs'
import TextArea from './entities/TextArea'

export default function createEntityElementForContactUs(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.ContactUs:
      return (
        <ContactUs entity={entity as ContactUsInterface} {...additionalProps} />
      )
    case EntityTypeEnum.TextArea:
      return (
        <TextArea entity={entity as TextAreaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.Field:
      return <FieldNew entity={entity as FieldInterface} {...additionalProps} />
    case EntityTypeEnum.ContactUsField:
      return (
        <ContactUsField
          entity={entity as FieldInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return (
        <Recaptcha entity={entity as RecaptchaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.Button:
      return (
        <ButtonNew entity={entity as ButtonInterface} {...additionalProps} />
      )
    case EntityTypeEnum.ContactUsButton:
      return (
        <ContactUsButton
          entity={entity as ContactUsButtonInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Attachments:
      return (
        <Attachments
          entity={entity as AttachmentsInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
