import React, { useMemo } from 'react'
import {
  BookingCalendarSteps,
  timeFormatLabels,
} from 'common/components/entities/Calendar/constants'
import { blogPageTypes } from 'common/constants/pageTypes'
import {
  RedirectionTypesEnum,
  sendFormRedirectionChoices,
} from 'common/enums/ButtonActionEnum'
import { useActions } from 'common/hooks/useActions'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { addUrlSchemaToTheLink } from 'common/utils/urlUtils'
import { changeBookingCalendarStepType } from 'client/actions/actionsManagement'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { Switcher } from '../../components'
import Input from '../../components/Input/Input'
import { useUpdateProp } from '../../hooks/useUpdateProps'
import { UrlRedirect } from '../../options'

interface BookingStepsSwitcherProps {
  entity: BookingCalendarInterface
}

export const BookingStepsSwitcher = ({ entity }: BookingStepsSwitcherProps) => {
  const updateProp = useUpdateProp(entity)
  const pageType = usePage(pageSelectors.getPageType)

  const stepType = useManagement(managementSelectors.getBookingCalendarStepType)
  const changeStep = useActions(changeBookingCalendarStepType)
  const isCalendarStep = stepType === BookingCalendarSteps.CalendarStep
  const isFormStep = stepType === BookingCalendarSteps.FormStep
  const isThankYouStep = stepType === BookingCalendarSteps.ThankYouStep
  const redirectionTypeNames = { ...sendFormRedirectionChoices }
  if (blogPageTypes.includes(pageType)) {
    delete redirectionTypeNames[RedirectionTypesEnum.nextStep]
  }

  const isRedirectionTypeCustomUrl = useMemo(() => {
    return entity.thankYouStepButtonType === RedirectionTypesEnum.customUrl
  }, [entity.thankYouStepButtonType])

  return (
    <React.Fragment>
      <Switcher
        handleFirstButton={() => changeStep(BookingCalendarSteps.CalendarStep)}
        isFirstActive={isCalendarStep}
        firstLabel="entity_settings.booking_calendar.switch_calendar_step"
        handleSecondButton={() => changeStep(BookingCalendarSteps.FormStep)}
        isSecondActive={isFormStep}
        secondLabel="entity_settings.booking_calendar.switch_form_step"
        handleThirdButton={() => changeStep(BookingCalendarSteps.ThankYouStep)}
        isThirdActive={isThankYouStep}
        thirdLabel="entity_settings.booking_calendar.switch_thank_you_step"
      />
      {isCalendarStep && (
        <React.Fragment>
          <Input
            value={entity.calendarStepHeadline}
            update={updateProp('calendarStepHeadline')}
            label="entity_settings.booking_calendar.headline"
          />
          <Input
            value={entity.calendarStepSubHeadline}
            update={updateProp('calendarStepSubHeadline')}
            label="entity_settings.booking_calendar.sub_headline"
          />
          <Input
            value={entity.nextStepButtonText}
            update={updateProp('nextStepButtonText')}
            label="entity_settings.booking_calendar.next_step_button_text"
          />
        </React.Fragment>
      )}
      {isFormStep && (
        <React.Fragment>
          <Input
            value={entity.formStepHeadline}
            update={updateProp('formStepHeadline')}
            label="entity_settings.booking_calendar.headline"
          />
          <Input
            value={entity.formStepSubHeadline}
            update={updateProp('formStepSubHeadline')}
            label="entity_settings.booking_calendar.sub_headline"
          />
        </React.Fragment>
      )}
      {isThankYouStep && (
        <React.Fragment>
          <Input
            value={entity.thankYouStepSubHeadline}
            update={updateProp('thankYouStepSubHeadline')}
            label="entity_settings.booking_calendar.sub_headline"
          />
          <Select
            labelText="entity_settings.booking_calendar.redirection_confirmed.label"
            value={entity.thankYouStepButtonType}
            update={updateProp('thankYouStepButtonType')}
            dataTestElement="button-redirection-checkbox"
            choiceList={redirectionTypeNames}
          />
          {isRedirectionTypeCustomUrl && (
            <UrlRedirect
              key="thankYouStepButtonCustomUrl"
              urlRedirect={addUrlSchemaToTheLink(
                entity.thankYouStepButtonCustomUrl,
              )}
              update={updateProp('thankYouStepButtonCustomUrl')}
              data-test-element="button-redirection-input"
            />
          )}
        </React.Fragment>
      )}
      <Select
        labelText="entity_settings.booking_calendar.time_format.label"
        value={entity.timeFormat}
        update={updateProp('timeFormat')}
        choiceList={timeFormatLabels}
      />
    </React.Fragment>
  )
}
