import React from 'react'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Input from '../components/Input'
import Range from '../components/Range/Range'
import { useUpdateProp } from '../hooks/useUpdateProps'
import HtmlAttrId from '../options/HtmlAttrId'
import Border from '../styles/Border'
import FontSize from '../styles/FontSize'
import ShadowStyle from '../styles/ShadowStyle'

function TextAreaSettings({ entity }: { entity: TextAreaInterface }) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  return (
    <>
      <Input
        labelText="entity_settings.textarea.placeholder.label"
        value={entity.placeholder}
        update={updateProp('placeholder')}
        placeholder=""
        type="text"
        helpMessage=""
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      <ColorPicker
        label="settings_styles.background_color.label"
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
      />
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <Range
        label="entity_settings.textarea.height"
        change={updateProp('height')}
        value={entity.height}
        max={400}
      />
      <Border
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default TextAreaSettings
