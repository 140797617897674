import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'

const alignLeftStyle = isFlex => (isFlex ? 'flex-start' : 'left')
const alignCenterStyle = isFlex => (isFlex ? 'center' : 'center')
const alignRightStyle = isFlex => (isFlex ? 'flex-end' : 'right')

const AlignButton = styled.button`
  width: 33.3%;
  float: left;
  margin-bottom: 0;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  color: ${({ active }) => (active ? '#707e8d' : '#777')};
  background-color: ${({ active }) => (active ? '#ebeef1' : '#f9f9f9')};
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    background-color: #ebeef1;
    color: #707e8d;
  }
`
const AlignStyle = ({ align = 'center', update, isFlex = true }) => (
  <OptionWrapper labelText="Align">
    <AlignButton
      onClick={() => update(alignLeftStyle(isFlex))}
      active={align === alignLeftStyle(isFlex)}
    >
      <span className="fas fa-align-left" />
    </AlignButton>
    <AlignButton
      onClick={() => update(alignCenterStyle(isFlex))}
      active={align === alignCenterStyle(isFlex)}
    >
      <span className="fas fa-align-center" />
    </AlignButton>
    <AlignButton
      onClick={() => update(alignRightStyle(isFlex))}
      active={align === alignRightStyle(isFlex)}
    >
      <span className="fas fa-align-right" />
    </AlignButton>
  </OptionWrapper>
)

AlignStyle.propTypes = {
  update: PropTypes.func.isRequired,
  align: PropTypes.string,
  isFlex: PropTypes.bool,
}

export default AlignStyle
