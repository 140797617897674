import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { RemotePopupBodyInterface } from 'common/types/remote-popup-body-interface'
import {
  migratePadding,
  migrateBackground,
  migrateFullBorderType,
  migrateBoxShadow,
} from '../utils/migrateUtils'

export function migrateRemotePopup(entity: OldEntityInterface) {
  const remotePopupBody: RemotePopupBodyInterface =
    {} as RemotePopupBodyInterface

  remotePopupBody.id = entity.id
  remotePopupBody.type = EntityTypeEnum.RemotePopupBody
  remotePopupBody.childIds = entity.childIds || []
  remotePopupBody.htmlAttrId = entity.options.attrId

  if (entity.styles.color) {
    remotePopupBody.closeButtonColor = entity.styles.color
  }
  if (entity.mobileStyles.color) {
    remotePopupBody.mobileCloseButtonColor = entity.mobileStyles.color
  }

  remotePopupBody.showClose = Boolean(parseInt(entity.options.showClose))
  if (entity.options.closedDays) {
    remotePopupBody.closedDays = entity.options.closedDays
  }
  if (entity.options.openOnExit) {
    remotePopupBody.openOnExit = Boolean(parseInt(entity.options.openOnExit))
  }
  if (entity.options.openAutomatically) {
    remotePopupBody.openAutomatically = Boolean(
      parseInt(entity.options.openAutomatically),
    )
  }
  if (entity.options.openOnMobileAutomatically) {
    remotePopupBody.openOnMobileAutomatically = Boolean(
      parseInt(entity.options.openOnMobileAutomatically),
    )
  }
  if (entity.options.delay) {
    remotePopupBody.delay = entity.options.delay
  }
  if (entity.options.mobileDelay) {
    remotePopupBody.mobileDelay = entity.options.mobileDelay
  }

  if (entity.options.blur) {
    remotePopupBody.blur = entity.options.blur
  }
  if (entity.styles.padding) {
    remotePopupBody.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    remotePopupBody.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  if (entity.styles.backgroundColor) {
    remotePopupBody.backgroundColor = entity.styles.backgroundColor
  }
  if (entity.mobileStyles.backgroundColor) {
    remotePopupBody.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }

  remotePopupBody.background = {}
  migrateBackground(remotePopupBody, entity)
  remotePopupBody.mobileBackground = {}
  if (entity.mobileStyles.backgroundSize) {
    remotePopupBody.mobileBackground.backgroundSize =
      entity.mobileStyles.backgroundSize
  }
  if (entity.styles.backgroundPosition) {
    remotePopupBody.mobileBackground.backgroundPosition =
      entity.mobileStyles.backgroundPosition
  }
  if (entity.styles.backgroundRepeat) {
    remotePopupBody.mobileBackground.backgroundRepeat =
      entity.mobileStyles.backgroundRepeat
  }
  if (entity.styles.backgroundAttachment) {
    remotePopupBody.mobileBackground.backgroundAttachment =
      entity.mobileStyles.backgroundAttachment
  }

  migrateFullBorderType(remotePopupBody, entity)
  migrateBoxShadow(remotePopupBody, entity)

  return remotePopupBody
}
