import React from 'react'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import CustomLoginInterface from 'common/types/entities/CustomLoginInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { FieldInterface } from 'common/types/entities/field-interface'
import FieldNew from 'client/components/entities/field-new/field-new'
import Button from 'client/pages/custom-login/entities/Button'
import Field from 'client/pages/custom-login/entities/Field'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import CustomLogin from './entities/CustomLogin'
import { ForgotPassword } from './entities/ForgotPassword'

export default function createEntityElementForCustomLogin(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.CustomLogin:
      return (
        <CustomLogin
          entity={entity as CustomLoginInterface}
          {...additionalProps}
        />
      )
    case structureTypes.FORM_INPUT:
      return (
        <Field
          // @ts-ignore
          entity={entity}
          {...additionalProps}
          isRemoveAllowed={false}
          isSaveAllowed={false}
          isCopyAllowed={false}
        />
      )
    case EntityTypeEnum.Field:
      return (
        <FieldNew
          entity={entity as FieldInterface}
          {...additionalProps}
          isRemoveAllowed={false}
          isSaveAllowed={false}
          isCopyAllowed={false}
        />
      )
    case structureTypes.BUTTON:
      return (
        <Button
          // @ts-ignore
          entity={entity}
          {...additionalProps}
          isRemoveAllowed={false}
          isSaveAllowed={false}
          isCopyAllowed={false}
        />
      )
    case EntityTypeEnum.CustomLoginForgotPassword:
      return (
        <ForgotPassword
          // @ts-ignore
          entity={entity}
          {...additionalProps}
          isRemoveAllowed={false}
          isSaveAllowed={false}
          isCopyAllowed={false}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
