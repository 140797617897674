import PropTypes from 'prop-types'
import React, { memo } from 'react'
import {
  borderSizes,
  borderStyleValues,
  borderTypeKeys,
  borderTypeNames,
} from 'common/constants/settings'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Select } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

function BorderStyle({
  type = borderTypeKeys.none,
  style = borderStyleValues.none,
  width = '1px',
  color = 'rgba(0, 0, 0, 0)',
  isBorderTypeVisible = true,
  mobileStyle,
  mobileWidth,
  mobileType,
  mobileColor,
  updateStyle,
  updateMobileStyle,
  updateOption,
  updateMobileOption,
}) {
  const { isMobile } = useDeviceModeContext()

  const calculatedStyle = isMobile ? mobileStyle || style : style
  const calclulatedWidth = isMobile ? mobileWidth || width : width
  const calclulatedType = isMobile ? mobileType || type : type
  const calclulatedColor = isMobile ? mobileColor || color : color

  const updateStyleProp = style => value =>
    isMobile ? updateMobileStyle(style)(value) : updateStyle(style)(value)

  const updateOptionProp = option => value =>
    isMobile ? updateMobileOption(option)(value) : updateOption(option)(value)

  return (
    <div>
      <GroupTitle>settings_styles.border.label</GroupTitle>
      <Select
        update={updateStyleProp('borderStyle')}
        value={calculatedStyle}
        choiceList={borderStyleValues}
        labelText="settings_styles.border_style.label"
        labelIcon={isMobile && <MobileIconUi />}
      />
      {style !== borderStyleValues.none && [
        <ColorPicker
          key="border-color"
          update={updateStyleProp('borderColor')}
          color={calclulatedColor}
          label="settings_styles.border.color"
          singleColorMode
        />,
        isBorderTypeVisible && (
          <Select
            key="border-type"
            update={updateOptionProp('borderType')}
            value={calclulatedType}
            choiceList={borderTypeNames}
            labelText="settings_styles.border.type"
            labelIcon={isMobile && <MobileIconUi />}
          />
        ),
        <Select
          key="border-width"
          update={updateStyleProp('borderWidth')}
          value={calclulatedWidth}
          choiceList={borderSizes}
          labelText="settings_styles.border.size"
          labelIcon={isMobile && <MobileIconUi />}
        />,
      ]}
    </div>
  )
}

BorderStyle.propTypes = {
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  type: PropTypes.string,
  style: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  mobileType: PropTypes.string,
  mobileStyle: PropTypes.string,
  mobileWidth: PropTypes.string,
  mobileColor: PropTypes.string,
  isBorderTypeVisible: PropTypes.bool,
}

export default memo(BorderStyle)
