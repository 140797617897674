import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

function CornerStyleAll({ topRight, mobileTopRight, update, mobileUpdate }) {
  const { isMobile } = useDeviceModeContext()

  return (
    <React.Fragment>
      <GroupTitle>settings_styles.corner_size.label</GroupTitle>
      <Range
        labelIcon={isMobile && <MobileIconUi />}
        value={isMobile ? mobileTopRight : topRight}
        update={isMobile ? mobileUpdate : update}
      />
    </React.Fragment>
  )
}

CornerStyleAll.propTypes = {
  topLeft: PropTypes.number,
  topRight: PropTypes.number,
  bottomLeft: PropTypes.number,
  bottomRight: PropTypes.number,
  mobileTopLeft: PropTypes.number,
  mobileTopRight: PropTypes.number,
  mobileBottomLeft: PropTypes.number,
  mobileBottomRight: PropTypes.number,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

export default memo(CornerStyleAll)
