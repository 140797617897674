import { migrateSection } from 'tools/commands/migrate/Section/migrateSection'
import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateCommand } from '../utils/migrateCommand'

export default function (page: ServerPage): [ServerPage, boolean] {
  return migrateCommand({
    page,
    structureType: structureTypes.SECTION,
    migrateFunction: migrateSection,
  })
}
