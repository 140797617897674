import styled from 'styled-components'

const GreenButtonUi = styled.button`
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-right: 2px;
  margin-left: 2px;
  color: #6b7c93;
  background-color: #fff;
  font-weight: ${({ $active }) => ($active ? '600' : '400')};
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  padding: 6px 10px;
  cursor: pointer;
  line-height: 1.42857143;
  outline: none;
  transition: background-color 0.25s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`

// color: ${({ active }) => (active ? '#142d63' : '#6b7c93')};
// background-color: ${({ active }) => (active ? '#fff' : '#fff')};

export default GreenButtonUi
