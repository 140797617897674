import { FETCH_BLOG_DATA, FetchBlogData } from './blogActionTypes'
import { BlogState } from './blogReducer'

export function fetchBlogData(blogData: BlogState): FetchBlogData {
  return {
    type: FETCH_BLOG_DATA,
    payload: blogData,
  }
}

export type BlogActions = FetchBlogData
