import React, { useState, useEffect } from 'react'
import { RGBColor, ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'
import styled from 'styled-components'
import { parseToObject, getColorAsString } from 'common/utils/colorUtils'
import NewColorPickerPopup from 'client/components/core/NewColorPickerPopup'
import Popover from 'client/components/core/Popover'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import ReactSelect, {
  ChangeOptionWithBooleanValue,
} from '../ReactSelect/ReactSelect'
import GradientSettings from './GradientSettings'
import ColorPickerPopupUi from './ui/ColorPickerPopupUi'
import ColorPreviewInner from './ui/ColorPreviewInner'
import ColorPreview from './ui/ColorPreviewUi'
import GradientSettingsWrapperUi from './ui/GradientSettingsWrapperUi'
import {
  checkIsBackgroundColorGradient,
  getFirstGradientColor,
  getAngleFromGradientPalette,
} from './utils'

const IconRemoverUi = styled.i`
  font-size: 30px;
  transition: all 0.2s linear;
  text-shadow: 1px 1px 2px #ccc;
  opacity: 0.7;
  margin-left: 10px;
  &:hover {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.05);
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
type OptionType = {
  value: boolean
  label: string
}

const colorPickerSelectModeStyles: StylesConfig<OptionType> = {
  control: inheritedStyles => ({
    ...inheritedStyles,
    backgroundColor: 'rgba(242, 242, 242, 1)',
    border: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: inheritedStyles => ({
    ...inheritedStyles,
    color: '#080D1F',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '18px',
    ':hover': {
      backgroundColor: 'rgba(242, 242, 242, 1)',
    },
  }),
  menu: inheritedStyles => ({
    ...inheritedStyles,
    marginTop: '3px',
  }),
  singleValue: inheritedStyles => ({
    ...inheritedStyles,
    color: 'rgba(8, 13, 31, 1)',
    fontSize: 13,
    fontWeight: 300,
    lineHeight: '22px',
  }),
}

export interface gradientPaletteInterface {
  id: number
  offset: number
  color: string
  opacity: number
}

const defaultColor = 'rgba(0, 0, 0, 0)'

const rgbaRegex = /rgba\(([\s\d]+),([\s\d]+),([\s\d]+),([\s\d]+[.,]?[\d]+)\)/g
const offsetRegex = /\s[\d.]+%/g

const getBackgroundColorsFromGradientPalette = (
  gradientPalette: gradientPaletteInterface[],
  color: string,
) => {
  const newColor = color.replace(
    /rgba.*/,
    `${gradientPalette[0].color} ${gradientPalette[0].offset * 100}%, ${
      gradientPalette[1].color
    } ${gradientPalette[1].offset * 100}%)`,
  )
  return newColor
}

type getGradientPaletteProps = {
  gradientString?: string
  firstGradientColor?: string
}

const getGradientPalette = ({
  gradientString,
  firstGradientColor,
}: getGradientPaletteProps) => {
  if (gradientString) {
    const gradientColors = gradientString.match(rgbaRegex) as RegExpMatchArray
    const gradientOffsets = gradientString.match(
      offsetRegex,
    ) as RegExpMatchArray

    const gradientPalette = gradientColors.map((color, index) => {
      return {
        id: index + 1,
        offset: parseFloat(gradientOffsets[index]) / 100,
        color,
      }
    })
    return gradientPalette as gradientPaletteInterface[]
  }
  if (firstGradientColor) {
    const initialPalette = [
      { id: 1, offset: 0.0, color: firstGradientColor },
      { id: 2, offset: 1.0, color: 'rgba(255, 255, 255, 1)' },
    ]
    return initialPalette
  }
}

type ColorPickerWithGradientProps = {
  color?: string
  mobileColor?: string
  update: (val: string) => void
  mobileUpdate: (val: string) => void
  label?: string
  removeBackgroundColorProperty: () => void
  fallbackOpacity?: number
}
function ColorPickerWithGradient({
  color = defaultColor,
  mobileColor = defaultColor,
  update,
  mobileUpdate,
  label = '',
  removeBackgroundColorProperty,
  fallbackOpacity,
}: ColorPickerWithGradientProps) {
  const { isMobile } = useDeviceModeContext()
  const updateBackground = isMobile ? mobileUpdate : update
  const calculatedColor = isMobile ? mobileColor || color : color
  const [isUsedGradient, setUsedGradient] = useState(false)
  const [gradientPalette, setGradientPalette] = useState<
    gradientPaletteInterface[]
  >([])
  const [activeColor, setActiveColor] = useState<string>(calculatedColor)
  const [activeColorId, setActiveColorId] = useState<number>(0)
  const { t } = useTranslation()

  const colorPickerModeChoices = [
    {
      label: t('entity_settings.gradient_color_picker.gradient'),
      value: true,
    },
    {
      label: t('entity_settings.gradient_color_picker.solid'),
      value: false,
    },
  ]

  useEffect(() => {
    const isGradient = checkIsBackgroundColorGradient(calculatedColor)
    if (isGradient) {
      const defaultGradientPalette = getGradientPalette({
        gradientString: calculatedColor,
      }) as gradientPaletteInterface[]
      setGradientPalette(defaultGradientPalette)
      setActiveColorId(1)
      setUsedGradient(!isUsedGradient)
      setActiveColor(defaultGradientPalette[0].color)
    }
  }, [])

  function getPrevColor(): RGBColor {
    return parseToObject(calculatedColor || defaultColor)
  }

  function hasRgbChanged(color: RGBColor) {
    const { r, g, b } = getPrevColor()
    return color.r !== r || color.g !== g || color.b !== b
  }

  function handleChangeComplete(color: ColorResult) {
    const rgb = { ...color.rgb }
    const prevA = getPrevColor().a

    if (hasRgbChanged(rgb) && prevA === 0) {
      rgb.a = fallbackOpacity || 1
    }
    setActiveColor(getColorAsString(rgb))
    removeBackgroundColorProperty()
    updateBackground(getColorAsString(rgb))
  }

  function updateColorPickerMode(option: ChangeOptionWithBooleanValue) {
    if (option && option.value) {
      chooseGradientColor()
      return
    }
    removeGradient()
  }

  function handleChangeGradientComplete(colorResult: ColorResult) {
    const rgb = { ...colorResult.rgb }
    const updatedPalette: gradientPaletteInterface[] = gradientPalette.map(
      gradientColor => {
        if (gradientColor.id === activeColorId) {
          setActiveColor(getColorAsString(rgb))
          gradientColor.color = getColorAsString(rgb)
        }
        return gradientColor
      },
    )

    setGradientPalette(updatedPalette)
    const backgroundColor = getBackgroundColorsFromGradientPalette(
      updatedPalette,
      calculatedColor,
    )
    updateBackground(backgroundColor)
  }

  function handleChangeGradient(updatedPalette: gradientPaletteInterface[]) {
    const backgroundColor = getBackgroundColorsFromGradientPalette(
      updatedPalette,
      calculatedColor,
    )
    updateBackground(backgroundColor)
  }

  function handleChangeAngle(updatedAngle: number) {
    const backgroundColor = getAngleFromGradientPalette(
      updatedAngle,
      calculatedColor,
    )
    updateBackground(backgroundColor)
  }

  function chooseGradientColor() {
    if (isUsedGradient) return
    setUsedGradient(!isUsedGradient)
    const defaultGradientPalette = getGradientPalette({
      firstGradientColor: calculatedColor,
    }) as gradientPaletteInterface[]
    setGradientPalette(defaultGradientPalette)
    const backgroundColor = getFirstGradientColor(calculatedColor)
    setActiveColorId(1)
    setActiveColor(defaultGradientPalette[0].color)
    removeBackgroundColorProperty()
    updateBackground(backgroundColor)
  }

  function removeGradient() {
    if (!isUsedGradient) return
    setUsedGradient(!isUsedGradient)
    setActiveColor(gradientPalette[0].color)
    updateBackground(gradientPalette[0].color)
  }
  function removeColor() {
    const parsedColor = parseToObject(activeColor)
    const newColor = {
      ...parsedColor,
      a: 0,
    }
    removeBackgroundColorProperty()
    setUsedGradient(false)
    updateBackground(getColorAsString(newColor))
  }

  return (
    <OptionWrapper
      labelText={label}
      labelIcon={isMobile ? <MobileIconUi /> : null}
    >
      <FlexContainer>
        <Popover
          offsetValue={45}
          flipOptions={{
            fallbackAxisSideDirection: 'end',
            fallbackPlacements: ['right'],
          }}
          strategyValue={'fixed'}
          placement={'right'}
          triggerElement={
            <ColorPreview>
              <ColorPreviewInner color={calculatedColor} />
            </ColorPreview>
          }
          popoverContent={
            <ColorPickerPopupUi>
              <ReactSelect<boolean>
                selectedOption={
                  isUsedGradient
                    ? colorPickerModeChoices[0]
                    : colorPickerModeChoices[1]
                }
                options={colorPickerModeChoices}
                update={updateColorPickerMode}
                styles={colorPickerSelectModeStyles}
                isMulti={false}
              />
              {isUsedGradient && (
                <GradientSettingsWrapperUi>
                  <GradientSettings
                    width={200}
                    palette={gradientPalette}
                    onPaletteChange={setGradientPalette}
                    onColorStopSelect={setActiveColor}
                    setActiveColorId={setActiveColorId}
                    activeColorId={activeColorId}
                    handleChangeGradient={handleChangeGradient}
                    handleChangeAngle={handleChangeAngle}
                  />
                </GradientSettingsWrapperUi>
              )}
              {/* @ts-ignore */}
              <NewColorPickerPopup
                color={activeColor}
                onChangeComplete={
                  isUsedGradient
                    ? handleChangeGradientComplete
                    : handleChangeComplete
                }
              />
            </ColorPickerPopupUi>
          }
        />
        <IconRemoverUi
          title={t('entity_options.icon_picker.remove_icon.title')}
          className="fa fa-ban"
          onClick={removeColor}
        />
      </FlexContainer>
    </OptionWrapper>
  )
}

export default ColorPickerWithGradient
