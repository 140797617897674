import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBlogLatestPosts } from 'client/pages/blog-page/entities/BlogLatestPosts'
import {
  migrateCategoriesSettings,
  migrateDateSettings,
  migrateDescriptionSettings,
  migrateFullImageBorderType,
  migrateImageBoxShadow,
  migrateTitleSettings,
} from '../BlogPostListing/utils'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
  migrateReadOnly,
} from '../utils/migrateUtils'

export default function migrateBlogLatestPosts(
  entity: OldEntityInterface,
): LatestBlogPostsInterface {
  const blogLatestPosts = createBlogLatestPosts(
    entity.parentId,
    entity.masterBlockId,
  )
  //id and masterBlock
  blogLatestPosts.id = entity.id
  if (entity.isMasterBlockRoot) {
    blogLatestPosts.isMasterBlockRoot = true
  }

  //margins
  migrateMargin(blogLatestPosts, entity)
  migrateMobileMargin(blogLatestPosts, entity)
  //attrId
  blogLatestPosts.htmlAttrId = entity.options.attrId

  blogLatestPosts.showDate = entity.options.showDate
  blogLatestPosts.maxPosts = entity.options.maxPosts
  blogLatestPosts.maxPostsPerRow = entity.options.maxPostsPerRow
  blogLatestPosts.displayHorizontally = entity.options.displayHorizontally

  if (entity.styles.padding) {
    blogLatestPosts.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    blogLatestPosts.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateImageBoxShadow(blogLatestPosts, entity)
  migrateFullImageBorderType(blogLatestPosts, entity)
  migrateTitleSettings(blogLatestPosts, entity)
  migrateDescriptionSettings(blogLatestPosts, entity)
  migrateCategoriesSettings(blogLatestPosts, entity)
  migrateDateSettings(blogLatestPosts, entity)
  migrateReadOnly(blogLatestPosts, entity)

  return blogLatestPosts
}
