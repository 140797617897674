import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { ContactUsButtonInterface } from 'common/types/entities/contact-us-button-interface'
import { FieldInterface } from 'common/types/entities/field-interface'
import FieldSettings from 'client/components/core/Sidebar/components/Settings/entities/field-settings'
import ContactUsSettings from 'client/pages/contact-us/settings/ContactUsSettings'
import createCommonEntitySettings from '../createCommonEntitySettings'
import { AttachmentsInterface } from './entities/Attachments'
import AttachmentsSettings from './settings/AttachmentsSettings'
import ButtonSettings from './settings/ButtonSettings'

function createSettingsForContactUs(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.ContactUs:
      return <ContactUsSettings entity={entity as ContactUsInterface} />
    case EntityTypeEnum.Button:
    case EntityTypeEnum.ContactUsButton:
      return <ButtonSettings entity={entity as ContactUsButtonInterface} />
    case EntityTypeEnum.ContactUsField:
    case EntityTypeEnum.Field:
      return <FieldSettings entity={entity as FieldInterface} />
    case EntityTypeEnum.Attachments:
      return <AttachmentsSettings entity={entity as AttachmentsInterface} />
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForContactUs
