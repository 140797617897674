// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rw-input {
    height: 42px;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./client/components/core/Sidebar/components/Settings/options/DateTimePicker/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".rw-input {\n    height: 42px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
