import EntityTypeEnum from "common/enums/entityTypeEnum"
import { OldEntityInterface } from "common/types/entities/OldEntityInterface"
import { BodyInterface } from "common/types/entities/body-interface"
import { migrateBackground, migrateBackgroundColor } from "../utils/migrateUtils"

export function migrateBody(entity: OldEntityInterface): BodyInterface {
  const body: BodyInterface = {
    id: entity.id,
    isAffiliateBadgeVisible: !!entity.options?.affiliateBadge,
    type: EntityTypeEnum.Body,
    childIds: entity.childIds || [],
    htmlAttrId: entity.options.attrId,
    background: {},
  } as BodyInterface

  if (entity.options?.blur !== undefined) {
    body.blur = entity.options.blur
  }

  if (entity.isReadOnly !== undefined) {
    body.isReadOnly = entity.isReadOnly
  }

  if (entity.mobileStyles) {
    body.mobileBackground = {}
  }

  migrateBackgroundColor(body, entity)
  migrateBackground(body, entity)

  return body
}