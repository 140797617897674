import React, { FC } from 'react'
import Checkbox from 'common/components/core/Checkbox'
import FormGroup from 'common/components/core/FormGroup'
import InputLabel from 'common/components/core/InputLabel'
import { Tooltip } from 'client/components/core/tooltip'

interface CheckboxWithLabelProps {
  isChecked: boolean
  setIsChecked: (value: boolean) => void
  labelText: string
  tooltipText?: string
}
const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({
  isChecked,
  setIsChecked,
  labelText,
  tooltipText,
}) => {
  return (
    <FormGroup styles={{ flexDirection: 'row' }}>
      <InputLabel styles="display: flex; gap: 5px;">
        {labelText}
        <Checkbox
          onChange={e => setIsChecked(e.target.checked)}
          checked={isChecked}
        />
      </InputLabel>
      {tooltipText && <Tooltip isArrowCenter message={tooltipText} />}
    </FormGroup>
  )
}

export default CheckboxWithLabel
