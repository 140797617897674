import React, { memo } from 'react'
import { TextInterface } from 'common/types/entities/TextInterface'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import TextEditor, {
  TextEditorUpdatePayload,
} from 'client/components/core/TextEditorNew'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useAppDispatch } from 'client/hooks/use-dispatch'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { updateEntity } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import BlogLink from './BlogLink'

type TextProps = {
  entity: TextInterface
  isMoveUpAllowed: boolean
  isMoveDownAllowed: boolean
}

function Text({ entity, isMoveUpAllowed, isMoveDownAllowed }: TextProps) {
  const dispatch = useAppDispatch()
  const idEditing = useManagement(m =>
    managementSelectors.isEditing(m, entity.id),
  )
  const { isMobile } = useDeviceModeContext()
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const globalTextAlign = usePage(pageSelectors.getGlobalTextAlign)
  const globalMobileTextAlign = usePage(pageSelectors.getGlobalMobileTextAlign)

  function updateText({ rawContentState }: TextEditorUpdatePayload) {
    dispatch(
      updateEntity({
        ...entity,
        rawContentState,
      }),
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <TextEditor
        attrId={entity.htmlAttrId}
        isEditing={idEditing}
        rawContentState={entity.rawContentState}
        update={updateText}
        padding={entity.padding}
        textAlign={entity.textAlign || globalTextAlign}
        editorTextAlign={
          isMobile
            ? entity.mobileTextAlign ||
              entity.textAlign ||
              globalMobileTextAlign
            : entity.textAlign || globalTextAlign
        }
        textColor={textColor}
        linkColor={linkColor}
        fontSize={entity.fontSize || globalFontSize}
        fontStyle={entity.fontStyle}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        lineHeight={entity.lineHeight || globalLineHeight}
        mobilePadding={entity.mobilePadding}
        mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
        mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        letterSpacing={entity.letterSpacing}
        mobileLetterSpacing={entity.mobileLetterSpacing}
        LinkMenu={BlogLink}
      />
    </BaseEntity>
  )
}

export default memo(Text)
