import { localeValues } from 'common/constants/localeTypes'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { ValueOf } from 'common/types/utilityTypes'

export const getTheLinkToHelpPageCodeBlock = (
  locale: ValueOf<typeof localeValues>,
) => {
  switch (locale) {
    case PageLocaleEnum.FRENCH: {
      return 'https://aide.systeme.io/article/293-est-il-possible-dinserer-un-code-html-css-ou-js'
    }
    case PageLocaleEnum.PORTUGUESE: {
      return 'https://help-pt.systeme.io/article/652-e-possivel-inserir-codigo-em-html-css-ou-js'
    }
    case PageLocaleEnum.SPANISH: {
      return 'https://help-es.systeme.io/article/771-es-posible-insertar-codigo-html-css-o-js'
    }
    case PageLocaleEnum.DEUTSCH: {
      return 'https://help-de.systeme.io/article/945-ist-es-moglich-html-css-oder-js-code-einzufugen'
    }
    case PageLocaleEnum.ITALIAN: {
      return 'https://help-it.systeme.io/article/412-e-possibile-inserire-codice-html-css-o-js'
    }
    case PageLocaleEnum.RUSSIAN: {
      return 'https://help-ru.systeme.io/article/1964-html-css-js'
    }
    case PageLocaleEnum.DUTCH: {
      return 'https://help-nl.systeme.io/article/1374-is-het-mogelijk-om-een-html-css-of-js-code-in-te-voegen'
    }
    case PageLocaleEnum.JAPANESE: {
      return 'https://help-jp.systeme.io/article/2193-is-it-possible-to-insert-html-css-or-js-code'
    }
    case PageLocaleEnum.CHINESE: {
      return 'https://help-zh.systeme.io/article/2193-is-it-possible-to-insert-html-css-or-js-code'
    }
    case PageLocaleEnum.TURKISH: {
      return 'https://help-tr.systeme.io/article/2193-is-it-possible-to-insert-html-css-or-js-code'
    }
    case PageLocaleEnum.ARABIC: {
      return 'https://help-ar.systeme.io/article/2193-is-it-possible-to-insert-html-css-or-js-code'
    }
    default: {
      return 'https://help.systeme.io/article/306-is-it-possible-to-insert-html-css-or-js-code'
    }
  }
}
