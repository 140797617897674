import React from 'react'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { SalesRedirectionButtonInterface } from 'common/types/entities/button-interface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import SalesRedirectionButton from './entities/SalesRedirectionButton'

export default function createEntityElementForSales(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.SalesRedirectionButton:
      return (
        <SalesRedirectionButton
          entity={entity as SalesRedirectionButtonInterface}
          {...additionalProps}
        />
      )
    case structureTypes.PHYSICAL_PRODUCT:
      return <div />
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
