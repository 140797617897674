import AceEditor from 'react-ace'
// AceEditor should be imported earlier than ace-builds !
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Ace } from 'ace-builds'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-textmate'
import 'ace-builds/webpack-resolver'
import { getUserLocale } from 'client/store/management/managementSelectors'
import {
  DialogBody,
  DialogCancel,
  DialogFooter,
  DialogHeader,
  DialogSubmit,
} from '../Dialog'
import DialogNotValidCodeUi from '../Dialog/DialogNotValidCodeUi'
import HasChangesUi from './ui/HasChangesUi'
import LinkHelpPageUi from './ui/LinkHelpPageUI'
import { useAppDispatch } from 'client/hooks/use-dispatch'
import { closeCodeDialog } from 'client/actions/actionsManagement'
import { getTheLinkToHelpPageCodeBlock } from './utils/getTheLinkToHelpPageCodeBlock'
import { useManagement } from 'client/store'
import ButtonLoader from 'common/components/core/ButtonLoader'

const ignoredAnnotations = [
  'in the frameset phase. Ignored.',
  'Expected e.g. <!DOCTYPE html>.',
  'Unexpected end tag (noscript). Ignored.',
  'Named entity expected. Got none.',
]

type CodeEditorProps = {
  label: string
  handleChange: (val?: string) => void
  value?: string
}

export type AsyncCodeEditor = (props: CodeEditorProps) => React.ReactPortal

function CodeEditor(props: CodeEditorProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState(props.value)
  const [isValid, setIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [annotations, setAnnotations] = useState<Ace.Annotation[]>([])
  const locale = useManagement(getUserLocale)

  const dispatch = useAppDispatch()
  const closeDialog = () => {
    dispatch(closeCodeDialog())
  }
  const save = async () => {
    try {
      setIsLoading(true)
      await axios.post(`/validate-html`, {
        html: value,
      })
      props.handleChange(value)
      setTimeout(closeDialog, 1000)
      setIsLoading(false)
    } catch (e) {
      setIsValid(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsValid(true)
  }, [value])

  const hasChanges = value !== props.value

  return createPortal(
    <React.Fragment>
      <DialogHeader close={!hasChanges ? closeDialog : undefined}>
        {t(props.label)}
        <LinkHelpPageUi>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={getTheLinkToHelpPageCodeBlock(locale)}
          >
            {t('components.core.code_editor.link_help_page')}
          </a>
        </LinkHelpPageUi>
      </DialogHeader>
      <DialogBody>
        <AceEditor
          width="700"
          mode="html"
          theme="textmate"
          fontSize={14}
          showPrintMargin={true}
          onValidate={annotations =>
            setAnnotations(
              annotations.filter(
                annotation =>
                  !ignoredAnnotations.some(ignore =>
                    annotation.text.includes(ignore),
                  ),
              ),
            )
          }
          annotations={annotations}
          showGutter={true}
          value={value}
          highlightActiveLine={true}
          onChange={setValue}
          name="code-editor"
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </DialogBody>
      <DialogFooter>
        {value && annotations.length !== 0 && (
          <DialogNotValidCodeUi>
            {t('components.core.code_editor.code_is_not_valid')}
          </DialogNotValidCodeUi>
        )}
        <DialogCancel onClick={closeDialog}>
          {t('components.core.code_editor.discard_changes')}
        </DialogCancel>
        <DialogSubmit
          onClick={save}
          disabled={annotations.length !== 0 || isLoading || !isValid}
        >
          {hasChanges && <HasChangesUi />}
          {isLoading && <ButtonLoader data-test-element="save-spinner" />}
          {t('components.core.code_editor.save')}
        </DialogSubmit>
        {!isValid && (
          <span style={{ color: 'red' }}>
            {t('components.core.code_editor.code_is_not_valid')}
          </span>
        )}
      </DialogFooter>
    </React.Fragment>,
    document.getElementById('code-dialog') as HTMLElement,
  )
}

export default CodeEditor
