import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { BlogContentPlaceholderInterface } from 'common/types/entities/BlogContentPlaceholderInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBlogContentPlaceholder } from 'client/pages/blog/entities/BlogContentPlaceholderNew'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

export default function migrateBlogContentPlaceholder(
  entity: OldEntityInterface,
): BlogContentPlaceholderInterface {
  const blogContentPlaceholder = createBlogContentPlaceholder(
    entity.parentId,
    entity.masterBlockId,
  )
  //id and masterBlock
  blogContentPlaceholder.id = entity.id
  if (entity.isMasterBlockRoot) {
    blogContentPlaceholder.isMasterBlockRoot = true
  }

  //margins
  migrateMargin(blogContentPlaceholder, entity)
  migrateMobileMargin(blogContentPlaceholder, entity)
  //attrId
  blogContentPlaceholder.htmlAttrId = entity.options.attrId
  //paddings
  if (entity.styles.padding) {
    blogContentPlaceholder.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    blogContentPlaceholder.mobilePadding = migratePadding(
      entity.mobileStyles.padding,
    )
  }
  //childIds
  if (entity.childIds) {
    blogContentPlaceholder.childIds = entity.childIds
  }

  migrateReadOnly(blogContentPlaceholder, entity)

  return blogContentPlaceholder
}
