import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBlogPostCategories } from 'client/pages/blog/entities/BlogPostCategories'
import { migrateMargin, migrateMobileMargin } from '../utils/migrateUtils'
import {
  migrateAlignSelf,
  migrateColor,
  migrateFontSettings,
  migrateFontSize,
} from './utils'

export default function migrateBlogPostCategories(
  entity: OldEntityInterface,
): BlogPostCategoriesInterface {
  const blogPostCategories = createBlogPostCategories(
    entity.parentId,
    entity.masterBlockId,
  )
  //id and masterBlock
  blogPostCategories.id = entity.id
  if (entity.isMasterBlockRoot) {
    blogPostCategories.isMasterBlockRoot = true
  }

  //margins
  migrateMargin(blogPostCategories, entity)
  migrateMobileMargin(blogPostCategories, entity)
  //attrId
  blogPostCategories.htmlAttrId = entity.options.attrId
  //font
  migrateFontSettings(blogPostCategories, entity)
  //color
  migrateColor(blogPostCategories, entity)
  //fontSize
  migrateFontSize(blogPostCategories, entity)
  //alignSelf
  migrateAlignSelf(blogPostCategories, entity)
  migrateReadOnly(blogPostCategories, entity)

  return blogPostCategories
}
