import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styled from 'styled-components'
import { useSidebarVisibilityContext } from 'client/context/SidebarVisibilityContext'

const LayoutUi = styled.div`
  display: grid;
  grid-template-rows: var(--layout-header-height) auto;
  grid-template-columns: ${({ $showSidebar }) => ($showSidebar ? '365px' : '0')} 1fr;
  height: 100vh;
`

function Layout(props) {
  const isVisible = useSidebarVisibilityContext()
  return (
    <DndProvider backend={HTML5Backend}>
      <LayoutUi $showSidebar={isVisible}>{props.children}</LayoutUi>
    </DndProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(Layout)
