import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SwitcherButtonIconUi from './ui/SwitcherButtonIconUi'
import SwitcherButtonUi from './ui/SwitcherButtonUi'
import SwitcherDescriptionUi from './ui/SwitcherDescriptionUi'
import SwitcherUi from './ui/SwitcherUi'
import SwitcherWrapperUi from './ui/SwitcherWrapperUi'

/**
 * A component that displays a set of buttons to switch between options.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Function} props.handleFirstButton - Callback function for the first button
 * @param {boolean} props.isFirstActive - Whether the first button is active
 * @param {string} props.firstLabel - Text label for the first button
 * @param {Function} props.handleSecondButton - Callback function for the second button
 * @param {string} props.secondLabel - Text label for the second button
 * @param {boolean} props.isSecondActive - Whether the second button is active
 * @param {Function} [props.handleThirdButton] - Callback function for the third button (optional)
 * @param {boolean} [props.isThirdActive] - Whether the third button is active (optional)
 * @param {string} [props.thirdLabel] - Text label for the third button (optional)
 * @param {string} [props.description] - Description text for the component (optional)
 * @param {Object} [props.wrapperStyle] - Custom styles for the component wrapper (optional)
 * @returns {React.Element} The rendered component
 */
function Switcher({
  handleFirstButton,
  isFirstActive,
  firstLabel,
  handleSecondButton,
  secondLabel,
  isSecondActive,
  handleThirdButton,
  isThirdActive,
  thirdLabel,
  description = '',
  wrapperStyle = '',
}) {
  const { t } = useTranslation()
  return (
    <SwitcherUi styles={wrapperStyle}>
      {description && (
        <SwitcherDescriptionUi>{t(description)}</SwitcherDescriptionUi>
      )}
      <SwitcherWrapperUi>
        <SwitcherButtonUi onClick={handleFirstButton} active={isFirstActive}>
          <SwitcherButtonIconUi
            className={`far fa-eye${isFirstActive ? '' : '-slash'}`}
          />
          {t(firstLabel)}
        </SwitcherButtonUi>
        <SwitcherButtonUi onClick={handleSecondButton} active={isSecondActive}>
          <SwitcherButtonIconUi
            className={`far fa-eye${isSecondActive ? '' : '-slash'}`}
          />
          {t(secondLabel)}
        </SwitcherButtonUi>
        {thirdLabel && (
          <SwitcherButtonUi onClick={handleThirdButton} active={isThirdActive}>
            <SwitcherButtonIconUi
              className={`far fa-eye${isThirdActive ? '' : '-slash'}`}
            />
            {t(thirdLabel)}
          </SwitcherButtonUi>
        )}
      </SwitcherWrapperUi>
    </SwitcherUi>
  )
}

Switcher.propTypes = {
  handleFirstButton: PropTypes.func.isRequired,
  isFirstActive: PropTypes.bool.isRequired,
  firstLabel: PropTypes.string.isRequired,
  handleSecondButton: PropTypes.func.isRequired,
  secondLabel: PropTypes.string.isRequired,
  isSecondActive: PropTypes.bool.isRequired,
  handleThirdButton: PropTypes.func,
  isThirdActive: PropTypes.bool,
  thirdLabel: PropTypes.string,
  description: PropTypes.string,
  wrapperStyle: PropTypes.object,
}

export default Switcher
