import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BackgroundPositionTypeEnum,
  BackgroundTypeEnum,
} from 'common/enums/BackgroundEnum'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import FileManager from 'client/components/FileManager/FileManager'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { pageSelectors, useFiles, usePage } from 'client/store'
import { DataFile } from 'client/store/files/filesReducer'
import filesSelectors from 'client/store/files/filesSelectors'
import Select, {
  ChangeOptionWithStringValue,
} from '../../components/ReactSelect/ReactSelect'
import InputGroupUi from '../../options/BackgroundImage/ui/InputGroupUi'
import InputTextUi from '../../options/BackgroundImage/ui/InputTextUi'
import RemoveFileIconUi from '../../options/BackgroundImage/ui/RemoveFileIconUi'
import UploadButtonIconUi from '../../options/BackgroundImage/ui/UploadButtonIconUi'
import UploadButtonUi from '../../options/BackgroundImage/ui/UploadButtonUi'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import BackgroundPositionWrapperUi from '../ui/BackgroundPositionWrapperUi'
import MobileIconUi from '../ui/MobileIconUi'
import {
  getFileName,
  detectBackgroundPositionType,
  detectBackgroundType,
  backGroundPositionNames,
  backgroundPositionStyles,
  convertOldPositionType,
  backgroundPositionValues,
  circlesPositionRenderer,
} from './utils'
import {
  BackgroundStylesInterface,
  BackgroundInterface,
} from 'common/types/background-interfaces'

type BackgroundPositionStyleProps = {
  update: (value: BackgroundStylesInterface) => void
  backgroundPosition?: BackgroundStylesInterface
  fileId: BackgroundInterface['backgroundFileId']
  fileLabelText?: string
  updateBackgroundFileId: (
    value: BackgroundInterface['backgroundFileId'],
  ) => void
}

function BackgroundPositionStyleNew({
  update,
  backgroundPosition,
  fileId,
  updateBackgroundFileId,
  fileLabelText = 'settings_styles.background_image.style',
}: BackgroundPositionStyleProps) {
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  const pageType = usePage(pageSelectors.getPageType)
  const isLecture = pageType === PageTypeEnum.Lecture

  const [currentBackgroundType, setCurrentBackgroundType] = useState(
    detectBackgroundType(backgroundPosition, isLecture),
  )
  const [currentPositionType, setCurrentPositionType] = useState(
    detectBackgroundPositionType(backgroundPosition),
  )
  const [fileManagerOpened, setFileManagerOpened] = useState(false)
  const backgroundImageFile = useFiles(state =>
    filesSelectors.getFileById(state, fileId),
  )

  function updateFile(file: DataFile) {
    updateBackgroundFileId(file.id)
  }

  function removeFile() {
    updateBackgroundFileId(null)
  }

  function openFileManager() {
    setFileManagerOpened(true)
  }

  function closeFileManager() {
    setFileManagerOpened(false)
  }

  const fileName = getFileName(backgroundImageFile)

  function getBackgroundPositionOption(position: BackgroundTypeEnum) {
    return {
      label: t(backGroundPositionNames[position]),
      value: position,
    }
  }

  function getBackgroundPositionOptions() {
    return [
      getBackgroundPositionOption(BackgroundTypeEnum.Default),
      /*
       * NOTE: the iframe in the user-domain project (course lectures) doesn't support "background-attachment: fixed"
       */
      ...(!isLecture
        ? [getBackgroundPositionOption(BackgroundTypeEnum.Fixed)]
        : []),
      getBackgroundPositionOption(BackgroundTypeEnum.Repeat),
      getBackgroundPositionOption(BackgroundTypeEnum.Width100),
      getBackgroundPositionOption(BackgroundTypeEnum.Width100Height100),
      getBackgroundPositionOption(BackgroundTypeEnum.ScalesWithoutCropping),
    ]
  }

  function updateBackgroundPosition(option: ChangeOptionWithStringValue) {
    if (option) {
      update(backgroundPositionStyles[option.value as BackgroundTypeEnum])

      setCurrentBackgroundType(
        detectBackgroundType(
          backgroundPositionStyles[option.value as BackgroundTypeEnum],
          isLecture,
        ),
      )

      setCurrentPositionType(
        detectBackgroundPositionType(
          backgroundPositionStyles[option.value as BackgroundTypeEnum],
        ),
      )
    }
  }

  const onCircleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (
      event.target instanceof Element &&
      event.target.id &&
      backgroundPosition
    ) {
      setCurrentPositionType(event.target.id as BackgroundPositionTypeEnum)
      const updatedPositionValue = {
        ...backgroundPosition,
        backgroundPosition:
          backgroundPositionValues[
            event.target.id as BackgroundPositionTypeEnum
          ],
      }
      update(updatedPositionValue)
    }
  }
  //@TODO: will be removed after checking all backgroundPosition
  useEffect(() => {
    if (backgroundPosition && typeof backgroundPosition === 'string') {
      const convertedPositionType = convertOldPositionType(backgroundPosition)
      update(convertedPositionType)
      setCurrentPositionType(
        detectBackgroundPositionType(convertedPositionType),
      )
      setCurrentBackgroundType(
        detectBackgroundType(backgroundPosition, isLecture),
      )
    }
  }, [backgroundPosition])

  useEffect(() => {
    setCurrentBackgroundType(
      detectBackgroundType(backgroundPosition, isLecture),
    )
    setCurrentPositionType(detectBackgroundPositionType(backgroundPosition))
  }, [backgroundPosition])

  /** @description Deprecated */
  // useEffect(() => {
  //   if (currentBackgroundType) {
  //     const backgroundStyles = currentPositionType
  //       ? {
  //           ...backgroundPositionStyles[currentBackgroundType],
  //           backgroundPosition: backgroundPositionValues[currentPositionType],
  //         }
  //       : backgroundPositionStyles[currentBackgroundType]

  //     update(backgroundStyles)
  //   }
  // }, [currentBackgroundType, currentPositionType, fileId])

  return (
    <>
      <OptionWrapper
        labelText={fileLabelText}
        labelIcon={isMobile ? <MobileIconUi /> : null}
      >
        <InputGroupUi>
          <InputTextUi value={fileName} />
          {fileName && removeFile && (
            <RemoveFileIconUi className="fa fa-times" onClick={removeFile} />
          )}
          <UploadButtonUi onClick={openFileManager}>
            <UploadButtonIconUi
              className="fas fa-cloud-upload-alt"
              aria-hidden="true"
            />
          </UploadButtonUi>
        </InputGroupUi>
        {fileManagerOpened && (
          <FileManager
            closeFileManager={closeFileManager}
            mimeTypes={mimeTypes.image}
            fileType="image"
            onInsert={updateFile}
          />
        )}
      </OptionWrapper>
      {fileId && (
        <BackgroundPositionWrapperUi>
          <div style={{ width: '70%' }}>
            <Select<string>
              selectedOption={
                currentBackgroundType
                  ? getBackgroundPositionOption(currentBackgroundType)
                  : null
              }
              update={updateBackgroundPosition}
              labelText="Display image"
              options={getBackgroundPositionOptions()}
              isMulti={false}
              labelIcon={isMobile ? <MobileIconUi /> : undefined}
            />
          </div>
          {currentBackgroundType !== BackgroundTypeEnum.Fixed && (
            <>
              {circlesPositionRenderer(
                onCircleClick,
                currentPositionType,
                currentBackgroundType,
              )}
            </>
          )}
        </BackgroundPositionWrapperUi>
      )}
    </>
  )
}

export default BackgroundPositionStyleNew
