import React from 'react'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { RemotePopupUi } from './remote-popup-ui'

interface RemotePopupProps {
  children?: React.ReactNode
}

export const RemotePopup = ({ children }: RemotePopupProps) => {
  const fontFamily = usePage(pageSelectors.getGlobalFontFamily)
  const fontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileFontSize = usePage(pageSelectors.getGlobalMobileTextFontSize)

  return (
    <RemotePopupUi
      fontFamily={fontFamily}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
    >
      {children}
    </RemotePopupUi>
  )
}
