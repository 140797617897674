import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import InputUi from './ui/InputUi'

/**
 * Input component
 * @component
 *
 * @param {Object} props - Component props
 * @param {function} props.update - Function to call when input value changes
 * @param {(number|string)} [props.value] - The value of the input
 * @param {string} [props.labelText] - Text to display as the input label
 * @param {string} [props.placeholder] - Placeholder text for the input
 * @param {string} [props.type] - Type of the input (e.g. 'text', 'number', etc.)
 * @param {string} [props.helpMessage] - Help message to display below the input
 *
 * @returns {React.Element} Input component
 */
const Input = ({
  value = '',
  update,
  labelText = null,
  placeholder = '',
  type = 'text',
  helpMessage = null,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <OptionWrapper labelText={labelText} helpMessage={helpMessage}>
      <InputUi
        onChange={e => update(e.target.value)}
        value={value}
        placeholder={t(placeholder)}
        type={type}
        {...rest}
      />
    </OptionWrapper>
  )
}

Input.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helpMessage: PropTypes.string,
}

export default Input

export { InputUi }
