import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

function CornerStyle({
  topLeft = 0,
  topRight = 0,
  bottomLeft = 0,
  bottomRight = 0,
  mobileTopLeft = 0,
  mobileTopRight = 0,
  mobileBottomLeft = 0,
  mobileBottomRight = 0,
  update,
  mobileUpdate,
}) {
  const { isMobile } = useDeviceModeContext()

  const updateProp = style => value =>
    isMobile ? mobileUpdate(style, 'px')(value) : update(style, 'px')(value)

  return (
    <div>
      <GroupTitle afterChildren={isMobile && <MobileIconUi />}>
        settings_styles.corner_size.label
      </GroupTitle>
      <Range
        labelText="settings_styles.corner_size.top_left"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileTopLeft : topLeft, 0)}
        update={updateProp('borderTopLeftRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.top_right"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileTopRight : topRight, 0)}
        update={updateProp('borderTopRightRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.bottom_left"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileBottomLeft : bottomLeft, 0)}
        update={updateProp('borderBottomLeftRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.bottom_right"
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(isMobile ? mobileBottomRight : bottomRight, 0)}
        update={updateProp('borderBottomRightRadius')}
      />
    </div>
  )
}

CornerStyle.propTypes = {
  topLeft: PropTypes.string | PropTypes.number,
  topRight: PropTypes.string | PropTypes.number,
  bottomLeft: PropTypes.string | PropTypes.number,
  bottomRight: PropTypes.string | PropTypes.number,
  mobileTopLeft: PropTypes.string | PropTypes.number,
  mobileTopRight: PropTypes.string | PropTypes.number,
  mobileBottomLeft: PropTypes.string | PropTypes.number,
  mobileBottomRight: PropTypes.string | PropTypes.number,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

export default memo(CornerStyle)
