import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createPaymentMethod } from '../../../../client/pages/offer/entities/PaymentMethod'
import {
  migrateFontSettings,
  migrateFontSize,
  migrateFullBorderType,
  migrateMargin,
  migrateMobileMargin,
} from '../utils/migrateUtils'

export function migratePaymentMethod(
  entity: OldEntityInterface,
): EntityInterface {
  const newEntity = createPaymentMethod(entity.parentId, entity.masterBlockId)
  //id and masterBlock
  newEntity.id = entity.id
  if (entity.isMasterBlockRoot) {
    newEntity.isMasterBlockRoot = true
  }
  //margins
  migrateMargin(newEntity, entity)
  migrateMobileMargin(newEntity, entity)

  migrateFontSettings(newEntity, entity)
  migrateFontSize(newEntity, entity)

  //@ts-expect-error seems to be excessive, entity interface contains no border
  migrateFullBorderType(newEntity, entity)

  if (entity.styles.color) {
    newEntity.color = entity.styles.color
  }

  if (entity.mobileStyles.color) {
    newEntity.mobileColor = entity.mobileStyles.color
  }

  //appearance
  if (entity.options.appearance) {
    newEntity.appearance.desktop = entity.options.appearance.desktop
    newEntity.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  newEntity.htmlAttrId = entity.options.attrId

  return newEntity
}
