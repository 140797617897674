import ServerPageInterface from "server/types/ServerPageInterface"
import { migrateCommand } from "../utils/migrateCommand"
import structureType from 'common/constants/structureTypes'
import { migrateBody } from "./migrate-body"

export default function (page: ServerPageInterface) {
  return migrateCommand({
    page,
    structureType: structureType.BODY,
    migrateFunction: migrateBody,
  })
}