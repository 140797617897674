import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import {
  migrateMargin,
  migrateMobileMargin,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createRawHtml } from 'client/components/entities/RawHtml/NewRawHtml'

export function migrateRawHtml(entity: OldEntityInterface) {
  const rawHtml = createRawHtml(entity.parentId, entity.masterBlockId)

  if (entity.childIds && entity.childIds.length !== 0) {
    rawHtml.childIds = entity.childIds
  }

  rawHtml.id = entity.id

  rawHtml.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    rawHtml.appearance.desktop = entity.options.appearance.desktop
    rawHtml.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(rawHtml, entity)
  migrateMobileMargin(rawHtml, entity)
  migrateReadOnly(rawHtml, entity)

  //html
  if (entity.options.html) {
    rawHtml.html = entity.options.html
  }

  //align
  if (entity.styles.textAlign) {
    rawHtml.textAlign = entity.styles.textAlign
  }
  //mobileAlign
  if (entity.mobileStyles.textAlign) {
    rawHtml.mobileTextAlign = entity.mobileStyles.textAlign
  }

  return rawHtml
}
