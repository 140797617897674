import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { imageActionsKeys } from 'common/constants/settings'
import { OldImageInterface } from 'common/types/entities/OldEntityInterface'
import { createImage } from 'client/components/entities/Image/Image'
import {
  migrateFullBorderType,
  migrateMargin,
  migrateMobileMargin,
} from '../utils/migrateUtils'

export function migrateImage(originalImage: OldImageInterface) {
  const image = createImage(originalImage.parentId, originalImage.masterBlockId)

  image.id = originalImage.id
  image.htmlAttrId = originalImage.options.attrId
  if (originalImage.isMasterBlockRoot) {
    originalImage.isMasterBlockRoot = true
  }
  //margins
  migrateMargin(image, originalImage)
  migrateMobileMargin(image, originalImage)

  if (
    originalImage.options.delay &&
    !isNaN(parseInt(originalImage.options.delay))
  ) {
    image.delay = parseInt(originalImage.options.delay)
  }
  if (originalImage.options.alt) {
    image.alt = originalImage.options.alt
  }
  if (
    originalImage.options.ratio &&
    !isNaN(parseFloat(originalImage.options.ratio))
  ) {
    image.ratio = parseFloat(originalImage.options.ratio)
  }

  if (originalImage.options.link) {
    image.link = originalImage.options.link
  }
  if (
    originalImage.options.action &&
    originalImage.options.action === imageActionsKeys.showPopup
  ) {
    if (originalImage.options.popup) {
      image.popupId = originalImage.options.popup
    }
  }
  if (originalImage.options.appearance) {
    image.appearance.desktop = originalImage.options.appearance.desktop
    image.appearance.mobile = originalImage.options.appearance.mobile
  }
  if (
    originalImage.options.blur &&
    !isNaN(parseInt(originalImage.options.blur))
  ) {
    image.blur = parseInt(originalImage.options.blur)
  }
  if (originalImage.options.openNewTab) {
    image.openNewTab = originalImage.options.openNewTab
  }

  if (originalImage.styles.backgroundImage) {
    image.externalUrl = originalImage.styles.backgroundImage
  }
  if (originalImage.options.srcFileId) {
    image.fileId = originalImage.options.srcFileId
  }
  if (
    originalImage.styles.width &&
    !isNaN(parseInt(originalImage.styles.width))
  ) {
    image.width = parseInt(originalImage.styles.width)
  }
  if (originalImage.mobileStyles.width === '100%') {
    image.mobileWidth = originalImage.mobileStyles.width
  } else if (
    originalImage.mobileStyles.width &&
    !isNaN(parseInt(originalImage.styles.width))
  ) {
    image.mobileWidth = parseInt(originalImage.styles.width)
  }
  migrateFullBorderType(image, originalImage)
  if (originalImage.styles.boxShadow) {
    image.boxShadow = originalImage.styles.boxShadow
  }
  if (originalImage.styles.justifyContent) {
    image.alignSelf = originalImage.styles.justifyContent
  }
  if (originalImage.mobileStyles.justifyContent) {
    image.mobileAlignSelf = originalImage.mobileStyles.justifyContent
  }

  migrateReadOnly(image, originalImage)

  return image
}
