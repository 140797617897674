import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import Select, {
  ChangeOptionValue,
  OptionType,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import { ShadowStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import GroupTitle from '../../styleComponents/GroupTitle'
import { LayoutType } from '../PostListingLayoutSetting/utils'
import VerticalAlign from './VerticalAlign'

type BlogPostCardSettingsProps<T> = {
  entity: T
  updateNestedProp: <K1 extends keyof T>(
    firstLevelPropKey: K1,
  ) => <K2 extends keyof T[K1]>(
    secondLevelPropKey: K2,
  ) => (value: T[K1][K2]) => void
  updateProp: <K extends keyof T>(prop: K) => (value: string) => void
  isMobile: boolean
}
function BlogPostCardSettings<T extends Record<string, any>>({
  updateNestedProp,
  updateProp,
  isMobile,
  entity,
}: BlogPostCardSettingsProps<T>) {
  const { t } = useTranslation()
  const isImagePlacementSelectVisible = isMobile
    ? true
    : !entity.layoutType || entity.layoutType === LayoutType.Grid3x3

  const isImageVariantIsBackground = isMobile
    ? entity.mobileImagePlacement === ImagePlacementVariants.Background
    : entity.imagePlacement === ImagePlacementVariants.Background ||
      (entity.layoutType && entity.layoutType !== LayoutType.Grid3x3)

  const imagePlacementOptions: OptionType<string>[] = [
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.top',
      ),
      value: ImagePlacementVariants.Up,
    },
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.left',
      ),
      value: ImagePlacementVariants.Left,
    },
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.background',
      ),
      value: ImagePlacementVariants.Background,
    },
  ]

  function updateImagePlacement(option: ChangeOptionValue<string>) {
    if (option) {
      if (isMobile) {
        updateProp('mobileImagePlacement')(option.value)
      } else updateProp('imagePlacement')(option.value)
    }
  }

  const getDefaultImagePlacement = (imagePlacement?: string) => {
    if (!imagePlacement) {
      return imagePlacementOptions[0]
    }

    const selectedCategoryOption = imagePlacementOptions.find(
      state => state.value === imagePlacement,
    )

    if (selectedCategoryOption) {
      return selectedCategoryOption
    } else return imagePlacementOptions[0]
  }
  const defaultForeGroundColor = 'rgba(255, 255, 255, 0.2)'

  const foreGroundColor =
    entity.postCard?.foregroundColor || defaultForeGroundColor
  const mobileForeGroundColor =
    entity.postCard?.mobileForegroundColor || defaultForeGroundColor

  return (
    <>
      <GroupTitle>entity_settings.blog_post_listing.post_card.title</GroupTitle>
      {isImagePlacementSelectVisible && (
        <Select<string>
          labelText="entity_settings.latest_blog_posts_carousel.image_placement.label"
          selectedOption={
            isMobile
              ? getDefaultImagePlacement(entity.mobileImagePlacement)
              : getDefaultImagePlacement(entity.imagePlacement)
          }
          update={updateImagePlacement}
          options={imagePlacementOptions}
          isMulti={false}
        />
      )}
      {isImageVariantIsBackground && (
        <VerticalAlign
          align={
            isMobile
              ? entity.postCard?.mobileVerticalAlignment
              : entity.postCard?.verticalAlignment
          }
          update={
            isMobile
              ? updateNestedProp('postCard')('mobileVerticalAlignment')
              : updateNestedProp('postCard')('verticalAlignment')
          }
        />
      )}
      <ColorPicker
        update={updateNestedProp('postCard')('backgroundColor')}
        mobileUpdate={updateNestedProp('postCard')('mobileBackgroundColor')}
        color={entity.postCard?.backgroundColor}
        mobileColor={entity.postCard?.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      {isImageVariantIsBackground && (
        <ColorPicker
          update={updateNestedProp('postCard')('foregroundColor')}
          mobileUpdate={updateNestedProp('postCard')('mobileForegroundColor')}
          color={foreGroundColor}
          mobileColor={mobileForeGroundColor}
          label="entity_settings.blog_post_listing.foreground_color"
        />
      )}
      <ShadowStyle
        shadow={entity.postCard?.boxShadow}
        mobileShadow={entity.postCard?.mobileBoxShadow}
        update={updateNestedProp('postCard')('boxShadow')}
        mobileUpdate={updateNestedProp('postCard')('mobileBoxShadow')}
        isTitleNeeded={false}
        labelText="settings_styles.shadow.label"
      />
      <Border
        showGroupTitle={false}
        update={updateNestedProp('postCard')('border')}
        mobileUpdate={updateNestedProp('postCard')('mobileBorder')}
        border={entity.postCard?.border}
        mobileBorder={entity.postCard?.mobileBorder}
      />
      <PaddingStyle
        padding={
          isMobile ? entity.postCard?.mobilePadding : entity.postCard?.padding
        }
        update={updateNestedProp('postCard')(
          isMobile ? 'mobilePadding' : 'padding',
        )}
        label="settings_styles.padding.label"
      />
    </>
  )
}

export default BlogPostCardSettings
