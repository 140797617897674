import React from 'react'
import { Appearance } from 'common/types/entities/EntityInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { FontUpdate } from '../components/Font/BaseFont'
import HeadingFont from '../components/Font/HeadingFont'
import { useUpdateEntity, useUpdateProp } from '../hooks/useUpdateProps'
import { DelayOption } from '../options'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import GroupTitle from '../styleComponents/GroupTitle'
import LetterSpacing from '../styles/LetterSpacing'
import MarginStyle from '../styles/MarginStyle'
import TextAlignStyle from '../styles/TextAlignStyle'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'

type HeadlineSettingsProps = {
  entity: HeadlineInterface
}

function HeadlineSettings({ entity }: HeadlineSettingsProps) {
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<HeadlineInterface>()
  const updateProp = useUpdateProp(entity)
  const fontFamily = isMobile
    ? entity.mobileFontFamily || entity.fontFamily
    : entity.fontFamily
  const fontStyle = isMobile
    ? entity.mobileFontStyle || entity.fontStyle
    : entity.fontStyle
  const fontWeight = isMobile
    ? entity.mobileFontWeight || entity.fontWeight
    : entity.fontWeight

  const globalTextAlign = usePage(pageSelectors.getGlobalHeadingAlign)
  const globalMobileTextAlign = usePage(
    pageSelectors.getGlobalMobileHeadingAlign,
  )

  function handleFontChange(fontUpdate: FontUpdate) {
    if (isMobile) {
      if (fontUpdate.fontFileId) {
        delete entity.mobileFontStyle
        delete entity.mobileFontWeight
        updateEntity({
          ...entity,
          mobileFontFamily: fontUpdate.fontFamily,
          mobileFontFileId: fontUpdate.fontFileId,
        })
      } else {
        if (entity.mobileFontFileId) {
          delete entity.mobileFontFileId
        }
        updateEntity({
          ...entity,
          mobileFontFamily: fontUpdate.fontFamily,
          ...(fontUpdate.fontStyle
            ? { mobileFontStyle: fontUpdate.fontStyle }
            : {}),
          ...(fontUpdate.fontWeight
            ? { mobileFontWeight: fontUpdate.fontWeight }
            : {}),
        })
      }
    } else {
      if (fontUpdate.fontFileId) {
        delete entity.fontStyle
        delete entity.fontWeight
        updateEntity({
          ...entity,
          fontFamily: fontUpdate.fontFamily,
          fontFileId: fontUpdate.fontFileId,
        })
      } else {
        if (entity.fontFileId) {
          delete entity.fontFileId
        }
        updateEntity({
          ...entity,
          fontFamily: fontUpdate.fontFamily,
          ...(fontUpdate.fontStyle ? { fontStyle: fontUpdate.fontStyle } : {}),
          ...(fontUpdate.fontWeight
            ? { fontWeight: fontUpdate.fontWeight }
            : {}),
        })
      }
    }
  }

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <GroupTitle>typography</GroupTitle>
      <TextFontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        removeEntityStyle={updateProp('fontSize')}
        removeEntityMobileStyle={updateProp('mobileFontSize')}
      />
      <TextLineHeight
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        removeEntityStyle={updateProp('lineHeight')}
        removeEntityMobileStyle={updateProp('mobileLineHeight')}
      />
      <HeadingFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <LetterSpacing
        change={updateProp(isMobile ? 'mobileLetterSpacing' : 'letterSpacing')}
        letterSpacing={
          isMobile ? entity.mobileLetterSpacing : entity.letterSpacing
        }
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="entity_settings.text.background_color"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <TextAlignStyle
        textAlign={entity.textAlign || globalTextAlign}
        mobileTextAlign={
          entity.mobileTextAlign || entity.textAlign || globalMobileTextAlign
        }
        update={updateProp('textAlign')}
        mobileUpdate={updateProp('mobileTextAlign')}
        isResetIconVisible={
          !!(isMobile ? entity.mobileTextAlign : entity.textAlign)
        }
        removeEntityStyle={updateProp('textAlign')}
        removeEntityMobileStyle={updateProp('mobileTextAlign')}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default HeadlineSettings
