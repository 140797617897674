import React from 'react'
import PropTypes from 'prop-types'
import { Translation } from 'react-i18next'
import SelectUi from './ui/Select'

/**
 * @typedef {Object} SelectProps
 * @param {string} [firstChoice] - The initial or default choice to be displayed.
 * @param {Object|Array} [choices] - The collection of choices available for selection.
 * @param {Object<string, any>} [styles] - Custom styles to apply to the select component.
 * @param {Function} [handleChange] - Callback function triggered when the selection changes.
 * @param {string} [value] - The currently selected value.
 * @param {boolean} [needTranslate] - Flag indicating if choices need to be translated.
 */
const Select = ({
  handleChange = () => {},
  choices = {},
  styles = {},
  value = '',
  firstChoice = '',
  needTranslate = false,
  ...rest
}) => (
  <Translation ns={['client', 'publisher']}>
    {t => (
      <SelectUi
        {...rest}
        styles={styles}
        onChange={e => {
          handleChange(e.target.value)
        }}
        value={value}
      >
        {firstChoice && <option value="">{t(firstChoice)}</option>}
        {Array.isArray(choices)
          ? choices.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))
          : Object.keys(choices).map(key => (
              <option key={key} value={key}>
                {needTranslate ? t([choices[key]]) : choices[key]}
              </option>
            ))}
      </SelectUi>
    )}
  </Translation>
)

Select.propTypes = {
  firstChoice: PropTypes.string,
  choices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  styles: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func,
  value: PropTypes.string,
  needTranslate: PropTypes.bool,
}

export default Select
