import React from 'react'
import { Appearance } from 'common/types/entities/EntityInterface'
import GroupTitle from '../../styleComponents/GroupTitle'
import Checkbox from '../../components/Checkbox'

type DeviceAppearanceProps = {
  desktop: boolean
  mobile: boolean
  update: (appearance: Appearance) => void
}

function DeviceAppearance(props: DeviceAppearanceProps) {
  function toggleDesktop() {
    props.update({
      desktop: !props.desktop,
      mobile: props.desktop ? true : props.mobile,
    })
  }

  function toggleMobile() {
    props.update({
      desktop: props.mobile ? true : props.desktop,
      mobile: !props.mobile,
    })
  }

  return (
    <>
      <GroupTitle>settings_options.device_appearance.label</GroupTitle>
      <Checkbox
        labelText="settings_options.device_appearance.desktop"
        update={toggleDesktop}
        value={props.desktop}
        directionRow
      />
      <Checkbox
        labelText="settings_options.device_appearance.mobile"
        update={toggleMobile}
        value={props.mobile}
        directionRow
      />
    </>
  )
}

export default DeviceAppearance
