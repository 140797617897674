import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Translation } from 'react-i18next'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'

const ReactSelect = ({
  value = '',
  firstOption = '',
  update,
  labelText = null,
  dataTestElement = '',
  labelIcon = null,
  isMulti = false,
  options,
  skipOptionTranslation = false,
  ...restProps
}) => (
  <Translation ns={['client', 'common']}>
    {t => (
      <OptionWrapper labelText={labelText} labelIcon={labelIcon}>
        <Select
          onChange={update}
          value={value}
          options={options}
          getOptionLabel={option =>
            skipOptionTranslation ? option.label : t(option.label)
          }
          firstOption={firstOption}
          dataTestElement={dataTestElement}
          isMulti={isMulti}
          {...restProps}
        />
      </OptionWrapper>
    )}
  </Translation>
)

ReactSelect.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  firstOption: PropTypes.string,
  dataTestElement: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  skipOptionTranslation: PropTypes.bool,
}

export default ReactSelect
