import { LocaleEnum } from 'common/enums/LocaleEnum'
import { useActions } from 'common/hooks/useActions'
import { BlockEntityInterface } from 'common/types/entities/EntityInterface'
import { addCommonBlock, addCommonBlockFile } from 'client/actions/blockActions'
import * as blockApi from 'client/api/blockApi'

interface CreateCommonBlockParams {
  locale: LocaleEnum
  blockEntities: BlockEntityInterface[]
  desktopBlobFile: string
  mobileBlobFile: string
  title: string
  category: string
}

export const useCreateCommonBlock = () => {
  const addBlock = useActions(addCommonBlock)
  const addCommonFile = useActions(addCommonBlockFile)

  const createCommonBlock = async ({
    locale,
    blockEntities,
    desktopBlobFile,
    mobileBlobFile,
    title,
    category,
  }: CreateCommonBlockParams) => {
    const { data } = await blockApi.createCommonBlock(
      locale,
      blockEntities,
      desktopBlobFile,
      mobileBlobFile,
      title,
      category,
    )
    addBlock(data.block)
    addCommonFile(data.desktopPreview)
    addCommonFile(data.mobilePreview)
    return data
  }

  return {
    createCommonBlock,
  }
}
