import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createColumn } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-column'

export function migrateColumn(entity: OldEntityInterface) {
  const column = createColumn({
    parentId: entity.parentId,
    masterBlockId: entity.masterBlockId,
  })
  column.id = entity.id
  column.htmlAttrId = entity.options.attrId
  column.childIds = entity.childIds || []
  column.size = entity.options.size

  if (entity.styles) {
    if (entity.styles.alignSelf) {
      column.alignSelf = entity.styles.alignSelf
    }
  }

  return column
}
