import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import structureTypes from '../../../../common/constants/structureTypes'
import ServerPage from '../../../../server/types/ServerPageInterface'
import migrateBlogContentPlaceholder from './migrateBlogContentPlaceholder'

export default function (page: ServerPage): [ServerPage, boolean] {
  let isChanged = false
  try {
    const entities = Object.values(page.content.entities).reduce(
      (prev, entity) => {
        if (entity.type === structureTypes.BLOG_CONTENT_PLACEHOLDER) {
          isChanged = true
        }

        return {
          ...prev,
          [entity.id]:
            entity.type === structureTypes.BLOG_CONTENT_PLACEHOLDER
              ? migrateBlogContentPlaceholder(entity as OldEntityInterface)
              : entity,
        }
      },
      {},
    )
    return [
      {
        ...page,
        content: {
          ...page.content,
          entities,
        },
      },
      isChanged,
    ]
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    console.log(`--error--`, page, page.id)

    return [page, false]
  }
}
