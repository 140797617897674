import { useTranslation } from 'react-i18next'
import React, { Fragment } from 'react'
import {
  Dialog,
  DialogBody,
  DialogCancel,
  DialogFooter,
  DialogHeader,
} from 'client/components/core/Dialog'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import { useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { ActionEditType } from 'client/types/AutomationRulesActionsEditInterface'
import ActionModalHeaderUi from './ui/ActionModalHeaderUi'
import AutomationActionContainerUi from './ui/AutomationActionContainerUi'
import AutomationActionIconContainerUi from './ui/AutomationActionIconContainerUi'
import AutomationActionsContainerUi from './ui/AutomationActionsContainerUi'
import AutomationAddActionDescriptionContainerUi from './ui/AutomationAddActionDescriptionContainerUi'
import { actionItems, getDefaultActionValue } from './utils/automationRuleUtils'

interface AddActionModalProps {
  opened: boolean
  onClose: () => void
  setRules: React.Dispatch<React.SetStateAction<ActionEditType[]>>
  maxActionId: React.MutableRefObject<number>
}

function AddActionModal({
  opened,
  onClose,
  setRules,
  maxActionId,
}: AddActionModalProps) {
  const { t } = useTranslation()
  const userId = useManagement(managementSelectors.getUserId)

  return (
    <Dialog width={900} show={opened}>
      <DialogHeader grayHeader={false} close={onClose}>
        <ActionModalHeaderUi>
          {t('entity_settings.automation_rule.edit.add_action')}
        </ActionModalHeaderUi>
      </DialogHeader>
      <DialogBody>
        <AutomationActionsContainerUi>
          {actionItems
            //for now create user will be available only for Aurelien, because we don't have workspace information in editor
            .filter(el => {
              if (!userId) return false
              const isRootAdmin = userId === '161'
              if (!isRootAdmin) {
                return el.slug !== AutomationRuleActionsEnum.create_user
              } else {
                return true
              }
            })
            .map((action, idx, actionTypes) => (
              <Fragment key={`add-action:${idx}`}>
                <AutomationActionContainerUi
                  onClick={() => {
                    setRules(prev => {
                      return [
                        ...prev,
                        {
                          ...getDefaultActionValue(action.slug, maxActionId),
                        },
                      ]
                    })
                    onClose()
                  }}
                  withHover
                >
                  <AutomationActionIconContainerUi>
                    {action.icon}
                  </AutomationActionIconContainerUi>
                  <AutomationAddActionDescriptionContainerUi>
                    <span>{t(action.title)}</span>
                    <span>{t(action.description)}</span>
                  </AutomationAddActionDescriptionContainerUi>
                </AutomationActionContainerUi>
                {idx !== actionTypes.length - 1 && (
                  <hr style={{ border: '1px solid #E1E3E5', width: '100%' }} />
                )}
              </Fragment>
            ))}
        </AutomationActionsContainerUi>
      </DialogBody>
      <DialogFooter>
        <DialogCancel onClick={onClose}>
          {t('entity_settings.survey.back_to_list')}
        </DialogCancel>
      </DialogFooter>
    </Dialog>
  )
}

export default AddActionModal
