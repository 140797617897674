import styled from 'styled-components'

interface InputLabelUiProps {
  styles?: string
}

const InputLabelUi = styled.label<InputLabelUiProps>`
  margin-bottom: 5px;
  font-size: 14px;
  ${({ styles }) => styles && styles}
`

export default InputLabelUi
