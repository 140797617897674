import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'

const Boolean = ({
  choiceList = { 1: 'ON', 0: 'OFF' },
  value = 'OFF',
  ...restProps
}) => <Select value={value} {...restProps} choiceList={choiceList} />

Boolean.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  choiceList: PropTypes.objectOf(PropTypes.any),
}

export default Boolean
