import React from 'react'
import { PriceElementInterface } from 'common/types/entities/price-element-interface'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store/index'
import pageSelectors from 'client/store/page/pageSelectors'
import DeviceAppearanceNew from '../../options/DeviceAppearance/DeviceAppearanceNew'
import GroupTitle from '../../styleComponents/GroupTitle'

interface PriceElementSettingsProps {
  entity: PriceElementInterface
}

export const PriceElementSettings = ({ entity }: PriceElementSettingsProps) => {
  const { isMobile } = useDeviceModeContext()

  const popups = usePage(pageSelectors.getPopupEntities)

  const updateProp = useUpdateProp(entity)

  const popupChoiceList = popups.reduce(
    (acc, popup) => ({
      ...acc,
      [popup.id]: popup.options.title,
    }),
    {},
  )

  return (
    <>
      <GroupTitle>Popups</GroupTitle>
      <Select
        labelText="First plan"
        update={updateProp('popupIdFirstPlan')}
        choiceList={popupChoiceList}
        firstOption="entity_settings.button.choose_actions"
        value={entity.popupIdFirstPlan}
        needTranslate={false}
      />
      <Select
        labelText="Second plan"
        update={updateProp('popupIdSecondPlan')}
        choiceList={popupChoiceList}
        firstOption="entity_settings.button.choose_actions"
        value={entity.popupIdSecondPlan}
        needTranslate={false}
      />
      <Select
        labelText="Third plan"
        update={updateProp('popupIdThirdPlan')}
        choiceList={popupChoiceList}
        firstOption="entity_settings.button.choose_actions"
        value={entity.popupIdThirdPlan}
        needTranslate={false}
      />
      <Select
        labelText="Fourth plan"
        update={updateProp('popupIdFourthPlan')}
        choiceList={popupChoiceList}
        firstOption="entity_settings.button.choose_actions"
        value={entity.popupIdFourthPlan}
        needTranslate={false}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}
