import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogCategoryDescriptionInterface } from 'common/types/entities/BlogCategoryDescriptionInterface'
import { BlogCategoryTitleInterface } from 'common/types/entities/BlogCategoryTitleInterface'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { LatestBlogPostsCarouselInterface } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { ContactUsButtonInterface } from 'common/types/entities/contact-us-button-interface'
import { FieldInterface } from 'common/types/entities/field-interface'
import ButtonSettingsNew from 'client/components/core/Sidebar/components/Settings/entities/button-settings'
import BaseTextEntitySettings from '../../components/core/Sidebar/components/Settings/BaseTextEntitySettings'
import RecaptchaSettings from '../../components/core/Sidebar/components/Settings/entities/RecaptchaSettings'
import { AttachmentsInterface } from '../contact-us/entities/Attachments'
import AttachmentsSettings from '../contact-us/settings/AttachmentsSettings'
import ButtonSettings from '../contact-us/settings/ButtonSettings'
import ContactUsFieldSettings from '../contact-us/settings/ContactUsFieldSettings'
import ContactUsSettings from '../contact-us/settings/ContactUsSettings'
import createCommonEntitySettings from '../createCommonEntitySettings'
import BlogLatestPostsSettings from './settings/BlogLatestPostsSettings'
import BlogPostListingSettings from './settings/BlogPostListingSettings'
import LatestBlogPostsCarouselSettings from './settings/LatestBlogPostsCarouselSettings'

function createSettingsForBlogPage(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.BlogPostListing:
      return (
        <BlogPostListingSettings entity={entity as BlogPostListingInterface} />
      )
    case EntityTypeEnum.LatestBlogPosts:
      return (
        <BlogLatestPostsSettings entity={entity as LatestBlogPostsInterface} />
      )
    case EntityTypeEnum.BlogCategoryTitle:
      return (
        <BaseTextEntitySettings entity={entity as BlogCategoryTitleInterface} />
      )
    case EntityTypeEnum.BlogCategoryDescription:
      return (
        <BaseTextEntitySettings
          entity={entity as BlogCategoryDescriptionInterface}
        />
      )
    case EntityTypeEnum.ContactUs:
      return <ContactUsSettings entity={entity as ContactUsInterface} />
    case EntityTypeEnum.Button:
      return <ButtonSettingsNew entity={entity as ButtonInterface} />
    case EntityTypeEnum.ContactUsButton:
      return <ButtonSettings entity={entity as ContactUsButtonInterface} />
    case EntityTypeEnum.ContactUsField:
      return <ContactUsFieldSettings entity={entity as FieldInterface} />
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return <RecaptchaSettings entity={entity as RecaptchaInterface} />
    case EntityTypeEnum.Attachments:
      return <AttachmentsSettings entity={entity as AttachmentsInterface} />
    case EntityTypeEnum.LatestBlogPostsCarousel:
      return (
        <LatestBlogPostsCarouselSettings
          entity={entity as LatestBlogPostsCarouselInterface}
        />
      )
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForBlogPage
