import {
  blogPostListingCategoriesInterface,
  blogPostListingDateInterface,
  blogPostListingDescriptionInterface,
  blogPostListingImageInterface,
  blogPostListingPaginationInterface,
  blogPostListingTitleInterface,
} from 'common/types/entities/BlogPostListingInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { FullBorderRadiusType } from 'common/types/styleTypes'

export function migrateImageBoxShadow(
  newEntity: blogPostListingImageInterface,
  originalEntity: OldEntityInterface,
) {
  //boxShadow
  if (
    originalEntity.options.imageStyles &&
    originalEntity.options.imageStyles.boxShadow
  ) {
    newEntity.imageBoxShadow = originalEntity.options.imageStyles.boxShadow
  }

  //mobileBoxShadow
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.imageStyles &&
    originalEntity.mobileOptions.imageStyles.boxShadow
  ) {
    newEntity.mobileImageBoxShadow =
      originalEntity.mobileOptions.imageStyles.boxShadow
  }
}

export function migrateFullImageBorderType(
  newEntity: any,
  originalEntity: OldEntityInterface,
) {
  newEntity.imageBorder = {}

  const desktopBorderRadius = {} as FullBorderRadiusType
  //borderRadius
  if (
    originalEntity.options.imageStyles &&
    originalEntity.options.imageStyles.borderTopLeftRadius &&
    !isNaN(parseInt(originalEntity.options.imageStyles.borderTopLeftRadius))
  ) {
    desktopBorderRadius.borderTopLeftRadius = parseInt(
      originalEntity.options.imageStyles.borderTopLeftRadius,
    )
  }

  if (
    originalEntity.options.imageStyles &&
    originalEntity.options.imageStyles.borderTopRightRadius &&
    !isNaN(parseInt(originalEntity.options.imageStyles.borderTopRightRadius))
  ) {
    desktopBorderRadius.borderTopRightRadius = parseInt(
      originalEntity.options.imageStyles.borderTopRightRadius,
    )
  }
  if (
    originalEntity.options.imageStyles &&
    originalEntity.options.imageStyles.borderBottomLeftRadius &&
    !isNaN(parseInt(originalEntity.options.imageStyles.borderBottomLeftRadius))
  ) {
    desktopBorderRadius.borderBottomLeftRadius = parseInt(
      originalEntity.options.imageStyles.borderBottomLeftRadius,
    )
  }
  if (
    originalEntity.options.imageStyles &&
    originalEntity.options.imageStyles.borderBottomRightRadius &&
    !isNaN(parseInt(originalEntity.options.imageStyles.borderBottomRightRadius))
  ) {
    desktopBorderRadius.borderBottomRightRadius = parseInt(
      originalEntity.options.imageStyles.borderBottomRightRadius,
    )
  }
  if (Object.keys(desktopBorderRadius).length > 0) {
    if (!newEntity.imageBorder) {
      newEntity.border = {}
    }
    newEntity.imageBorder.radius = desktopBorderRadius
  }

  if (
    !originalEntity.mobileOptions ||
    !originalEntity.mobileOptions.imageStyles
  ) {
    return
  }
  // MOBILE BORDER
  newEntity.mobileImageBorder = {}

  const mobileBorderRadius = {} as FullBorderRadiusType
  // const mobileBorder = newEntity.mobileBorder as Partial<BorderType>
  // const mobileBorderRadius = mobileBorder.radius as FullBorderRadiusType
  //borderRadius
  if (
    originalEntity.mobileOptions.imageStyles.borderTopLeftRadius &&
    !isNaN(
      parseInt(originalEntity.mobileOptions.imageStyles.borderTopLeftRadius),
    )
  ) {
    mobileBorderRadius.borderTopLeftRadius = parseInt(
      originalEntity.mobileOptions.imageStyles.borderTopLeftRadius,
    )
  }
  if (
    originalEntity.mobileOptions.imageStyles.borderTopRightRadius &&
    !isNaN(
      parseInt(originalEntity.mobileOptions.imageStyles.borderTopRightRadius),
    )
  ) {
    mobileBorderRadius.borderTopRightRadius = parseInt(
      originalEntity.mobileOptions.imageStyles.borderTopRightRadius,
    )
  }
  if (
    originalEntity.mobileOptions.imageStyles.borderBottomLeftRadius &&
    !isNaN(
      parseInt(originalEntity.mobileOptions.imageStyles.borderBottomLeftRadius),
    )
  ) {
    mobileBorderRadius.borderBottomLeftRadius = parseInt(
      originalEntity.mobileOptions.imageStyles.borderBottomLeftRadius,
    )
  }
  if (
    originalEntity.mobileOptions.imageStyles.borderBottomRightRadius &&
    !isNaN(
      parseInt(
        originalEntity.mobileOptions.imageStyles.borderBottomRightRadius,
      ),
    )
  ) {
    mobileBorderRadius.borderBottomRightRadius = parseInt(
      originalEntity.mobileOptions.imageStyles.borderBottomRightRadius,
    )
  }

  if (Object.keys(mobileBorderRadius).length > 0) {
    if (!newEntity.mobileImageBorder) {
      newEntity.mobileImageBorder = {}
    }
    newEntity.mobileImageBorder.radius = mobileBorderRadius
  }
}
export function migrateTitleSettings(
  newEntity: blogPostListingTitleInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.options.titleStyles.fontSize &&
    !isNaN(parseInt(originalEntity.options.titleStyles.fontSize))
  ) {
    newEntity.titleFontSize = parseInt(
      originalEntity.options.titleStyles.fontSize,
    )
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.titleStyles.fontSize))
  ) {
    newEntity.mobileTitleFontSize = parseInt(
      originalEntity.mobileOptions.titleStyles.fontSize,
    )
  }

  //lineHeight
  if (
    originalEntity.options.titleStyles.lineHeight &&
    !isNaN(parseInt(originalEntity.options.titleStyles.lineHeight))
  ) {
    newEntity.titleLineHeight = parseInt(
      originalEntity.options.titleStyles.lineHeight,
    )
  }

  //mobileLineHeight
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.lineHeight &&
    !isNaN(parseInt(originalEntity.mobileOptions.titleStyles.lineHeight))
  ) {
    newEntity.mobileTitleLineHeight = parseInt(
      originalEntity.mobileOptions.titleStyles.lineHeight,
    )
  }

  if (originalEntity.options.titleStyles.fontFamily) {
    newEntity.titleFontFamily = originalEntity.options.titleStyles.fontFamily
  }
  if (originalEntity.options.titleStyles.fontWeight) {
    newEntity.titleFontWeight = originalEntity.options.titleStyles.fontWeight
  }
  if (originalEntity.options.titleStyles.fontStyle) {
    newEntity.titleFontStyle = originalEntity.options.titleStyles.fontStyle
  }
  //mobileText
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.fontFamily
  ) {
    newEntity.mobileTitleFontFamily = originalEntity.mobileStyles.fontFamily
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.fontWeight
  ) {
    newEntity.mobileTitleFontWeight = originalEntity.mobileStyles.fontWeight
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.fontStyle
  ) {
    newEntity.mobileTitleFontStyle = originalEntity.mobileStyles.fontStyle
  }
  //Letter spacing
  if (
    originalEntity.options.titleStyles.letterSpacing &&
    !isNaN(parseInt(originalEntity.options.titleStyles.letterSpacing))
  ) {
    newEntity.titleLetterSpacing = parseInt(
      originalEntity.options.titleStyles.letterSpacing,
    )
  }
  //Mobile Letter spacing
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.letterSpacing &&
    !isNaN(parseInt(originalEntity.mobileOptions.titleStyles.letterSpacing))
  ) {
    newEntity.mobileTitleLetterSpacing = parseInt(
      originalEntity.mobileOptions.titleStyles.letterSpacing,
    )
  }

  //align
  if (originalEntity.options.titleStyles.textAlign) {
    newEntity.titleAlign = originalEntity.options.titleStyles.textAlign
  }
  //mobileAlign
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.textAlign
  ) {
    newEntity.mobileTitleAlign =
      originalEntity.mobileOptions.titleStyles.textAlign
  }

  //textColor
  if (originalEntity.options.titleStyles.color) {
    newEntity.titleColor = originalEntity.options.titleStyles.color
  }

  //mobileTextColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.titleStyles.color
  ) {
    newEntity.mobileTitleColor = originalEntity.mobileOptions.titleStyles.color
  }
}

export function migrateDescriptionSettings(
  newEntity: blogPostListingDescriptionInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.options.descriptionStyles.fontSize &&
    !isNaN(parseInt(originalEntity.options.descriptionStyles.fontSize))
  ) {
    newEntity.descriptionFontSize = parseInt(
      originalEntity.options.descriptionStyles.fontSize,
    )
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.descriptionStyles.fontSize))
  ) {
    newEntity.mobileDescriptionFontSize = parseInt(
      originalEntity.mobileOptions.descriptionStyles.fontSize,
    )
  }

  //lineHeight
  if (
    originalEntity.options.descriptionStyles.lineHeight &&
    !isNaN(parseInt(originalEntity.options.descriptionStyles.lineHeight))
  ) {
    newEntity.descriptionLineHeight = parseInt(
      originalEntity.options.descriptionStyles.lineHeight,
    )
  }

  //mobileLineHeight
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.lineHeight &&
    !isNaN(parseInt(originalEntity.mobileOptions.descriptionStyles.lineHeight))
  ) {
    newEntity.mobileDescriptionLineHeight = parseInt(
      originalEntity.mobileOptions.descriptionStyles.lineHeight,
    )
  }

  if (originalEntity.options.descriptionStyles.fontFamily) {
    newEntity.descriptionFontFamily =
      originalEntity.options.descriptionStyles.fontFamily
  }
  if (originalEntity.options.descriptionStyles.fontWeight) {
    newEntity.descriptionFontWeight =
      originalEntity.options.descriptionStyles.fontWeight
  }
  if (originalEntity.options.descriptionStyles.fontStyle) {
    newEntity.descriptionFontStyle =
      originalEntity.options.descriptionStyles.fontStyle
  }
  //mobileText
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.fontFamily
  ) {
    newEntity.mobileDescriptionFontFamily =
      originalEntity.mobileStyles.fontFamily
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.fontWeight
  ) {
    newEntity.mobileDescriptionFontWeight =
      originalEntity.mobileStyles.fontWeight
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.fontStyle
  ) {
    newEntity.mobileDescriptionFontStyle = originalEntity.mobileStyles.fontStyle
  }
  //align
  if (originalEntity.options.descriptionStyles.textAlign) {
    newEntity.descriptionAlign =
      originalEntity.options.descriptionStyles.textAlign
  }
  //mobileAlign
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.textAlign
  ) {
    newEntity.mobileDescriptionAlign =
      originalEntity.mobileOptions.descriptionStyles.textAlign
  }

  //textColor
  if (originalEntity.options.descriptionStyles.color) {
    newEntity.descriptionColor = originalEntity.options.descriptionStyles.color
  }

  //mobileTextColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.descriptionStyles.color
  ) {
    newEntity.mobileDescriptionColor =
      originalEntity.mobileOptions.descriptionStyles.color
  }
}

export function migrateCategoriesSettings(
  newEntity: blogPostListingCategoriesInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.options.categoriesStyles.fontSize &&
    !isNaN(parseInt(originalEntity.options.categoriesStyles.fontSize))
  ) {
    newEntity.categoriesFontSize = parseInt(
      originalEntity.options.categoriesStyles.fontSize,
    )
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.categoriesStyles.fontSize))
  ) {
    newEntity.mobileCategoriesFontSize = parseInt(
      originalEntity.mobileOptions.categoriesStyles.fontSize,
    )
  }

  if (originalEntity.options.categoriesStyles.fontFamily) {
    newEntity.categoriesFontFamily =
      originalEntity.options.categoriesStyles.fontFamily
  }
  if (originalEntity.options.categoriesStyles.fontWeight) {
    newEntity.categoriesFontWeight =
      originalEntity.options.categoriesStyles.fontWeight
  }
  if (originalEntity.options.categoriesStyles.fontStyle) {
    newEntity.categoriesFontStyle =
      originalEntity.options.categoriesStyles.fontStyle
  }
  //mobileText
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.fontFamily
  ) {
    newEntity.mobileCategoriesFontFamily =
      originalEntity.mobileStyles.fontFamily
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.fontWeight
  ) {
    newEntity.mobileCategoriesFontWeight =
      originalEntity.mobileStyles.fontWeight
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.fontStyle
  ) {
    newEntity.mobileCategoriesFontStyle = originalEntity.mobileStyles.fontStyle
  }
  //align
  if (originalEntity.options.categoriesStyles.textAlign) {
    newEntity.categoriesAlign =
      originalEntity.options.categoriesStyles.textAlign
  }
  //mobileAlign
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.textAlign
  ) {
    newEntity.mobileCategoriesAlign =
      originalEntity.mobileOptions.categoriesStyles.textAlign
  }

  //textColor
  if (originalEntity.options.categoriesStyles.color) {
    newEntity.categoriesColor = originalEntity.options.categoriesStyles.color
  }

  //mobileTextColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.categoriesStyles.color
  ) {
    newEntity.mobileCategoriesColor =
      originalEntity.mobileOptions.categoriesStyles.color
  }
}

export function migrateDateSettings(
  newEntity: blogPostListingDateInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.options.dateStyles.fontSize &&
    !isNaN(parseInt(originalEntity.options.dateStyles.fontSize))
  ) {
    newEntity.dateFontSize = parseInt(
      originalEntity.options.dateStyles.fontSize,
    )
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.dateStyles.fontSize))
  ) {
    newEntity.mobileDateFontSize = parseInt(
      originalEntity.mobileOptions.dateStyles.fontSize,
    )
  }

  if (originalEntity.options.dateStyles.fontFamily) {
    newEntity.dateFontFamily = originalEntity.options.dateStyles.fontFamily
  }
  if (originalEntity.options.dateStyles.fontWeight) {
    newEntity.dateFontWeight = originalEntity.options.dateStyles.fontWeight
  }
  if (originalEntity.options.dateStyles.fontStyle) {
    newEntity.dateFontStyle = originalEntity.options.dateStyles.fontStyle
  }
  //mobileText
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.fontFamily
  ) {
    newEntity.mobileDateFontFamily = originalEntity.mobileStyles.fontFamily
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.fontWeight
  ) {
    newEntity.mobileDateFontWeight = originalEntity.mobileStyles.fontWeight
  }
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.fontStyle
  ) {
    newEntity.mobileDateFontStyle = originalEntity.mobileStyles.fontStyle
  }
  //align
  if (originalEntity.options.dateStyles.textAlign) {
    newEntity.dateAlign = originalEntity.options.dateStyles.textAlign
  }
  //mobileAlign
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.textAlign
  ) {
    newEntity.mobileDateAlign =
      originalEntity.mobileOptions.dateStyles.textAlign
  }

  //textColor
  if (originalEntity.options.dateStyles.color) {
    newEntity.dateColor = originalEntity.options.dateStyles.color
  }

  //mobileTextColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.dateStyles.color
  ) {
    newEntity.mobileDateColor = originalEntity.mobileOptions.dateStyles.color
  }
}

export function migratePaginationSettings(
  newEntity: blogPostListingPaginationInterface,
  originalEntity: OldEntityInterface,
) {
  if (!originalEntity.options.paginationStyles) {
    return
  }
  //ACtiveColor
  if (originalEntity.options.paginationStyles.activeColor) {
    newEntity.paginationActiveColor =
      originalEntity.options.paginationStyles.activeColor
  }
  if (originalEntity.options.paginationStyles.activeBackgroundColor) {
    newEntity.paginationActiveBackgroundColor =
      originalEntity.options.paginationStyles.activeBackgroundColor
  }
  if (originalEntity.options.paginationStyles.passiveColor) {
    newEntity.paginationInactiveColor =
      originalEntity.options.paginationStyles.passiveColor
  }
  if (originalEntity.options.paginationStyles.passiveBackgroundColor) {
    newEntity.paginationInactiveBackgroundColor =
      originalEntity.options.paginationStyles.passiveBackgroundColor
  }
}

export function migrateMobilePaginationSettings(
  newEntity: blogPostListingPaginationInterface,
  originalEntity: OldEntityInterface,
) {
  if (
    !originalEntity.mobileOptions ||
    !originalEntity.mobileOptions.paginationStyles
  ) {
    return
  }
  //ACtiveColor
  if (originalEntity.mobileOptions.paginationStyles.activeColor) {
    newEntity.mobilePaginationActiveColor =
      originalEntity.mobileOptions.paginationStyles.activeColor
  }
  if (originalEntity.mobileOptions.paginationStyles.activeBackgroundColor) {
    newEntity.mobilePaginationActiveBackgroundColor =
      originalEntity.mobileOptions.paginationStyles.activeBackgroundColor
  }
  if (originalEntity.mobileOptions.paginationStyles.passiveColor) {
    newEntity.mobilePaginationInactiveColor =
      originalEntity.mobileOptions.paginationStyles.passiveColor
  }
  if (originalEntity.mobileOptions.paginationStyles.passiveBackgroundColor) {
    newEntity.mobilePaginationInactiveBackgroundColor =
      originalEntity.mobileOptions.paginationStyles.passiveBackgroundColor
  }
}
