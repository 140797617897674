import React from 'react'
import { Appearance } from 'common/types/entities/EntityInterface'
import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'
import { SettingsProps } from 'client/components/core/Sidebar/components/Settings/entities'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import MarginStyle from '../styles/MarginStyle'

function NewHorizontalLineSettings({
  entity,
}: SettingsProps<HorizontalLineInterface>) {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<HorizontalLineInterface>()
  const updateNestedProp = useUpdateNestedProp(entity)

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <React.Fragment>
      <Border
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        withoutType
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default NewHorizontalLineSettings
