import { migrateReadOnly } from 'tools/commands/migrate/utils/migrateUtils'
import {
  migrateFontSettings,
  migrateFontSize,
  migrateLineHeight,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createCheckbox } from 'client/components/core/Sidebar/components/Settings/entities/CheckboxSettings/utils/create-checkbox'

export function migrateCheckbox(entity: OldEntityInterface) {
  const checkbox = createCheckbox(
    entity.parentId,
    JSON.stringify(entity.options.rawContentState),
    entity.masterBlockId,
  )

  if (entity.options.rawContentState) {
    checkbox.rawContentState = entity.options.rawContentState
  }

  if (entity.childIds && entity.childIds.length !== 0) {
    checkbox.childIds = entity.childIds
  }

  checkbox.id = entity.id
  checkbox.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    checkbox.appearance.desktop = entity.options.appearance.desktop
    checkbox.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(checkbox, entity)
  migrateMobileMargin(checkbox, entity)
  migrateFontSettings(checkbox, entity)
  migrateFontSize(checkbox, entity)
  migrateLineHeight(checkbox, entity)
  migrateReadOnly(checkbox, entity)

  if (entity.styles.padding) {
    checkbox.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    checkbox.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  //color
  if (entity.styles.color) {
    checkbox.color = entity.styles.color
  }

  if (entity.mobileStyles && entity.mobileStyles.color) {
    checkbox.mobileColor = entity.mobileStyles.color
  }

  if (entity.options.errorText) {
    checkbox.errorText = entity.options.errorText
  }

  if (entity.options.textAlign) {
    checkbox.textAlign = entity.styles.textAlign
  }

  if (entity.mobileOptions.textAlign) {
    checkbox.mobileTextAlign = entity.mobileStyles.textAlign
  }

  checkbox.optional = entity.options.optional ?? false

  return checkbox
}
