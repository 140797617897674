import { match } from 'path-to-regexp'

export default function usePageRouteDetector() {
  const checkPattern = uri => match(uri, [])(window.location.pathname)
  const { params } = checkPattern('/page/:id/edit')
  let pageId
  let isTemplate = false
  if (params && params.id && Number.isInteger(+params.id)) {
    pageId = params.id
  } else {
    const { params } = checkPattern('/page/template/:id/edit')
    if (params && params.id) {
      pageId = params.id
      isTemplate = true
    }
  }

  return { pageId, isTemplate }
}
