import PropTypes from 'prop-types'
import React, { memo } from 'react'
import styled from 'styled-components'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import OptionWrapper from '../styleComponents/OptionWrapper'
import MobileIconUi from '../styles/ui/MobileIconUi'

const ALIGN_LEFT = 'flex-start'
const ALIGN_CENTER = 'center'
const ALIGN_RIGHT = 'flex-end'

const AlignButton = styled.button`
  width: 33.3%;
  float: left;
  margin-bottom: 0;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  color: ${({ active }) => (active ? '#707e8d' : '#777')};
  background-color: ${({ active }) => (active ? '#ebeef1' : '#f9f9f9')};
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    background-color: #ebeef1;
    color: #707e8d;
  }
`

function FlexAlignStyle(props) {
  const {
    update,
    mobileUpdate,
    mobileAlign = 'center',
    align,
    label = 'settings_styles.align.label',
  } = props
  const { isMobile } = useDeviceModeContext()

  function getAlign() {
    return isMobile ? mobileAlign : align
  }

  function updateAlign(newAlign) {
    isMobile ? mobileUpdate(newAlign) : update(newAlign)
  }

  return (
    <OptionWrapper labelText={label} labelIcon={isMobile && <MobileIconUi />}>
      <AlignButton
        onClick={() => updateAlign(ALIGN_LEFT)}
        active={ALIGN_LEFT === getAlign()}
      >
        <span className="fas fa-align-left" />
      </AlignButton>
      <AlignButton
        onClick={() => updateAlign(ALIGN_CENTER)}
        active={ALIGN_CENTER === getAlign()}
      >
        <span className="fas fa-align-center" />
      </AlignButton>
      <AlignButton
        onClick={() => updateAlign(ALIGN_RIGHT)}
        active={ALIGN_RIGHT === getAlign()}
      >
        <span className="fas fa-align-right" />
      </AlignButton>
    </OptionWrapper>
  )
}

FlexAlignStyle.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  mobileAlign: PropTypes.string,
  update: PropTypes.func,
  mobileUpdate: PropTypes.func.isRequired,
}

export default memo(FlexAlignStyle)
