import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateCommand } from '../utils/migrateCommand'
import { migrateRawHtml } from './migrateRawHtml'

export default function (page: ServerPage): [ServerPage, boolean] {
  return migrateCommand({
    page,
    structureType: structureTypes.RAW_HTML,
    migrateFunction: migrateRawHtml,
  })
}
