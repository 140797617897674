import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import * as managementActions from 'client/actions/actionsManagement'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { AsyncCodeEditor } from '../../../../CodeEditor'
import { Button, FileUpload } from '../components'
import BooleanNew from '../components/BooleanNew'
import Input from '../components/Input/Input'
import ReactSelect, {
  ChangeOptionWithStringValue,
} from '../components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import { DelayOption } from '../options'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import { ShadowStyle } from '../styles'
import Border from '../styles/Border'
import MarginStyle from '../styles/MarginStyle'

enum VideoType {
  Youtube = 'youtube',
  Custom = 'custom',
  File = 'file',
  Url = 'url',
}

const videoTypesNames = {
  [VideoType.Youtube]: 'entity_settings.video.source_type.youtube',
  [VideoType.Custom]: 'entity_settings.video.source_type.custom_embed',
  [VideoType.File]: 'entity_settings.video.source_type.upload_file',
  [VideoType.Url]: 'entity_settings.video.source_type.direct_link',
}

function detectVideoType(entity: VideoInterface) {
  if (entity.fileId) {
    return VideoType.File
  }

  if (entity.embedCode) {
    return VideoType.Custom
  }

  return VideoType.Url
}

function VideoSettingsNew({ entity }: { entity: VideoInterface }) {
  const [videoType, setVideoType] = useState(detectVideoType(entity))
  const [CodeEditor, setCodeEditor] = useState<AsyncCodeEditor | null>(null)
  const isCodeDialogPortalAllowed = useManagement(
    managementSelectors.isCodeDialogPortalAllowed,
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<VideoInterface>()
  const updateNestedProp = useUpdateNestedProp(entity)
  const { isMobile } = useDeviceModeContext()

  function getOption(videoType: VideoType) {
    return {
      value: videoType,
      label: t(videoTypesNames[videoType]),
    }
  }

  const loadCodeEditor = async () => {
    const { default: codeEditor } = await import(
      /* webpackChunkName: "code-editor" */ 'client/components/core/CodeEditor'
    )

    setCodeEditor(() => codeEditor)
  }

  const openCodeEditor = async () => {
    if (!CodeEditor) {
      await loadCodeEditor()
    }
    dispatch(managementActions.showCodeDialog())
  }

  function updateVideoType(option: ChangeOptionWithStringValue) {
    if (!option) {
      return
    }

    const updatedEntity = { ...entity }
    switch (option.value) {
      case VideoType.Url:
        delete updatedEntity.fileId
        delete updatedEntity.embedCode
        delete updatedEntity.posterFileId
        break
      case VideoType.File:
        delete updatedEntity.url
        delete updatedEntity.embedCode
        break
      case VideoType.Custom:
        delete updatedEntity.url
        delete updatedEntity.fileId
        delete updatedEntity.posterFileId
        break
      default:
    }
    updateEntity(updatedEntity)
    setVideoType(option.value as VideoType)
  }

  function removePosterFileId() {
    const updatedEntity = { ...entity }
    delete updatedEntity.posterFileId
    updateEntity(updatedEntity)
  }

  return (
    <>
      <ReactSelect<string>
        selectedOption={getOption(videoType)}
        update={updateVideoType}
        labelText="entity_settings.video.type.label"
        options={[
          getOption(VideoType.Url),
          getOption(VideoType.Custom),
          getOption(VideoType.File),
        ]}
        isMulti={false}
      />
      {videoType === VideoType.Custom && (
        <>
          <Button
            small
            onClick={openCodeEditor}
            helpMessage="entity_settings.raw_html.description"
          >
            entity_settings.raw_html.edit_label
          </Button>
          {isCodeDialogPortalAllowed && CodeEditor && (
            <CodeEditor
              label="entity_settings.raw_html.label"
              value={entity.embedCode}
              handleChange={updateProp('embedCode')}
            />
          )}
        </>
      )}
      {videoType === VideoType.Url && (
        <Input
          label="entity_settings.video.type.url.label"
          value={entity.url}
          update={updateProp('url')}
        />
      )}
      {videoType === VideoType.File && (
        <FileUpload
          fileId={entity.fileId}
          mimeTypes={mimeTypes.video}
          afterUpload={(file: any) => updateProp('fileId')(file.id)}
          fileType="video"
          helpMessage="entity_settings.video.upload.help_message"
          label="video"
        />
      )}
      {videoType !== VideoType.Custom && (
        <>
          <BooleanNew
            labelText="entity_settings.video.auto_play.label"
            value={entity.autoplay}
            update={updateProp('autoplay')}
          />
          <BooleanNew
            labelText="entity_settings.video.controls.label"
            value={entity.controls}
            update={updateProp('controls')}
          />
        </>
      )}
      {videoType === VideoType.File && (
        <FileUpload
          fileId={entity.posterFileId}
          mimeTypes={mimeTypes.image}
          afterUpload={(file: any) => updateProp('posterFileId')(file.id)}
          fileType="image"
          removeFile={removePosterFileId}
          label="entity_settings.video.upload.poster.label"
          helpMessage="entity_settings.video.upload.poster_help_message"
        />
      )}
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Border
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <DeviceAppearance
        update={appearance =>
          updateEntity({
            ...entity,
            appearance,
          })
        }
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default VideoSettingsNew
