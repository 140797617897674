import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaypalBackgroundColorType } from 'common/components/entities/paypal-rest-button'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import ColorPickerWithGradient from 'client/components/core/Sidebar/components/Settings/components/ColorPickerWithGradient'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { Tooltip } from 'client/components/core/tooltip'
import useManagement from 'client/hooks/useManagement'
import { PaypalBackgroundColor } from 'client/pages/offer/settings/paypal-button-settings'
import { isPaypalSettingsOpened } from 'client/reducers/managementReducer'
import { usePayment } from 'client/store'
import { togglePayPalSettings } from 'client/store/management/managementActions'
import { isPaymentMethodAvailable } from 'client/store/payment/paymentSelectors'
import ButtonIconPicker from '../../../components/core/Sidebar/components/Settings/components/ButtonIconPicker'
import HoverButtonSetting from '../../../components/core/Sidebar/components/Settings/components/HoverButton'
import Input from '../../../components/core/Sidebar/components/Settings/components/Input'
import { SettingsProps } from '../../../components/core/Sidebar/components/Settings/entities'
import {
  useUpdateEntity,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import GroupTitle from '../../../components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  LineHeight,
  ShadowStyle,
} from '../../../components/core/Sidebar/components/Settings/styles'
import Border from '../../../components/core/Sidebar/components/Settings/styles/Border'
import ButtonWidth from '../../../components/core/Sidebar/components/Settings/styles/ButtonWidth'
import FlexAlignStyle from '../../../components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'
import { useAppDispatch } from '../../../hooks/use-dispatch'

function UpsellButtonSettings({
  entity,
}: SettingsProps<UpsellButtonInterface>) {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<UpsellButtonInterface>()
  const removeBackgroundColorProperty = () => {
    const updatedEntity = { ...entity }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isMobile
      ? delete updatedEntity.mobileBackground
      : //@ts-expect-error background for updatedEntity is required
        delete updatedEntity.background

    updateEntity(updatedEntity)
  }

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const isPaypalAvailable = usePayment(state =>
    isPaymentMethodAvailable(state, PaymentMethodEnum.PaypalRest),
  )

  const previewPaypalSettings = useManagement(isPaypalSettingsOpened)

  const updatePaypalBackgroundColor = (color: PaypalBackgroundColorType) => {
    const updatedEntity = { ...entity }
    if (!updatedEntity.paypal) updatedEntity.paypal = {}
    updatedEntity.paypal = { ...entity.paypal, backgroundColor: color }
    updateEntity(updatedEntity)
  }

  return (
    <>
      {entity.type === EntityTypeEnum.UpsellAgreeButton &&
        isPaypalAvailable && (
          <>
            <GroupTitle
              style={{ display: 'flex', alignItems: 'center' }}
              afterChildren={
                <Tooltip
                  isFlex
                  message={t(
                    'entity_settings.payment_paypal.limitation_tooltip',
                  )}
                />
              }
            >
              entity_settings.payment_paypal.group_title
            </GroupTitle>
            <NewCheckbox
              labelText="entity_settings.paypal.preview_button"
              helpMessage={
                'entity_settings.payment_paypal.preview_button_tooltip'
              }
              checked={previewPaypalSettings}
              update={value => dispatch(togglePayPalSettings(value))}
            />
            <PaypalBackgroundColor
              color={entity.paypal?.backgroundColor}
              update={updatePaypalBackgroundColor}
            />
            <GroupTitle>entity_settings.payment_common.group_title</GroupTitle>
          </>
        )}
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <ButtonWidth
        update={updateProp('width')}
        mobileUpdate={updateProp('mobileWidth')}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
      />
      <ColorPickerWithGradient
        update={updateProp('background')}
        mobileUpdate={updateProp('mobileBackground')}
        color={entity.background}
        mobileColor={entity.mobileBackground}
        label="settings_styles.background_color.label"
        removeBackgroundColorProperty={removeBackgroundColorProperty}
      />
      <ButtonIconPicker
        valueBefore={entity.iconClassNameBefore}
        valueAfter={entity.iconClassNameAfter}
        updateBefore={updateProp('iconClassNameBefore')}
        updateAfter={updateProp('iconClassNameAfter')}
      />
      <GroupTitle>entity_settings.button.text.group_title</GroupTitle>
      <Input
        labelText="entity_settings.button.text.label"
        value={entity.text}
        update={updateProp('text')}
        placeholder=""
        type="text"
        helpMessage=""
      />
      <FontSize
        update={updateProp('textFontSize')}
        mobileUpdate={updateProp('mobileTextFontSize')}
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
      />
      <TextFontUpdater<UpsellButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'textFontFamily'}
        fontWeightKey={'textFontWeight'}
        fontStyleKey={'textFontStyle'}
        mobileFontFamilyKey={'mobileTextFontFamily'}
        mobileFontWeightKey={'mobileTextFontWeight'}
        mobileFontStyleKey={'mobileTextFontStyle'}
        fontFileIdKey={'textFontFileId'}
        mobileFontFileIdKey={'mobileTextFontFileId'}
      />
      <ColorPicker
        update={updateProp('textColor')}
        mobileUpdate={updateProp('mobileTextColor')}
        color={entity.textColor}
        mobileColor={entity.mobileTextColor}
      />
      <GroupTitle>entity_settings.button.sub_text.group_title</GroupTitle>
      <Input
        labelText="entity_settings.button.sub_text.label"
        value={entity.subText}
        update={updateProp('subText')}
        placeholder=""
        type="text"
        helpMessage=""
      />
      <FontSize
        update={updateProp('subTextFontSize')}
        mobileUpdate={updateProp('mobileSubTextFontSize')}
        fontSize={entity.subTextFontSize}
        mobileFontSize={entity.mobileSubTextFontSize}
      />
      <TextFontUpdater<UpsellButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'subTextFontFamily'}
        fontWeightKey={'subTextFontWeight'}
        fontStyleKey={'subTextFontStyle'}
        mobileFontFamilyKey={'mobileSubTextFontFamily'}
        mobileFontWeightKey={'mobileSubTextFontWeight'}
        mobileFontStyleKey={'mobileSubTextFontStyle'}
        fontFileIdKey={'subTextFontFileId'}
        mobileFontFileIdKey={'mobileSubTextFontFileId'}
      />
      <ColorPicker
        update={updateProp('subTextColor')}
        mobileUpdate={updateProp('subTextColor')}
        color={entity.subTextColor}
        mobileColor={entity.mobileSubTextColor}
      />
      <HoverButtonSetting
        update={updateProp('hover')}
        mobileUpdate={updateProp('mobileHover')}
        hover={entity?.hover}
        mobileHover={entity?.mobileHover}
        backgroundColor={entity.background ?? entity?.backgroundColor}
        textColor={entity?.textColor}
        subTextColor={entity?.subTextColor}
      />
      <Border
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default UpsellButtonSettings
