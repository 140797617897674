import structureTypes from 'common/constants/structureTypes'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import ServerPage from 'server/types/ServerPageInterface'
import { migratePaymentMethod } from './migratePaymentMethod'

export default function (page: ServerPage): [ServerPage, boolean] {
  let isChanged = false
  Object.values(page.content.entities).forEach(entity => {
    if (entity.type === structureTypes.PAYMENT_METHOD) {
      isChanged = true
      page.content.entities[entity.id] = migratePaymentMethod(
        entity as OldEntityInterface,
      )
    }
  })

  return [page, isChanged]
}
