import React from 'react'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import Input from '../components/Input/Input'
import GroupTitle from '../styleComponents/GroupTitle'

type DelayOptionProps = {
  delay?: number | string
  update: (value: number) => void
}

const DelayOption = ({ delay = 0, update }: DelayOptionProps) => {
  const pageType = usePage(pageSelectors.getPageType)
  // looks dirty, but we the delay option breaks the display of a popup and an inline
  if (pageType === PageTypeEnum.Popup || pageType === PageTypeEnum.Inline) {
    return null
  }

  return (
    <>
      <GroupTitle>settings_options.delay.label</GroupTitle>
      <Input
        update={val => update(parseInt(val))}
        placeholder="settings_options.delay.placeholder"
        value={delay}
        type="number"
      />
    </>
  )
}

export default DelayOption
