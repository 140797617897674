import React from 'react'
import { useTranslation } from 'react-i18next'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { RemotePopupBodyInterface } from 'common/types/remote-popup-body-interface'
import { getMobileBackgroundFileId } from 'common/utils/getMobileBackgroundFileId'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getRootEntity } from 'client/reducers/pageReducer'
import { usePage } from 'client/store'
import { updateEntity } from 'client/store/page/pageActions'
import { getFirstEntityByType } from 'client/store/page/pageSelectors'
import { Checkbox } from '../components'
import ColorPicker from '../components/ColorPicker'
import ReactSelect from '../components/ReactSelect/ReactSelect'
import { useUpdateProp } from '../hooks/useUpdateProps'
import { LanguageSelector } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { BackgroundPositionStyleNew, ShadowStyle } from '../styles'
import Border from '../styles/Border'
import PaddingStyle from '../styles/SplitPaddingStyle'
import PopupFormSettingsOld from './PopupFormSettings'
import Typography from './components/Typography'
import Range from '../components/Range/Range'
import { useAppDispatch } from 'client/hooks/use-dispatch'

export const RemotePopupSettingsResolver = () => {
  const isMigratedRemotePopup = usePage(page =>
    getFirstEntityByType(page, EntityTypeEnum.RemotePopupBody),
  )

  if (isMigratedRemotePopup) {
    return <RemotePopupFormSettings />
  }
  return <PopupFormSettingsOld />
}

export const RemotePopupFormSettings = () => {
  // @description: for RemotePopupBody
  const { t } = useTranslation()
  const entity: RemotePopupBodyInterface = usePage(getRootEntity)

  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()

  const dispatch = useAppDispatch()

  const selectOptions = [
    { value: true, label: t('ON') },
    { value: false, label: t('OFF') },
  ]

  const toggleOpenOnExit = (option: (typeof selectOptions)[number] | null) => {
    const updatedEntity = { ...entity }
    if (!option?.value) {
      updatedEntity.openOnMobileAutomatically = false
      delete updatedEntity.mobileDelay
    }
    updatedEntity.openOnExit = !!option?.value
    dispatch(
      updateEntity({
        ...updatedEntity,
      }),
    )
  }

  const toggleDelayOption = (value: boolean) => {
    const updatedEntity = { ...entity }
    if (value) {
      updatedEntity.closedDays = 1
    } else {
      delete updatedEntity.closedDays
    }
    dispatch(updateEntity(updatedEntity))
  }

  return (
    <>
      <ReactSelect
        labelText={'show close button'}
        options={selectOptions}
        update={option => updateProp('showClose')(!!option?.value)}
        isMulti={false}
        selectedOption={entity.showClose ? selectOptions[0] : selectOptions[1]}
      />
      {entity.showClose && (
        <>
          <ColorPicker
            label={t('entity_settings.popup.color_button_close')}
            update={updateProp('closeButtonColor')}
            mobileUpdate={updateProp('mobileCloseButtonColor')}
            color={entity.closeButtonColor}
            mobileColor={entity.mobileCloseButtonColor}
          />
          <NewCheckbox
            checked={!!entity.closedDays}
            update={toggleDelayOption}
            labelText="entity_settings.popup.delay.label"
            helpMessage="entity_settings.popup.delay.help_message"
          />
        </>
      )}
      {entity.showClose && !!entity.closedDays && (
        <Range
          label={t('entity_settings.popup.reopen_in_x_days', {
            days: entity.closedDays || 0,
          })}
          change={updateProp('closedDays')}
          value={entity.closedDays}
          min={1}
          max={365}
          allowNegative={false}
        />
      )}
      <ReactSelect
        options={selectOptions}
        selectedOption={
          entity.openAutomatically ? selectOptions[0] : selectOptions[1]
        }
        update={option => {
          updateProp('openAutomatically')(!!option?.value)
        }}
        labelText="entity_settings.popup.open_automatically"
        isMulti={false}
      />
      {entity.openAutomatically && (
        <Range
          label={t('entity_settings.popup.open_in_x_seconds', {
            seconds: entity.delay,
          })}
          value={entity.delay}
          change={updateProp('delay')}
          min={0}
          max={120}
        />
      )}
      <ReactSelect
        selectedOption={entity.openOnExit ? selectOptions[0] : selectOptions[1]}
        options={selectOptions}
        update={toggleOpenOnExit}
        labelText="entity_settings.popup.open_on_exit"
        isMulti={false}
      />
      {entity.openOnExit && (
        <Checkbox
          labelText="entity_settings.popup.mobile_open_on_exit"
          value={entity.openOnMobileAutomatically}
          update={updateProp('openOnMobileAutomatically')}
        />
      )}
      {entity.openOnMobileAutomatically && (
        <Range
          label={t('entity_settings.popup.open_in_x_seconds', {
            seconds: entity.mobileDelay,
          })}
          value={entity.mobileDelay}
          change={(val: number) => val > 0 && updateProp('mobileDelay')(val)}
          min={0}
          max={120}
        />
      )}
      <Typography />
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        label="settings_styles.background_color.label"
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
      />
      <BackgroundPositionStyleNew
        backgroundPosition={entity.background}
        fileId={
          isMobile
            ? getMobileBackgroundFileId(
                entity.backgroundFileId,
                entity.mobileBackgroundFileId,
              )
            : entity.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateProp('mobileBackgroundFileId')
            : updateProp('backgroundFileId')
        }
        update={updateProp('background')}
      />
      <Range
        label="page_settings.body.background_blur.label"
        value={entity.blur}
        change={updateProp('blur')}
        max={10}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        border={isMobile ? entity.mobileBorder : entity.border}
        update={isMobile ? updateProp('mobileBorder') : updateProp('border')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <LanguageSelector />
    </>
  )
}
