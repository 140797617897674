import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { InlineBodyInterface } from 'common/types/inline-body-interface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getRootEntity } from 'client/reducers/pageReducer'
import { usePage } from 'client/store'
import { getFirstEntityByType } from 'client/store/page/pageSelectors'
import { useUpdateProp } from '../hooks/useUpdateProps'
import { LanguageSelector, Width } from '../options'
import { ShadowStyle } from '../styles'
import Border from '../styles/Border'
import PaddingStyle from '../styles/SplitPaddingStyle'
import InlineFormSettingsOld from './InlineFormSettings'
import Typography from './components/Typography'
import { Background } from './components/background'

export const InlineFormSettingsResolver = () => {
  const isMigratedInlineEntity = usePage(page =>
    getFirstEntityByType(page, EntityTypeEnum.InlineBody),
  )

  if (isMigratedInlineEntity) {
    return <InlineFormSettings />
  }
  return <InlineFormSettingsOld />
}

export const InlineFormSettings = () => {
  // @description: for InlineBody

  const entity: InlineBodyInterface = usePage(getRootEntity)
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()

  return (
    <>
      <Width
        width={entity.maxWidth}
        update={value => updateProp('maxWidth')(parseInt(value))}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        update={updateProp('border')}
        mobileUpdate={updateProp('mobileBorder')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Typography />
      <LanguageSelector />
      <Background<InlineBodyInterface> />
    </>
  )
}
