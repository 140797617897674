import styled, { css } from 'styled-components'
import { fontCss, FontProps, mobileFontCss } from 'common/utils/styleUtilsNew'

export const RemotePopupUi = styled.section<FontProps>`
  width: 100%;
  height: min-content; /*@see why https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container*/
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8e9091;
  ${fontCss}

  ${p => p.theme.phone} {
    ${mobileFontCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`
