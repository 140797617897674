import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ControlledTooltip from 'common/components/core/ControlledTooltip'
import structureTypes from 'common/constants/structureTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'

const IOS_ZOOM_FONT_BREAKPOINT = 16

/**
 * @param {(string|number)} [fontSize] - The font size value.
 * @param {(string|number)} [mobileFontSize] - The font size value for mobile devices.
 * @param {function} update - Function to update the font size.
 * @param {function} mobileUpdate - Function to update the mobile font size.
 * @param {string} [labelText] - Text to display as a label.
 */
function FontSize({
  fontSize = null,
  mobileFontSize = null,
  labelText = 'settings_styles.font_size.label',
  entityType,
  update,
  mobileUpdate,
}) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const timeoutRef = useRef()

  const [updateProp, currentFontSize] = isMobile
    ? [mobileUpdate, mobileFontSize]
    : [update, fontSize]

  const fontSizePixels = parseInt(currentFontSize, 10)

  const debouncedClearPopover = debounce(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => setIsOpenPopover(false), 4000)
  }, 200)

  useEffect(() => {
    if (
      isMobile &&
      entityType === structureTypes.FORM_INPUT &&
      fontSizePixels < IOS_ZOOM_FONT_BREAKPOINT
    ) {
      setIsOpenPopover(true)
      debouncedClearPopover()
    }
  }, [isMobile, fontSizePixels, entityType])

  useEffect(() => {
    if (isOpenPopover && fontSizePixels >= IOS_ZOOM_FONT_BREAKPOINT) {
      setIsOpenPopover(false)
    }

    if (!isOpenPopover && timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [isOpenPopover, fontSizePixels])

  return (
    <ControlledTooltip
      isOpen={isOpenPopover}
      message={t('entity_settings.form_input.warnings.ios_font_size')}
      onClose={() => setIsOpenPopover(false)}
    >
      <Range
        labelText={labelText}
        labelIcon={isMobile && <MobileIconUi />}
        value={parseInt(currentFontSize, 0)}
        update={updateProp}
        min={8}
      />
    </ControlledTooltip>
  )
}

FontSize.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  labelText: PropTypes.string,
}

export default memo(FontSize)
